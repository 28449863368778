import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const options = {
    headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json"
    }
};

function get() {
    return axios.get(`${url}/me`, options).then(response => {
        return {
            list: response.data,
            meta: response.data.meta
        };
    });
}

function recoveryCode2FA() {
    return axios
        .get(`${url}/admin/user/two-factor-recovery-codes`, options)
        .then(response => response.data);
}

function resetRecoveryCodes2FA() {
    return axios
        .post(`${url}/admin/user/two-factor-recovery-codes`, options)
        .then(response => response.data);
}

function qrCode2FA() {
    return axios
        .get(`${url}/admin/user/two-factor-qr-code`, options)
        .then(response => response.data);
}

function enable2FA() {
    return axios
        .post(`${url}/admin/user/two-factor-authentication`, options)
        .then(response => {
            console.log(response.data)
            return response.data;
        });
}

function update(payload) {
    return axios
        .put(`${url}/admin/user/profile-information`, payload, options)
        .then(response => {
            return response.data;
        });
}

function updatePassword(payload) {
    return axios
        .put(`${url}/admin/user/password`, payload, options)
        .then(response => {
            return response.data;
        });
}

export default {
    get,
    enable2FA,
    qrCode2FA,
    recoveryCode2FA,
    resetRecoveryCodes2FA,
    update,
    updatePassword
};