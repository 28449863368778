import service from "@/store/services/communauties/bookings-service";
import moment from 'moment'
moment.locale('fr');

const state = {
  list: [],
  meta: {},
  booking: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, booking) => {
    state.booking = booking;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  async list({ commit, dispatch }, params) {
    try {
      const { list, meta } = await service.list(params)
      commit("SET_LIST", list);
      commit('SET_META', meta);
    } catch (error) {
      return ({...error})
    }
  },
};

const getters = {
  all: (state) => {
    let bookingsList = [...state.list]
    bookingsList.map( b => {
      let from = `${b.on_day} ${b.from}`
      let to = `${b.on_day} ${b.to}`
      
      b.from = moment(new Date(from)).format("LT")
      b.to = moment(new Date(to)).format("LT")
      b.on_day = moment(new Date(b.on_day)).format("LL")
    })
    return bookingsList
  },
  listTotal: state => state.meta.total ?? 0,
  booking: (state) => state.booking,
  getBookingByID: (state) => (id) => {
    return state.list.find(b => b.id == id)
  }
};

const bookings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default bookings;
