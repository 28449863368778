import axios from 'axios';
import store from "../store";
import router from "../router";

// Response interceptor
axios.interceptors.response.use(
  (response) => {
      // Handle successful responses
      return response;
  },
  (error) => {
      if(error.response?.status === 401) {
        localStorage.clear()
        store.commit('isAuthenticated')
        router.go(-1);
        // Token expired, redirect to login or show an error message
      }
      return Promise.reject(error);
  }
);

export default axios;