<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Détails</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">{{ submit.label }}</h3>

              <!-- Card body -->
              <div class="row">
                <div class="col-lg-8">
                  <p class="mb-0" v-html="submit.form.detail"></p>
                </div>
              </div>
            </card>

            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8 d-flex">
                  <h3 class="mb-0">Données Fournies</h3>
                  <base-button
                @click="goBack"
                type="button"
                class="ml-2 btn btn-sm btn-primary"
                >Retourner à la liste</base-button>
                </div>
                <div v-show="status == 'submitted'" class="col-4 text-right">
                  <button
                    @click="printSubmitInPdf(submit)"
                    class="mb-2 btn btn-md btn-info"
                  >
                    <i class="ni ni-cloud-download-95"></i>
                  </button>
                  <button
                    @click="handleValidation('approved')"
                    class="mb-2 btn btn-md btn-success"
                  >
                    <i class="ni ni-check-bold"></i>
                  </button>
                  <button
                    @click="handleValidation('rejected')"
                    class="btn btn-md btn-warning"
                  >
                    <i class="ni ni-curved-next"></i>
                  </button>
                  <button
                    @click="handleValidation('rejected')"
                    class="btn btn-md btn-warning"
                  >
                    <i class="ni ni-archive-2"></i>
                  </button>
                </div>
                <badge
                  v-show="status == 'approved'"
                  rounded
                  type="success"
                  size="lg"
                  >Reçue</badge
                >
                <badge
                  v-show="status == 'rejected'"
                  rounded
                  type="success"
                  size="lg"
                  >Rejetée</badge
                >
              </div>
              <SubmitSection
                v-for="section in submit.sections"
                :key="section.id"
                :section="section"
              />
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import makeSubmissionDoc from "@/util/makePdf";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import SubmitSection from "../Sections/FilledSection.vue";
import BaseButton from "@/components/BaseButton.vue";
export default {
  name: "ShowPendingRequestPage",
  components: {
    SubmitSection,
    RouteBreadCrumb,
    BaseButton,
  },
  data() {
    return {
      submit: {
        label: "",
        rank: "",
        status: "",
        total_section: null,
        filled_section: null,
        form: {
          detail: "",
        },
      },
      status: "",
    };
  },
  watch: {
    submit: {
      deep: true,
      handler(newVal, oldVal) {
        this.status = newVal.status;
      },
    },
  },
  created() {
    this.get();
  },
  methods: {
    id() {
      return this.$route.params.id;
    },
    goBack() {
      this.$router.push({ name: "Listes Demandes Usagers En Cours" });
    },
    async get() {
      try {
        await this.$store.dispatch("submits/get", { id: this.id() });
        this.submit = this.$store.getters["submits/submit"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleValidation(status) {
      try {
        await this.$store.dispatch("submits/update", {
          id: this.id(),
          status: status,
        });
        this.get();
        this.$notify({
          type: "success",
          message: "Le status de la demande a été modifé avec succès!",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.errors);
      }
    },
    printSubmitInPdf(submit) {
      const docDefinition = makeSubmissionDoc(submit)
      pdfMake.createPdf(docDefinition).open();
    },
    getBase64Image() {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");

      // Create an image object
      var image = new Image();

      // Set the image source
      image.src = "/img/brand/logo.svg";

      // Wait for the image to load
      image.onload = function () {
        // Set the canvas dimensions to match the image
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image on the canvas
        context.drawImage(image, 0, 0);

        // Get the base64 representation of the image
        var base64 = canvas.toDataURL("image/png"); // You can change 'image/png' to other formats like 'image/jpeg'

        // Use the base64 string as needed
        // console.log(base64); // Output the base64 string
      };
    },
  },
};
</script>

<style></style>
