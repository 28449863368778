<template>
  <div class="card">
    <div class="card-header">
      <h1>Modification de Mot de passe</h1>
    </div>
    <div class="card-body">
      <form ref="password_form" @submit.prevent="handleChangePassword">
        <base-input
          v-model="current_password"
          type="password"
          name="current_password"
          autocomplete="on"
          class="mb-3"
          prepend-icon="fa fa-key"
          placeholder="Actuel mot de passe"
        />
        <validation-error :errors="apiValidationErrors.current_password" />
        <base-input
          v-model="password"
          type="password"
          name="new_password"
          autocomplete="on"
          class="mb-3"
          prepend-icon="fa fa-key"
          placeholder="Nouveau mot de passe"
        />
        <validation-error :errors="apiValidationErrors.password" />

        <base-input
          v-model="password_confirmation"
          type="password"
          name="confirm_password"
          autocomplete="on"
          class="mb-3"
          prepend-icon="fa fa-key"
          placeholder="Confirmer mot de passe"
        />
        <validation-error :errors="apiValidationErrors.password_confirmation" />
        <div class="my-4">
          <base-button
            type="button"
            class="btn btn-sm btn-primary"
            native-type="submit"
          >
            Changer Mot de Passe
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "UserPasswordCard",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
      current_password: null,
      password: null,
      password_confirmation: null,
    };
  },

  methods: {
    async handleChangePassword() {
      // if (this.$isDemo == 1 && ["1", "2", "3"].includes(this.user.id)) {
      //   this.$notify({
      //     type: "danger",
      //     message: "You are not allowed not change data of default users.",
      //   });
      //   return;
      // }
      const password = {
        'current_password': this.current_password,
        'password': this.password,
        'password_confirmation': this.password_confirmation
      }

      try {
        await this.$store.dispatch("profile/updatePassword", password);
        this.$refs["password_form"].reset();
        this.resetApiValidation();

        this.$notify({
          type: "success",
          message: "Mot de passe modifié avec succès",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue lors de la modification du mot de passe!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
