import service from '@/store/services/users/profile-service';

const state = {
  me: null,
};

const mutations = {
  SET_RESOURCE: (state, me) => {
    state.me = me;
  }
};

const actions = {
  me({commit, dispatch}, params) {
    return service.get(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile.list);
      });
  },

  recoveryCode({commit, dispatch}, params) {
    return service.recoveryCode2FA(params)
      .then((codes) => codes);
  },

  resetRecoveryCodes({commit, dispatch}, params) {
    return service.resetRecoveryCodes2FA(params)
      .then((codes) => codes);
  },

  qrCode({commit, dispatch}, params) {
    return service.qrCode2FA(params)
      .then((qrcode) => qrcode);
  },

  enable2FA({commit, dispatch}, params) {
    return service.enable2FA(params)
      .then((profile) => {
        //
      });
  },
  update({commit, dispatch}, params) {
    return service.update(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile);
      });
  },

  updatePassword({commit, dispatch}, params) {
    return service.updatePassword(params)
      .then((profile) => {
       return profile
        // commit('SET_RESOURCE', profile);
      });
  },
};

const getters = {
  me: state => state.me
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default profile;
