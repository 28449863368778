import { isEmpty, isEqual, omit, pick } from "lodash";

export default {
    data() {
        return {
            isLoading: false,
            apiValidationErrors: {}
        };
    },
    methods: {
        /* extract API server validation errors and assigns them to local mixin data */
        setApiValidation(serverErrors, refs = null) {
            if(serverErrors === undefined) this.apiValidationErrors = {}
            else this.apiValidationErrors = serverErrors
        },

        /* reset validation content */
        resetApiValidation() {
            this.apiValidationErrors = {};
        }
    }
};