<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ formType }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb :pageTitle="page"></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">{{ name }}</h3>
              </div>
              <div class="col-4 text-right">
                <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <slot name="managementForm"></slot>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  props: ['formType', 'name', 'page', 'goBack'],

  components: {
    RouteBreadCrumb,
    BaseButton,
  },
};
</script>