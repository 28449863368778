<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Liste</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Publication - Idées</h3>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addItem()">
                <span class="btn-inner--icon"
                  ><i class="fas fa-user-edit"></i
                ></span>
                <span class="btn-inner--text">Publier Idée</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary pagination-select mb-3"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <!-- <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Rechercher..."
                clearable
              />
            </div> -->
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="mapItems"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>
            <el-table-column
              label="Titre"
              min-width="200px"
              prop="topic"
              sortable="custom"
            />
            <el-table-column
              label="Nbr. Commentaire"
              min-width="200px"
              prop="comments_count"
              sortable="custom"
            />

            <!-- <el-table-column
              label="Nbr Commentaire(s)"
              min-width="200px"
              sortable="custom"
              prop="comments_count"
            /> -->

            <!-- <el-table-column label="Image" min-width="150px">
              <template v-slot="{ row }">
                <img
                   v-show="row.image_url"
                  :src="row.image_url"
                  style="width: 100px; height: auto"
                  class="rounded text-truncate"
                  alt="avatar"
                />
              </template>
            </el-table-column> -->

            <el-table-column
              label="Crée"
              prop="created_at"
              min-width="170px"
              sortable="custom"
            />

            <el-table-column min-width="85px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="Modifier" placement="top">
                  <a
                  v-if="!(row.comments_count > 0)"
                    type="text"
                    @click="editItem(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-user-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Supprimer" placement="top">
                  <a
                    type="text"
                    @click="deleteItem(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Affichage de {{ total ? from + 1 : 0 }} à {{ to }} de
              {{ total }} Idées

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} Lignes selectionnées
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
  </div>
</template>
<script>
import moment from 'moment'
moment.locale('fr');

import { BasePagination } from "@/components/";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import Vue from "vue";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    RouteBreadCrumb,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      items: [],
      loading: true,
      user: null,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    mapItems() {
      if (this.items){
        let topics = this.items
        topics.map( item => {
          return item.created_at = moment(new Date(item.created_at).getTime()).fromNow()
          }
        )
        return topics
      }
      else
        return []
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          filter: {
            ...(this.query ? { name: this.query } : {}),
            ...(this.query ? { sub_title: this.query } : {}),
            ...(this.query ? { content: this.query } : {}),
          },
          page: this.pagination.currentPage,
          size: this.pagination.perPage,
        };

        await this.$store.dispatch("forums/list", params);
        this.items = this.$store.getters["forums/list"];
        this.total = this.$store.getters["forums/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },

    async deleteItem(id) {
       
      const confirmation = await swal.fire({
        title: `Supprimer cette idée?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("profile/me");
          this.user = await { ...this.$store.getters["profile/me"] };
          // if (this.user.roles[0].name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          await this.$store.dispatch("items/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Item deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    addItem() {
      this.$router.push({ name: "Ajouter Idée" });
    },
    editItem(row) {
      this.$router.push({
        name: "Edit Item",
        params: { id: row.id },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
