import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const url = process.env.VUE_APP_API_BASE_URL;
const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
    storageNamespace: 'admin',
    tokenPrefix: 'back_office',
    baseUrl: url,
    tokenName: "access_token",
    tokenPath: "data.token",
    registerUrl: "admin/auth/register",
    loginUrl: "admin/user/two-factor-authentication",
    logoutUrl: "auth/logout",
});

export default {
    state: {
        isAuthenticated: localStorage.getItem("admin.back_office_access_token") !== null,
        namespaced: true
    },

    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        }
    },

    mutations: {
        isAuthenticated(state) {
            state.isAuthenticated = vueAuth.isAuthenticated();
        }
    },

    actions: {
        login(context, payload) {
            return axios
                .post(`${url}/admin/auth/login`, payload.user, payload.requestOptions)
                .then(response => {
                    return response
                }).catch((error) => error.response);
        },
        login2FA(context, payload) {
            return vueAuth.login(payload.user, payload.requestOptions)
                .then(response => {
                    context.commit("isAuthenticated");
                    router.push({ path: "/dashboard" });

                    return response;
                }).catch((error) => error.response);
        },
        resendCode(context, payload) {
            return axios.post(`${url}/admin/user/two-factor-resend-code`, payload.user, payload.requestOptions)
                .then(response => {
                    return response;
                }).catch((error) => error.response);
        },

        register(context, payload) {
            return vueAuth
                .register(payload.user, payload.requestOptions)
                .then(response => {
                    context.commit("isAuthenticated", {
                        isAuthenticated: vueAuth.isAuthenticated()
                    });
                    router.push({ path: "/dashboard" });
                });
        },

        logout(context, payload) {
            return vueAuth.logout().then(response => {
                context.commit("isAuthenticated", {
                    isAuthenticated: vueAuth.isAuthenticated()
                });
                router.push({ name: "Login" });
            });
        }
    }
};