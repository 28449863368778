import service from '@/store/services/menus/menus-service';

const state = {
  list: [],
  menu: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, menu) => {
    state.menu = menu;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params)
      .then(({ list, meta }) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  fetchAllProperties({ commit, dispatch }) {
    return service.fetchAll().then( response => response)
  },

  get({ commit, dispatch }, params) {
    return service.get(params)
      .then((menu) => { commit('SET_RESOURCE', menu); });
  },

  add({ commit, dispatch }, params) {
    return service.add(params)
      .then((menu) => { commit('SET_RESOURCE', menu); });
  },

  update({ commit, dispatch }, params) {
    return service.update(params)
      .then((menu) => { commit('SET_RESOURCE', menu); });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.total,
  menu: state => state.menu,
};

const menus = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default menus;