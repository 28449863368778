import service from "@/store/services/communauties/booking-services-service";

const state = {
  list: [],
  serivce: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, service) => {
    state.service = service;
  },
};

const actions = {
  async list({ commit, dispatch }, params) {
    try {
      const data = await service.list(params)
      commit("SET_LIST", data);
    } catch (error) {
      return ({...error})
    }
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((service) => {
      commit("SET_RESOURCE", service);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((service) => {
      commit("SET_RESOURCE", service);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((service) => {
      commit("SET_RESOURCE", service);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  all: (state) => state.list,
  service: (state) => state.service,
  getServiceByID: (state) => (id) => {
    return state.list.find(b => b.id == id)
  }
};

const booking_services = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default booking_services;
