var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Liste Formulaires' })}}},[_c('i',{staticClass:"fas fa-arrow-left"})]),_vm._v(" Liste des sections du formulaire ")],1)])]),(_vm.sectionTypes.length)?_c('collapse',{attrs:{"active-index":0}},_vm._l((_vm.sectionTypes),function(type,index){return _c('collapse-item',{key:index,attrs:{"id":("section-" + type),"title":("collapse-" + index),"name":("section" + (index + 1))}},[_c('div',{staticClass:"d-flex align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('h5',{staticClass:"mr-2 mb-0"},[_vm._v(_vm._s(_vm.sectionTypeColor(type).name))])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-between mb-4"},[_c('div',{staticClass:"d-flex"},[_c('h6',{staticClass:"heading-small text-muted"},[_vm._v(" "+_vm._s(_vm.sectionTypeColor(type).name)+" ")]),_c('handle-form-sections-modal',{attrs:{"color":_vm.sectionTypeColor(type).color,"fieldLabel":_vm.sectionTypeColor(type).name,"currType":type,"sections":_vm.initialSections,"formId":_vm.id()},on:{"formUpdated":function($event){return _vm.$emit('sectionUpdated')}}}),_c('handle-drag-and-drop-modal',{attrs:{"color":_vm.sectionTypeColor(type).color,"fieldLabel":_vm.sectionTypeColor(type).name,"currType":type,"sections":_vm.getAttachedSectionsByType(type),"formId":_vm.id()},on:{"formUpdated":function($event){return _vm.$emit('sectionUpdated')}}})],1)]),_c('div',{staticClass:"row row-cols-1 py-3"},[_c('ul',{staticClass:"list-group list-group-flush",attrs:{"data-toggle":"checklist"}},_vm._l((_vm.getAttachedSectionsByType(type)),function(section,index){
var _obj;
return _c('li',{key:index,staticClass:"checklist-entry list-group-item flex-column align-items-start py-4"},[_c('div',{staticClass:"section-checklist-item",class:( _obj = {
                  'checklist-item-checked': _vm.sectionIsArchivedWithCurrForm(
                    section.is_archived_with_form
                  )
                }, _obj[("checklist-item-" + (_vm.sectionTypeColor(type).color))] = _vm.sectionTypeColor(type).color, _obj )},[_c('div',[_c('base-checkbox',{attrs:{"checked":_vm.sectionIsArchivedWithCurrForm(
                        section.is_archived_with_form
                      ),"type":_vm.sectionTypeColor(type).color,"disabled":section.archived},on:{"input":function($event){return _vm.disabledSection(section)}}})],1),_c('div',{staticClass:"checklist-info"},[_c('h5',{staticClass:"checklist-title mb-0"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(section.archived),expression:"section.archived"}],staticClass:"fas fa-archive mr-1 text-danger"}),_vm._v(_vm._s(section.name)+" ")]),_c('small',[_vm._v("Nbr. Questions : "+_vm._s(section.questions.length))])])])])}),0)])])])}),1):_c('div',[_c('i',{staticClass:"text-muted"},[_vm._v("Aucune section n'est enregistrée pour ce formulaire! Veuillez cliquer sur le bouton "),_c('span',{staticClass:"text-primary"},[_vm._v("Ajouter Section")]),_vm._v(" pour ajouter")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }