<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Liste</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Prises de Rendez-vous</h3>
              </div>
              <div class="col-6 d-flex flex-row justify-content-end align-content-end">
                <div class="d-flex flex-row align-content-end mr-6">
                  <!-- <b class="mr-2">Affichage Calendrier :</b>
                  <base-switch v-model="calendarView" @input="tooggleView" type="success" on-text="Oui" off-text="Non"></base-switch> -->
                </div>
                <base-button
                  type="warning"
                  icon
                  size="sm"
                  @click="bookingSetting()"
                >
                  <span class="btn-inner--icon"
                    ><i class="ni ni-settings"></i
                  ></span>
                  <span class="btn-inner--text">Disponibilités</span>
                </base-button>
              </div>
            </div>
          </template>
          <div v-if="calendarView" class="card-body">
            <BookingCalendar />
          </div>
          <div v-else class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  class="select-primary pagination-select mb-3"
                  v-model="pagination.perPage"
                  placeholder="Par page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
  
                <!-- <div>
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Rechercher..."
                  clearable
                />
              </div> -->
              </div>
              <el-table
                class="table-responsive align-items-center table-flush"
                header-row-class-name="thead-light"
                :data="bookingsList"
                @sort-change="sortChange"
              >
                <div slot="empty" v-if="loading">
                  <img
                    src="/img/loading.gif"
                    style="height: 100px; width: 100px"
                  />
                </div>
                <el-table-column
                  label="Nom"
                  min-width="150px"
                  prop="lastname"
                  sortable="custom"
                />
  
                <el-table-column
                  label="Prénom"
                  min-width="150px"
                  prop="firstname"
                  sortable="custom"
                />
  
                <el-table-column
                  label="Contact"
                  min-width="150px"
                  sortable="custom"
                  prop="contact"
                />
  
                <el-table-column label="Statut" min-width="100px">
                  <template v-slot="{ row }">
                    <badge v-if="row.confirmed == true" type="success"
                        >Confirmé</badge
                      >
                      <badge v-else-if="!row.confirmed == false" type="danger"
                        >Non Confirmé</badge
                      >
                      <badge v-else type="warning">En Cours</badge>
                  </template>
                </el-table-column>
  
                <el-table-column label="RDV" min-width="200px">
                  <template v-slot="{ row }">
                    {{ row.on_day + " ( " + row.from + " - " + row.to + " )" }}
                  </template>
                </el-table-column>
  
                <el-table-column min-width="85px" align="center">
                  <div slot-scope="{ row }" class="table-actions">
                    <el-tooltip content="Voir" placement="top">
                      <a
                        type="text"
                        @click="showItem(row)"
                        class="table-action"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="fas fa-eye"></i>
                      </a>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
  
              <!-- start booking modal -->
              <modal :show.sync="showBookingModal">
                <h6 slot="header" class="modal-title" id="modal-title-default">
                  Détail d'un Rendez-vous
                </h6>
  
                <div v-if="booking.id != undefined">
                  <div
                    class="d-flex flex-column justify-center mb-2 py-1 border-bottom border-top"
                  >
                    <span class="mx-auto mb-2">
                      <b class="mr-1">Statut :</b>
                      <badge v-if="booking.confirmed == true" type="success"
                        >Confirmé</badge
                      >
                      <badge v-else-if="!booking.confirmed == false" type="danger"
                        >Non Confirmé</badge
                      >
                      <badge v-else type="warning">En attente de confirmation</badge>
                    </span>
                    <span class="mx-auto"
                      >{{ booking.on_day }} ( {{ booking.from }} -
                      {{ booking.to }} )</span
                    >
                  </div>
                  <div class="d-flex flex-column justify-content-start mt-4">
                    <span
                      ><b class="mr-1">Nom & Prénom :</b>{{ booking.firstname }}
                      {{ booking.lastname }}</span
                    >
                    <span
                      ><b class="mr-1">Contact :</b>{{ booking.contact }}</span
                    >
                  </div>
                  <div class="d-flex flex-column justify-content-start mt-4">
                    <span><b>Notes :</b></span>
                    <p>
                      {{ booking.notes }}
                    </p>
                  </div>
                </div>
  
                <template slot="footer">
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="showBookingModal = false"
                    >Fermer
                  </base-button>
                </template>
              </modal>
              <!-- end booking modal -->
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Affichage de {{ total ? from + 1 : 0 }} à {{ to }} de
                  {{ total }} Rendez-vous
  
                  <span v-if="selectedRows.length">
                    &nbsp; &nbsp; {{ selectedRows.length }} Lignes selectionnées
                  </span>
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              />
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { BasePagination } from "@/components/";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BookingCalendar from "@/views/Communities/Bookings/CalendarView";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    RouteBreadCrumb,
    BookingCalendar,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "created_at",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      items: [],
      loading: true,
      user: null,
      showBookingModal: false,
      calendarView: false,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    ...mapGetters("bookings", {
      bookingsList: "all",
      total: "listTotal",
      booking: "booking",
      findBooking: "getBookingByID",
    }),
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          filter: {
            ...(this.query ? { firsname: this.query } : {}),
            ...(this.query ? { lastname: this.query } : {}),
            ...(this.query ? { contact: this.query } : {}),
          },
          page: this.pagination.currentPage,
          size: this.pagination.perPage,
        };

        await this.$store.dispatch("bookings/list", params);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },

    async deleteItem(id) {
      const confirmation = await swal.fire({
        title: `Supprimer cette idée?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Non, garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("profile/me");
          this.user = await { ...this.$store.getters["profile/me"] };
          // if (this.user.roles[0].name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          await this.$store.dispatch("bookings/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Item deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    bookingSetting() {
      this.$router.push({ name: "Paramétrer Disponibilités" });
    },
    showItem(row) {
      let currBooking = this.findBooking(row.id);
      if (currBooking != undefined) {
        this.$store.commit("bookings/SET_RESOURCE", currBooking);
        this.showBookingModal = true;
      } else {
        this.$notify({
          type: "danger",
          message: "Ce rendez-vous est introuvable.",
        });
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
    tooggleView() {
      console.log(this.activeBookingView)
    }
  },
};
</script>
