<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Détails</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="mt--6">
      <div class="header pb-6 d-flex align-items-center profile-header">
        <!-- Mask -->
        <span class="mask bg-gradient-default opacity-8"></span>
        <!-- Header container -->
        <div class="container-fluid">
          <div class="row">
            <Transition mode="out-in" :duration="{ enter: 900, leave: 800 }">
              <div
                v-if="headerState === 'showing'"
                class="col-lg-8 col-md-10 text-wrap"
              >
                <h1 class="display-3 text-white">
                  <u
                    >{{
                      formHeader.type != undefined
                        ? formHeader.type.name
                        : "-----"
                    }}
                    :</u
                  >
                  {{ formHeader.name }}
                </h1>
                <div class="overflow-auto mt-0 mb-5" style="max-height: 200px;">
                  <p class="text-white" v-html="formHeader.detail"></p>
                </div>
                <base-button @click="headerState = 'editing'" type="secondary"
                  >Modifier l'en-tête</base-button
                >
              </div>
              <div
                v-else-if="headerState === 'editing'"
                class="col-lg-7 col-md-10 text-wrap"
              >
                <EditFormPage
                  @updateState="(state) => (headerState = state)"
                  @updateHeader="handleSubmit"
                  :state="headerState"
                  :data="formHeader"
                  :errors="apiValidationErrors"
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>

      <div class="container-fluid mt--4">
        <card v-if="isLoading">
          <span>En cours de récupération...</span>
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </card>
        <card v-else>
          <form-section-collapse
            :formHasSubmits="this.formHeader.has_submits"
            :initialSections="this.formHeader.sections"
            @sectionUpdated="get"
          />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import FormSectionCollapse from "@/components/Collapse/FormSectionCollapse.vue";
import EditFormPage from "./EditFormPage.vue";

import formMixin from "@/mixins/form-mixin";
export default {
  name: "ShowFormPage",
  components: {
    RouteBreadCrumb,
    FormSectionCollapse,
    EditFormPage,
  },

  mixins: [formMixin],

  data() {
    return {
      type: null,
      headerState: "showing",
      formHeader: {
        name: "",
        detail: "",
        has_submits: false,
        sections: [],
      },
      isLoading: false,
    };
  },
  created() {
    this.get();
  },
  methods: {
    id() {
      return parseInt(this.$route.params.id);
    },

    async get() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("forms/get", { id: this.id() });
        this.formHeader = this.$store.getters["forms/form"];
        this.isLoading = false
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false
      }
    },

    async handleSubmit(e) {
      try {
        await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
        // if (this.user.roles[0].name === "member") {
        //   this.$notify({
        //     type: "danger",
        //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
        //   });
        //   return;
        // }

        await this.$store.dispatch("forms/update", {
          id: this.id(),
          item: { ...e },
        });
        this.formHeader = await this.$store.getters["forms/form"];
        this.headerState = "showing";

        this.$notify({
          type: "success",
          message: "Les détails du formulaires ont été mis à jour avec succès!",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/brand/logo1.png);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
