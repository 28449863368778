<template>
  <div>
    <base-button
      type="warning"
      @click="modals.toggleModal = true"
      size="sm"
      class="btn-icon-only rounded-circle"
    >
      <span class="btn-inner--icon display-5 font-weight-light"
        ><i class="fas fa-pen"></i
      ></span>
    </base-button>

    <modal :show.sync="modals.toggleModal" size="lg" body-classes="p-0">
      <div slot="header" class="modal-title">
        <h2>Re-ordonner les Sections</h2>
        <small class="font-weight-lighter font-italic"
          ><sup class="font-weight-bold text-warning">*</sup>L'ordonnancement
          consiste à glisser et déposer les sections pour obtenir l'ordre
          souhaité à ce formulaire.</small
        >
      </div>
      <div class="modal-body px-lg-5 pt-lg-5 border-0">
        <form role="form" @submit.prevent="saveReorderSections">
          <draggable
            tag="ul"
            v-model="sectionsList"
            class="list-group list-group-flush"
            :group="currType"
            data-toggle="checklist"
            :move="setSectionsOrder"
          >
            <transition-group>
              <li
                class="checklist-entry list-group-item flex-column align-items-start py-4 border-top-0 border-left-0 border-right-0 border-bottom-2"
                v-for="(section, index) in sectionsList"
                :key="index"
              >
                <div class="section-checklist-item">
                  <div>
                    <!-- <i class="fas fa-bars mr-4"></i> -->
                    <span class="mr-4">{{ index + 1 }}-</span>
                  </div>
                  <div class="checklist-info">
                    <h5 class="checklist-title mb-0">
                      <i
                        v-show="section.archived"
                        class="fas fa-archive mr-1 text-danger"
                      ></i
                      >{{ section.name }}
                    </h5>
                  </div>
                </div>
              </li>
            </transition-group>
          </draggable>
          <div class="modal-footer d-flex justify-content-between">
            <base-button
              type="primary"
              :loading="isLoading"
              native-type="submit"
              :disabled="sectionsList.length <= 0"
              >Enregistrer</base-button
            >
            <base-button type="danger" @click="closeModal">Annuler</base-button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "HandleDragAndDropModal",
  components: {
    draggable,
  },
  props: {
    formId: Number,
    color: String,
    currType: String,
    sections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modals: {
        toggleModal: false,
      },
      sectionsList: [],
      isLoading: false,
    };
  },
  watch: {
    sections: {
      handler: 'setDraggableSections',
      immediate: true,
    },
  },
  methods: {
    setDraggableSections(list) {
      this.sectionsList = list.map(
        (s, i) => (
          {
            id: s.id,
            name: s.name,
            range: (i + 1)
          }
        )
      )
    },
    setSectionsOrder(ev) {
      // const {  element, index, futureIndex } = ev?.draggedContext
      // let dragSection = this.sectionsList.find(s => s.id == element.id)

      // if(dragSection != undefined) {
      //   console.log([index, futureIndex])
      //   this.sectionsList[futureIndex].range = (futureIndex + 1)
      // }
    },
    closeModal() {
      this.sectionsList = [];
      this.modals.toggleModal = false; //close modal
    },
    async saveReorderSections() {
      this.isLoading = true;
      try {
        const draggingSections = this.sectionsList.map((s, i) => ({id: s.id, range: (i + 1)}))
        await this.$store.dispatch("forms/reorderSections", {
          id: this.formId,
          type: this.currType,
          sections: draggingSections,
        });

        this.$emit("formUpdated");
        this.$notify({
          type: "success",
          message: "Les sections ont été mis à jour avec succès!",
        });

        this.isLoading = false;
        this.modals.toggleModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.isLoading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
