<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!-- Home start -->
        <sidebar-item
          :link="{
            name: 'Tableau de Bord',
            icon: 'ni ni-shop text-primary',
            path: '/admin/dashboard',
          }"
        >
        </sidebar-item>
        <!-- Home end -->
      </template>

      <!-- Management start -->
      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Gestions</h6>

        <ul class="navbar-nav mb-md-3">
          <!-- Menus Management start -->
          <sidebar-item
            :link="{
              name: 'Menus',
              icon: 'ni ni-bullet-list-67 text-primary',
              path: '/admin/siteweb-menus/liste',
            }"
          />
          <!-- Menus Management end -->

          <!-- Contents Management start -->
          <sidebar-item
            :link="{
              name: 'Contenus',
              icon: 'ni ni-ungroup text-primary',
              path: '/admin/gestion-contenus',
            }"
          />
          <!-- Contents Management end -->

          <!-- Posts Management start -->
          <sidebar-item
            :link="{
              name: 'Publications',
              icon: 'ni ni-align-left-2 text-primary',
              path: '/admin/gestion-publications',
            }"
          />
          <!-- Posts Management end -->

          <!-- Documentations Management start -->
          <sidebar-item
            :link="{
              name: 'Documentations',
              icon: 'ni ni-books text-primary',
              path: '/admin/gestion-documentations',
            }"
          />
          <!-- Documentations Management end -->

          <!-- FAQs Management start -->
          <sidebar-item
            :link="{
              name: 'FAQs',
              icon: 'ni ni-curved-next text-primary',
              path: '/admin/apdp-faqs/liste',
            }"
          />
          <!-- FAQs Management end -->

          <!-- Newsletters Management start -->
          <sidebar-item
            :link="{
              name: 'Newsletters',
              icon: 'ni ni-world-2 text-primary',
              path: '/admin/newsletters',
            }"
          />
          <!-- Newsletters Management end -->

          <!-- Form start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-folder-17 text-primary',
              name: 'Formulaires',
              path: '/admin/apdp-formulaires',
            }"
          />
          <!-- Form end -->

          <!-- Rdv start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-book-bookmark text-primary',
              name: 'Prise de RDV',
              path: '/admin/apdp-communautes/prises-rdv',
            }"
          />
          <!-- Rdv end -->
        </ul>
      </template>
      <!-- Management end -->

      <!-- Form start -->
      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Demandes Usagers</h6>

        <ul class="navbar-nav mb-md-3">
          <!-- pending form start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-single-copy-04 text-warning',
              name: 'En Cours',
              path: '/admin/formulaire-usagers/en-cours/liste',
            }"
          />
          <!-- pending form end -->

          <!-- handle form start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-folder-17 text-success',
              name: 'Validées',
              path: '/admin/formulaire-usagers/approuves/liste',
            }"
          />
          <!-- handle form end -->
        </ul>
      </template>
      <!-- Form end -->

      <!-- Community start -->
      <template slot="links-after">
        <hr class="my-3" />
        <!-- <h6 class="navbar-heading p-0 text-muted">Blog & Forum</h6> -->
        <h6 class="navbar-heading p-0 text-muted">Forum</h6>

        <ul class="navbar-nav mb-md-3">
          <!-- Blog start -->
          <!-- <sidebar-item
            :link="{
              icon: 'ni ni-align-left-2 text-danger',
              name: 'Blogs',
              path: '/admin/apdp-communautes/blogs',
            }"
          /> -->
          <!-- Blog end -->

          <!-- Forum start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-notification-70 text-yellow',
              name: 'Forums',
              path: '/admin/apdp-communautes/forums',
            }"
          />
          <!-- Forum end -->
        </ul>
      </template>
      <!-- Community end -->

      <!-- DPO start -->
      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Espace DPO</h6>

        <ul class="navbar-nav mb-md-3">
          <!-- Formations start -->
          <sidebar-item
            :link="{
              icon: 'ni ni-align-left-2 text-danger',
              name: 'Formations',
              path: '/admin/dpo/formations',
            }"
          />
          <!-- Formations end -->
        </ul>
      </template>
      <!-- DPO end -->

      <!-- Users Management start -->
      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Utilisateurs</h6>

        <ul class="navbar-nav mb-md-3">
          <!-- Permissions Management start -->
          <sidebar-item
            :link="{
              name: 'Rôles & Permissions',
              icon: 'fa fa-user-shield text-primary',
            }"
          >
            <sidebar-item
              :link="{
                name: 'Rôles',
                path: '/admin/utilisateurs/gestion-roles',
              }"
            />
            <sidebar-item
              :link="{
                name: 'Permissions',
                path: '/admin/utilisateurs/gestion-permissions',
              }"
            />
          </sidebar-item>
          <!-- Permissions Management end -->

          <!-- Users start -->
          <sidebar-item
            :link="{
              icon: 'fa fa-users text-success',
              name: 'Utilisateurs',
              path: '/admin/utilisateurs/gestion-utilisateurs',
            }"
          />
          <!-- Users end -->
        </ul>
      </template>
      <!-- Users Management end -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar(), this.$store.dispatch("profile/me");
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}

body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
