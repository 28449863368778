<template>
  <resource-list currResource="publication">
    <template #listTable>
      <el-table-column
        label="Titre"
        min-width="200px"
        prop="title"
        sortable="custom"
      />
      <el-table-column
        label="Sous-Titre"
        min-width="200px"
        prop="sub_title"
        sortable="custom"
      />

      <el-table-column
        label="Type"
        min-width="200px"
        prop="type"
        sortable="custom"
      />

      <el-table-column
        label="Contenu"
        min-width="200px"
        prop="content"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <div v-html="row.content" style="max-height: 50px;"  />
        </template>
      </el-table-column>

      <el-table-column label="Image" min-width="150px">
        <template v-slot="{ row }">
          <img
            v-show="row.image_url"
            :src="row.image_url"
            style="width: 100px; height: auto"
            class="rounded text-truncate"
            alt="avatar"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="Crée le"
        prop="created_at"
        min-width="170px"
        sortable="custom"
      />
    </template>
  </resource-list>
</template>
<script>
import moment from "moment";
moment.locale("fr");
import {
  TableColumn,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css"
import ResourceList from "../../Components/Management/ResourceList.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResourceList,
    [TableColumn.name]: TableColumn,
  }
};
</script>
