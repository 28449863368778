import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } = createNamespacedHelpers("article_types");
const EXCLUDED_RESOURCE = 'documentations';

export default {
  data() {
    return {
      modals: {
        articleTypeModal: false,
        isLoading: false,
      },
      articleTypeForm: { name: "" },
    };
  },
  watch: {
    hasInput: {
        handler: function (newVal, oldVal) {
          if(this.resource?.slug != EXCLUDED_RESOURCE) {
            if (!newVal) {
              this.toggleCategory = false;
              this.toggleSection = false;
            } else if(newVal && this.hasCategories(this.item.type) && this.item.category) { // if article type provides by user has any category, enable categories field
              this.toggleCategory = true

              if(this.hasSections(this.item.type) && this.item.section) this.toggleSection = true
            }
          }
        },
        immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "all",
      "type",
      "getTypeByName",
      "hasCategories",
      "hasSections",
    ]),
    // check if user input
    hasInput() {
      return typeof this.item.type === "string" && this.item.type != "";
    },
    // check if article type provides category & if not, allow user to add one by showing category checkbox through this method
    checkCategory() {
      return (
        this.hasInput &&
        typeof this.item.category === "string" &&
        this.item.category != ""
      );
    },
  },
  created() {
    this.getTypes();
  },
  methods: {
    async getTypes() {
      try {
        await this.$store.dispatch("article_types/list", {
          resource: this.item.concerned_resource,
        });

        if (this.all && this.all.length) {
          this.setType({});
          // this.item.type = this.all[0].value;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Une erreur est survenue lors de la récupération des Types.`,
        });
      }
    },

    queryType(input, callback) {
      let types = this.all;
      let results = input ? types.filter(this.createFilter(input)) : types;

      //call callback function to return suggestions
      callback(results);
    },
    async handleEditType() {
      this.modals.isLoading = true;
      let payload = {
        id: this.articleTypeForm.id,
        name: this.articleTypeForm.name,
        concerned_resource: this.item.concerned_resource,
      };
      try {
        await this.$store.dispatch("article_types/update", payload);
        await this.getTypes();

        this.modals.articleTypeModal = false;
        this.$notify({
          type: "success",
          message: "Type modifié avec succès.",
        });
      } catch (error) {
        // this.articleTypeForm.name = ''
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.modals.isLoading = false;
    },

    handleSelect(t) {
      this.setType(t);
      this.item.type = t.value;
    },

    async deleteType() {
      const confirmation = await swal.fire({
        title: `Supprimer le type`,
        html: `<p>Confirmez-vous la suppression du type <b>${this.item.type}</b>?</p>`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Oui, supprimer`,
        cancelButtonText: "Non, annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        },
      });

      try {
        if (confirmation.value === true) {
          let type = this.getTypeByName(this.item.type);
          await this.$store.dispatch("article_types/destroy", { id: type.id });
          await this.getTypes();
          this.$notify({
            type: "success",
            message: "Le type a été supprimé avec succès.",
          });
        }
      } catch (error) {
        if (error.response !== undefined) {
          this.$notify({
            type: "danger",
            message: error.response?.data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message:
              "Désolé, une erreur est survenue lors de la suppression du type!",
          });
        }
      }
    },

    ...mapMutations({
      setType: "SET_RESOURCE",
    }),

    openEditModal() {
      if (Object.keys(this.type).length) {
        this.articleTypeForm = {
          id: this.type.id,
          name: this.type.value,
        };
      } else if (this.item.type.length && this.getTypeByName(this.item.type)) {
        let type = this.getTypeByName(this.item.type);
        this.articleTypeForm = {
          id: type.id,
          name: type.name,
        };
      } else {
        this.articleTypeForm.name = "";
      }
      this.modals.articleTypeModal = true;
    },

    createFilter(input) {
      return (type) => {
        return type.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
      };
    },
  },
};
