import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function list(payload) {
  const requestOptions = {
    ...options,
    params: payload.params,
  };

  return axios.get(`${url}/admin/bookings`, requestOptions).then((response) => {
    return {
      list: response.data.data,
      meta: response.data.meta,
    };
  });
}


function destroy(id) {
  return axios.delete(`${url}/items/${id}`, options);
}

export default {
  list,
};
