<template>
  <div>
    <collapse v-if="initialQuestions.length" :active-index="0">
      <collapse-item
        :id="`question-${question.id}`"
        :title="`collapse-${index}`"
        v-for="(question, index) in initialQuestions"
        :key="index"
        :name="`question${index + 1}`"
      >
        <h5 slot="title" class="mb-0">{{ question.name }}</h5>
        <div>
          <div class="d-flex justify-content-start mb-2">
            <div class="d-flex align-items-center align-content-center">
              <EditQuestion
                @questionUpdated="$emit('refreshQuestionsCollapse')"
                :question="question"
                :shs="sectionHasSubmits"
              />
            </div>
          </div>
          <div class="row py-3">
            <div class="col-lg-6">
              <div class="row mb-3">
                <div class="col-3">
                  <b>Question :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.name }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-3">
                  <b>Type de champs :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.answer_type }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-3">
                  <b>Requis :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.answer_required ? "Oui" : "Non" }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row mb-3">
                <div class="col-3">
                  <b>Tooltip :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.tooltip }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-3">
                  <b>Validation :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.pattern }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-3">
                  <b>Message d'Erreur :</b>
                </div>
                <div class="col-9">
                  <span>{{ question.error_message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </collapse-item>
    </collapse>
    <div v-else>
      <i class="text-muted"
        >Aucune question n'est enregistrée pour cette section! Veuillez cliquer
        sur le bouton <span class="text-warning">Ajouter Question</span> pour
        ajouter</i
      >
    </div>
  </div>
</template>

<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import EditQuestion from "@/views/UserForms/Forms/Questions/EditQuestion.vue";

export default {
  name: "SectionQuestionsCollapse",
  props: {
    sectionHasSubmits: {
      type: Boolean,
      required: true,
    },
    initialQuestions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["refreshQuestionsCollapse"],
  components: { Collapse, CollapseItem, EditQuestion },
};
</script>

<style></style>
