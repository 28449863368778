<template>
  <div>
    <collapse v-if="initialEpisodes.length" :active-index="0">
      <collapse-item :id="`episode-${support.id}`" :title="`collapse-${index}`" v-for="(support, index) in initialEpisodes"
        :key="index" :name="`episode${index + 1}`">
        <h5 slot="title" class="mb-0">{{ support.title }}</h5>
        <div>
          <div class="d-flex justify-content-between mb-4">
            <div class="d-flex align-items-center align-content-center">
              <h6 class="heading-small text-muted">{{ support.title }}</h6>
              <!-- <EditSectionModal @supportUpdated="$emit('supportUpdated')" :id="support.id" :supportName.sync="support.name" :fhs="formHasSubmits" /> -->
            </div>
          </div>
          <div class="row py-3">
            <div class="col-lg-6 pb-4">
              <div class="d-flex flex-column align-items-start">
                <span><b class="mr-1">Type :</b> {{ support.media_type.toUpperCase() }}</span>
                <span class="d-block text-nowrap"><b class="mr-1">Contenu :</b>{{ (support.media_url != null) && (support.media_url.length > 0) ? support.media_url[0] : '' }}</span>
                <span><b class="mr-1">Auteur :</b>{{ support.author }}</span>
                <p>{{ support.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </collapse-item>
    </collapse>
    <div v-else>
      <i class="text-muted">Aucun support n'est enregistré pour cette formation! Veuillez cliquer sur le bouton <span class="text-primary">Ajouter Support</span> pour ajouter</i>
    </div>
  </div>
</template>

<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";

export default {
  name: "TrainingSupportsCollapse",
  props:{
     formId: String,
     formHasSubmits: Boolean,
     initialEpisodes: {
      type: Array,
      default: () => [],
     }
    },
  components: { Collapse, CollapseItem },
};
</script>

<style>
</style>