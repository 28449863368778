<template>
  <div class="row align-items-center">
    <div class="col-8 d-flex flex-row">
      <h3 class="mb-0">Liste des sections</h3>
      <base-button type="primary" icon size="sm" @click="modals.form = true">
        <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
        <span class="btn-inner--text">Ajouter</span>
      </base-button>
    </div>
    <div class="col-4 text-right">
      <el-select
        v-model="type"
        @change="(val) => type = val"
        filterable
        placeholder="Sélectionner le type"
      >
        <el-option
          v-for="option in options"
          :key="option.label"
          :label="option.label"
          :value="option.value"
        >
        </el-option>
      </el-select>
      <modal :show.sync="modals.form" body-classes="p-0">
        <h6 slot="header" class="modal-title">Ajouter une {{ getType(type).name }}</h6>
        <form role="form" @submit.prevent="handleSubmit">
          <card
            type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0"
          >
            <template>
              <base-input
                alternative
                v-model="formModal.name"
                class="mb-3"
                placeholder="Nom de la Section"
                prepend-icon="ni ni-tag"
              />
              <validation-error :errors="apiValidationErrors.name" />
            </template>
          </card>
          <div class="d-flex justify-content-between my-4 px-lg-5">
            <base-button
              type="primary"
              :loading="isLoading"
              native-type="submit"
              >Enregistrer</base-button
            >
            <base-button type="danger" @click="modals.form = false"
              >Annuler</base-button
            >
          </div>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
import { Modal } from "@/components";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";

import { Select, Option } from "element-ui";
export default {
  emits: ["sectionCreated"],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    currentType: {
      type: String,
      default: "",
    },
    getType: {
      type: Function
    }
  },
  components: {
    ValidationError,
    Modal,
    BaseButton,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [formMixin],
  data() {
    return {
      isLoading: false,
      modals: {
        form: false,
      },
      formModal: {
        name: "",
      },
    };
  },
  computed: {
    type: {
      get: function () {
        return this.currentType;
      },
      set: function (val) {
        this.$emit('update:currentType', val);
      },
    },
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      let payload = { params: {...this.formModal, type: this.type } };
      try {
        await this.$store.dispatch("form_sections/add", payload);
        this.modals.form = false;

        this.$notify({
          type: "success",
          message: `${this.getType(this.type).name} ajoutée avec succès.`,
        });

        this.$nextTick(() => {
          this.formModal = { name: "" };
        }, this.$emit("sectionCreated"));
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false;
      }
    },
  },
};
</script>
