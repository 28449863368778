import service from '@/store/services/articles/article-sections-service';

const state = {
    list: [],
    section: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, section) => {
        state.section = section;
    },
};

const actions = {
    list({ commit, dispatch }) {
        return service.list()
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
            });
    },

    get({ commit, dispatch }, params) {
        return service.get(params)
            .then((section) => { commit('SET_RESOURCE', section); });
    },

    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((section) => { commit('SET_RESOURCE', section); });
    },

    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((section) => { commit('SET_RESOURCE', section); });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    all: state => state.list.map(t => ({
        id: t.id,
        value: t.name,
        slug: t.slug
    })),
    getSectionByName: state => (name) => {
        return state.list.find(t => t.name === name)
    },
    section: state => state.section,
};

const article_sections = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default article_sections;