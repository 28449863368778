<template>
  <div class="row align-items-center">
    <div class="col-8">
      <h3 class="mb-0">
        <base-button
          @click="$router.push({ name: 'Paramètre Formulaires' })"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-arrow-left"></i>
        </base-button>
        Questions
      </h3>
    </div>
    <div v-if="isArchived" class="col-4 text-right">
      <span class="badge badge-danger"
        ><i class="fas fa-archive mr-1"></i> Archivée</span
      >
    </div>
    <div v-else class="col-4 text-right">
      <base-button
        type="warning"
        @click="modals.question = true"
        :disabled="isArchived"
      >
        <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
        <span class="btn-inner--text">Ajouter une Question</span>
      </base-button>
      <base-button type="danger" @click="deleteSection" :disabled="isArchived">
        <span class="btn-inner--icon"><i class="fas fa-archive"></i></span>
        <span class="btn-inner--text"
          >{{ sectionHasSubmits ? "Archiver" : "Supprimer" }} la Section</span
        >
      </base-button>

      <QuestionModal
        :openModal.sync="modals.question"
        :questionData="formModal"
        :optionData="option"
        @questionHandled="$emit('questionCreated')"
      />
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseButton from "@/components/BaseButton.vue";

import QuestionModal from "@/components/Modals/QuestionModal.vue";
export default {
  name: "AddQuestion",
  emits: ["questionCreated"],
  props: {
    isArchived: {
      type: Boolean,
      required: true,
    },
    sectionHasSubmits: {
      type: Boolean,
      required: true,
    },
    sectionHasForms: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
    QuestionModal,
  },
  data() {
    return {
      modals: {
        question: false,
      },
      formModal: {
        name: "",
        description: "",
        answer_required: true,
        answer_type: "",
        pattern: "",
        error_messsge: "",
        tooltip: "",
        allow_multiple_option_answers: false,
      },
      option: {
        name: "",
        slug: "",
        choices: [{ name: "" }],
      },
    };
  },
  methods: {
    async deleteSection() {
      let alertObject = {
        name: "Supprimer",
        icon: "warning",
        content: "de supprimer",
      };
      if (this.sectionHasSubmits) {
        alertObject = { name: "Archiver", icon: "info", content: "d'archiver" };
      }
      const confirmation = await swal.fire({
        icon: `${alertObject.icon}`,
        title: `${alertObject.name} cette section?`,
        html: `${
          this.sectionHasForms && !this.sectionHasSubmits
            ? "<p>Cette section est associées à un ou plusieurs formulaire(s).</p><hr/>"
            : ""
        }<p class="font-weight-lighter font-italic"><sup class="font-weight-bold text-warning">*</sup>${
          alertObject.name
        } cette section inclus le faite ${
          alertObject.content
        } ses options et annuler ses dépendances.</p>`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `${alertObject.name}`,
        cancelButtonText: "Garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("form_sections/destroy", {
            id: this.$route.params.id,
          });
          this.$router.go(-1);
          if (this.sectionHasSubmits) {
            this.$notify({
              type: "info",
              message: "La section a été archivée avec succès.",
            });
          } else {
            this.$notify({
              type: "success",
              message: "La section a été supprimée avec succès.",
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur est survenue lors de la suppression de la section!",
        });
      }
    },
  },
};
</script>
