import service from "@/store/services/userForms/form-types-service";

const state = {
  list: [],
  type: {},
  sub_type: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_SUB_RESOURCE: (state, type) => {
    state.sub_type = type;
  },
  SET_RESOURCE: (state, type) => {
    state.type = type;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((type) => {
      commit("SET_RESOURCE", type);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((type) => {
      commit("SET_RESOURCE", type);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((type) => {
      commit("SET_RESOURCE", type);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  all: (state) => (types) => {
   let mappedTypes =  types.map((t) => ({
      id: t.id,
      value: t.name,
      slug: t.slug,
      parent: t.parent,
      description: t.description,
    }))
    return mappedTypes;
  },
  allParentTypes: (state, getters) => {
    let types = [...state.list]
    return getters.all(types);
  },
  allChildrenTypes: (state, getters) => {
    if (Object.keys(state.type).length) {
        let currentType = state.list.find(t => t.id == getters.type?.id) // find the selected type in original types list
        let types = [...currentType.sub_types]
        
        return getters.all(types);
    }
    return [];
  },
  getTypeByName:
    (state, getters) =>
    (name, level = "parent") => {
      if (level == "parent")
        return getters.allParentTypes.find((t) => t.value === name);
      else if (level == "child")
        return getters.allChildrenTypes.find((t) => t.value === name);
      else return "";
    },
  type: (state) => state.type,
  subType: (state) => state.sub_type,
};

const form_types = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default form_types;
