import service from '@/store/services/userForms/submits-service'

const state = {
    list: [],
    submit: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, item) => {
        state.submit = item;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
};

const actions = {
    list({ commit, dispatch }, payload) {
        return service.list(payload)
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            });
    },

    get({ commit }, params) {
        return service.get(params)
            .then((submit) => { commit('SET_RESOURCE', submit); });
    },

    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((submit) => { commit('SET_RESOURCE', submit); });
    },

    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((submit) => { commit('SET_RESOURCE', submit); });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    list: state => state.list,
    listTotal: state => state.meta.total,
    submit: state => {
        let s = {...state.submit }
        s.sections = s.sections.map((section) => {
            const completedDate = section.pivot.completed_on
            let questionWithAnswers = mapAnswers(section.questions, s.answers);
            let countAnswers = questionWithAnswers.reduce((acc, currQuestion) => acc + ((currQuestion.answer != null) ? 1 : 0 ), 0)
            
            return {
                name: section.name,
                type: section.type,
                completed_on: completedDate,
                has_answers: countAnswers > 0,
                answers: questionWithAnswers
            }
        }).sort((prevSec, nextSec) => {
            return new Date(prevSec.completed_on) - new Date(nextSec.completed_on)
        })
        return s;
    },
};

const submits = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

function mapAnswers(questions, answers) {
    questions.forEach(q => {
        let answerIsFounded = answers.find(a => a.question_id === q.id);
        answerIsFounded ? q.answer = answerIsFounded : null;
    });
    return questions;
}

export default submits;