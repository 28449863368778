<template>
  <div class="content">
    <base-header type="primary" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ajouter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Créer Formation</h3>
              </div>
              <div class="col-4 text-right">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <form ref="warning_form" @submit.prevent="handleSubmit">
                <!-- row 1 -->
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Titre" v-model="item.title" />
                  </div>
                  <div class="col-lg-6">
                    <div class="d-flex flex-row">
                      <base-input label="Type" class="w-75">
                        <el-autocomplete
                          class="d-block"
                          v-model="item.type"
                          :fetch-suggestions="queryType"
                          placeholder="Selectionner..."
                          @select="handleSelect"
                        >
                        </el-autocomplete>
                      </base-input>
                      <div
                        v-if="getTypeByName(item.type)"
                        class="px-2 pt-2 align-self-center"
                      >
                        <base-button
                          type="primary"
                          @click="openEditModal"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-pen"></i
                          ></span>
                        </base-button>
                        <base-button
                          type="danger"
                          @click="deleteType"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </base-button>
                      </div>
                    </div>
                    <validation-error :errors="apiValidationErrors.type" />
                  </div>
                </div>

                <!-- row 2 -->
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      label="Date de début"
                      type="date"
                      v-model="item.from_date"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="date"
                      label="Date de fin"
                      v-model="item.to_date"
                    />
                  </div>
                </div>

                <!-- row 3 -->
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de début"
                      type="time"
                      v-model="item.from_time"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de fin"
                      type="time"
                      v-model="item.to_time"
                    />
                  </div>
                </div>

                <base-input label="Description">
                  <html-editor v-model="item.description" name="editor" />
                </base-input>
                <validation-error :errors="apiValidationErrors.excerpt" />

                <div class="my-4">
                  <base-button
                    type="button"
                    class="btn btn-sm btn-primary"
                    native-type="submit"
                  >
                    Ajouter Formation
                  </base-button>
                </div>
              </form>

              <!-- Modal edit formation type -->
              <modal
                :show.sync="modals.editTypeModal"
                header-classes="bg-success text-white"
                body-classes="p-0"
              >
                <div
                  slot="header"
                  class="modal-title text-white d-inline-block"
                >
                  <i class="fas fa-info-circle display-4"></i>
                  <h2 class="text-white d-inline ml-3">Modifier le type</h2>
                </div>
                <form role="form" @submit.prevent="handleEditType">
                  <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
                  >
                    <template>
                      <div class="row">
                        <div class="col-lg-8">
                          <base-input
                            name="Type"
                            v-model="typeForm.name"
                            placeholder="Entrer votre type"
                            required
                          />
                        </div>
                      </div>
                    </template>
                  </card>
                  <div class="modal-footer d-flex justify-content-between">
                    <base-button
                      type="primary"
                      :loading="isLoading"
                      native-type="submit"
                      >Enregistrer</base-button
                    >
                    <base-button
                      type="danger"
                      @click="modals.editTypeModal = false"
                      >Annuler</base-button
                    >
                  </div>
                </form>
              </modal>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations } = createNamespacedHelpers("form_types");

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Autocomplete } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
moment.locale("fr");

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      isLoading: false,
      item: {
        type: "",
        title: "",
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        description: "",
        excerpt: "",
        user: null,
      },
      modals: {
        editTypeModal: false,
      },
      typeForm: { name: "" },
    };
  },

  computed: {
    ...mapGetters(["all", "getTypeByName", "type"]),
  },
  created() {
    this.getTypes();
  },
  mounted() {
    this.initDateTime();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "Liste Formation DPO" });
    },

    async getTypes() {
      try {
        await this.$store.dispatch("form_types/list", {
          resource: "formation-dpo",
        });

        if (this.all && this.all.length) {
          this.setType({});
          this.item.type = this.all[0].value;
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
        this.setApiValidation(error.response.data.data.errors);
      }
    },

    queryType(input, callback) {
      let types = this.all;
      let results = input ? types.filter(this.createFilter(input)) : types;

      //call callback function to return suggestions
      callback(results);
    },

    initDateTime() {
      // date
      this.item.from_date = moment().format("YYYY-MM-DD");
      this.item.to_date = moment(this.item.from_date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");

      // // time
      this.item.from_time = "08:00:00";
      this.item.to_time = moment(this.item.from_time, "hh:mm")
        .add(9, "hours")
        .format("HH:mm:ss");
    },

    async handleSubmit() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
      //   });
      //   return;
      // }
      this.item.user = this.user.id;
      this.item.excerpt = this.item.description;
      try {
        delete this.item.id;
        await this.$store.dispatch("formations/addFormation", this.item);
        this.item = await this.$store.getters["formations/formation"];

        this.$router.push({
          name: "Détails Formation DPO",
          params: { id: this.item.uid },
        });

        this.$notify({
          type: "success",
          message: "Formation créée avec succès",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        // this.setApiValidation(error);
      }
    },

    handleSelect(t) {
      this.setType(t);
      this.item.type = t.value;
    },
    createFilter(input) {
      return (type) => {
        return type.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
      };
    },

    async deleteType() {
      const confirmation = await swal.fire({
        title: `Supprimer le type`,
        html: `<p>Confirmez-vous la suppression du type <b>${this.item.type}</b>?</p>`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Oui, supprimer`,
        cancelButtonText: "Non, annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        },
      });

      try {
        if (confirmation.value === true) {
          let type = this.getTypeByName(this.item.type);
          await this.$store.dispatch("form_types/destroy", { id: type.id });
          await this.getTypes();
          this.$notify({
            type: "success",
            message: "Le type a été supprimé avec succès.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur est survenue lors de la suppression du type!",
        });
      }
    },

    ...mapMutations({
      setType: "SET_RESOURCE",
    }),

    openEditModal() {
      if (Object.keys(this.type).length) {
        this.typeForm = {
          id: this.type.id,
          name: this.type.value,
        };
      } else if (this.item.type.length && this.getTypeByName(this.item.type)) {
        let type = this.getTypeByName(this.item.type);
        this.typeForm = {
          id: type.id,
          name: type.name,
        };
      } else {
        this.typeForm.name = "";
      }
      this.modals.editTypeModal = true;
    },

    async handleEditType() {
      this.isLoading = true;
      let payload = {
        id: this.typeForm.id,
        name: this.typeForm.name,
      };
      try {
        await this.$store.dispatch("form_types/update", payload);
        await this.getTypes();

        this.modals.editTypeModal = false;
        this.$notify({
          type: "success",
          message: "Type modifié avec succès.",
        });
      } catch (error) {
        this.typeForm.name = "";
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },
  },
};
</script>
