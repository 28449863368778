import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(payload) {
    const options = {
        params: payload.params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        }
    };

    return axios.get(`${url}/admin/dpo/trainings`, options).then(response => {
        return {
            list: response.data.data,
            meta: response.data.meta
        };
    });
}

function get(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };
    return axios
        .get(`${url}/admin/dpo/trainings/${payload.id}`, options)
        .then(response => {
            return response.data.data
        });
}

function add(payload) {

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/admin/dpo/trainings`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function update(payload) {

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/admin/dpo/trainings/${payload.id}`, payload.item, options)
        .then(response => {
            return response.data.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/admin/dpo/trainings/${id}`, options);
}

export default {
    list,
    get,
    add,
    update,
    destroy,
};