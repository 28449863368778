<template>
  <form class="mt-5" @submit.prevent="$emit('updateTraining', formationData)">
    <base-input
      label="Titre"
      labelClasses="text-secondary"
      v-model="formationData.title"
    />
    <validation-error :errors="errors.title" />

    <base-input
      label="Formateur"
      labelClasses="text-secondary"
      v-model="formationData.former"
    />
    <validation-error :errors="errors.former" />

    <!-- row 2 -->
    <div class="row">
      <div class="col-lg-6">
        <base-input
          label="Date de début"
          labelClasses="text-secondary"
          type="date"
          v-model="formationData.from_date"
        />
        <validation-error :errors="errors.from_date" />
      </div>
      <div class="col-lg-6">
        <base-input type="date" label="Date de fin" labelClasses="text-secondary" v-model="formationData.to_date" />
        <validation-error :errors="errors.to_date" />
      </div>
    </div>

    <!-- row 3 -->
    <div class="row">
      <div class="col-lg-6">
        <base-input
          label="Heure de début"
          labelClasses="text-secondary"
          type="time"
          v-model="formationData.from_time"
        />
        <validation-error :errors="errors.from_time" />
      </div>
      <div class="col-lg-6">
        <base-input label="Heure de fin" labelClasses="text-secondary" type="time" v-model="formationData.to_time" />
        <validation-error :errors="errors.to_time" />
      </div>
    </div>

    <base-input label="Description" labelClasses="text-secondary">
      <html-editor
        class="w-100"
        v-model="formationData.description"
        name="editor"
      />
    </base-input>
    <validation-error :errors="errors.description" />
    <div class="mb-5">
      <base-button
        v-show="headerState === 'editing'"
        @click="$emit('updateState', 'showing')"
        type="button"
        class="btn btn-warning"
        >Annuler</base-button
      >
      <base-button type="button" class="btn btn-primary" native-type="submit">
        Enregistrer
      </base-button>
    </div>
  </form>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
export default {
  name: "EditTrainingPage",
  props: {
    state: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default(rawProps) {
        return [];
      },
    },
  },
  components: {
    HtmlEditor,
    BaseInput,
    BaseButton,
    ValidationError,
  },

  data() {
    return {
      formationData: {
        title: "",
        former: "",
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        description: "",
      },
    };
  },

  computed: {
    headerState() {
      return this.state;
    },
    headerErrors() {
      return this.errors;
    },
  },

  created() {
    this.getFormationFields();
  },

  methods: {
    getFormationFields() {
      const { title, former, description, from_date, to_date, from_time, to_time, type } = this.data;
      this.formationData.title = title;
      this.formationData.former = former;
      this.formationData.description = description;
      this.formationData.from_date = from_date;
      this.formationData.to_date = to_date;
      this.formationData.from_time = from_time;
      this.formationData.to_time = to_time;
    },
  },
};
</script>

<style></style>
