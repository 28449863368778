<template>
  <div class="media-files d-inline-flex">
    <div class="form-group pr-4">
      <label class="form-control-label"> Image </label>
      <div v-if="image" class="profile-image card-img pb-4">
        <img :src="`${image}`" class="profile-image argon-image" />
      </div>
      <div v-else class="profile-image">
        <img src="/img/placeholder-image.jpeg" class="argon-image img-fluid" />
      </div>
      <div class="image-upload">
        <base-button v-if="image" type="button" class="btn btn-sm btn-danger" @click="removeImage">
          <span>
            <i class="fa fa-times" />
            Retirer
          </span>
        </base-button>
        <base-button type="button" class="btn btn-sm btn-primary">
          <label v-if="!image" for="imageInput" class="mb-0">Selectionner une image</label>
          <label v-else for="imageInput" class="mb-0">Changer</label>
          <input id="imageInput" ref="imageInput" accept="image/*" type="file" style="display: none"
            @change="onSelectFile('image', $event)" />
        </base-button>
      </div>
    </div>
    <div class="form-group pl-4">
      <label class="form-control-label">Document </label>
      <div v-if="document" class="profile-image pb-4">
        <iframe :src="`${document}`" class="argon-image img-fluid"></iframe>
      </div>
      <div v-else class="profile-image">
        <img src="/img/placeholder-document.png" class="argon-image img-fluid" />
      </div>
      <div class="document-upload">
        <base-button v-if="document" type="button" class="btn btn-sm btn-danger" @click="removeDocument">
          <span>
            <i class="fa fa-times" />
            Retirer
          </span>
        </base-button>
        <base-button type="button" class="btn btn-sm btn-primary">
          <label v-if="!document" for="documentInput" class="mb-0">Selectionner un .pdf</label>
          <label v-else for="documentInput" class="mb-0">Changer</label>
          <input id="documentInput" ref="documentInput" accept=".pdf" type="file" style="display: none"
            @change="onSelectFile('document', $event)" />
        </base-button>
      </div>
    </div>
    <validation-error :errors="errors.image" />
    <validation-error :errors="errors.document" />
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "UploadMediaForm",
  props: {
    files: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Object,
      default: () => ({
        image: null,
        document: null
      })
    }
  },
  components: {
    BaseButton,
    ValidationError,
  },
  data() {
    return {
      image: null,
      document: null,
    }
  },
  methods: {
    onSelectFile(resource, e) {
      let uploadFiles = e.target.files || e.dataTransfer.files;
      if (!uploadFiles.length) return;
      this.createMedia(uploadFiles[0], resource)
    },

    //Format the preview of file uploaded
    createMedia(file, resource) {
      let reader = new FileReader();
      reader.onload = (e) => {
        if (resource === 'image') {
          this.image = e.target.result;
        } else {
          this.document = e.target.result;
        }
        this.files.push({ type: resource, file: file });
      };
      reader.readAsDataURL(file);

      this.$emit('update:files', this.files)
    },
    removeImage() {
      this.image = null;
    },
    removeDocument() {
      this.document = null;
    },
  }
}
</script>