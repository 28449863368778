import service from '@/store/services/userForms/form-questions-service';

const state = {
    list: [],
    question: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, question) => {
        state.question = question;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    }
};

const actions = {
    list({ commit, dispatch }, params) {
        return service.list(params)
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            });
    },

    get({ commit, dispatch }, params) {
        return service.get(params)
            .then((question) => { commit('SET_RESOURCE', question); });
    },

    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((question) => { commit('SET_RESOURCE', question); });
    },

    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((question) => { commit('SET_RESOURCE', question); });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    list: state => state.list,
    question: state => state.question,
};

const form_questions = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default form_questions;