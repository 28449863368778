import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();
const defaultHeader = {
  Accept: "application/vnd.api+json",
  "Content-Type": "application/vnd.api+json",
};

function list(params) {
  const options = {
    headers: defaultHeader,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/admin/sections`, options).then((response) => {
    return {
      list: response.data.data,
      meta: response.data.meta,
    };
  });
}

async function allByType(payload) {
  const options = {
    headers: defaultHeader,
  };
  const response = await axios.get(
    `${url}/admin/types/${payload.type}/sections`,
    options
  );
  return response.data.data;
}

function get(payload) {
  const options = {
    headers: defaultHeader,
  };

  return axios
    .get(`${url}/admin/sections/${payload.id}`, options)
    .then((response) => {
      let section = response.data.data;
      return section;
    });
}

function add(payload) {
  const options = {
    headers: defaultHeader,
  };

  return axios
    .post(`${url}/admin/sections`, payload.params, options)
    .then((response) => {
      return response.data.data;
    });
}

function update(payload) {
  const options = {
    headers: defaultHeader,
  };

  return axios
    .put(`${url}/admin/sections/${payload.id}`, payload.item, options)
    .then((response) => {
      return response.data.data;
    });
}

function destroy(payload) {
  const options = {
    headers: defaultHeader,
  };

  return axios.delete(`${url}/admin/sections/${payload.id}`, options);
}

export default {
  list,
  allByType,
  get,
  add,
  update,
  destroy,
};
