<template>
  <div class="row align-items-center">
    <div class="col-lg-8">
      <h3 class="mb-0">Liste des Traitements</h3>
      <base-button type="primary" icon size="sm" @click="modals.form = true">
        <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
        <span class="btn-inner--text">Ajouter</span>
      </base-button>
    </div>
    <modal :show.sync="modals.form" body-classes="p-0">
      <h6 slot="header" class="modal-title">Ajouter un traitement</h6>
      <div v-if="!this.isLoading">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
      <form v-else role="form" @submit.prevent="handleSubmit">
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <base-input
              alternative
              v-model="formModal.name"
              class="mb-3"
              placeholder="Intitulé du traitement"
              prepend-icon="ni ni-tag"
            />
            <base-input v-if="types.length > 0" alternative>
              <el-select
                name="parent"
                v-model="formModal.parent_id"
                prepend-icon="fas fa-user"
                placeholder="Selectionner un traitement parent"
              >
                <el-option
                  v-for="t in types"
                  :key="t.id"
                  :value="t.id"
                  :label="t.name"
                />
              </el-select>
            </base-input>
          </template>
        </card>
        <div class="d-flex justify-content-between my-4 px-lg-5">
          <base-button type="primary" :loading="isLoading" native-type="submit"
            >Enregistrer</base-button
          >
          <base-button type="danger" @click="modals.form = false"
            >Annuler</base-button
          >
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { Modal } from "@/components";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";

import { Select, Option } from "element-ui";
export default {
  emits: ["typeCreated"],
  components: {
    Modal,
    BaseButton,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      isLoading: false,
      modals: {
        form: false,
      },
      formModal: {
        name: "",
        parent_id: null,
      },
    };
  },

  methods: {
    async handleSubmit() {
      this.isLoading = true;
      let payload = {
        params: this.formModal,
      };
      try {
        await this.$store.dispatch("form_sections/add", payload);
        // let section = this.$store.getters["form_sections/section"];
        this.modals.form = false;

        this.$notify({
          type: "success",
          message: "Traitement ajouté avec succès.",
        });

        this.$nextTick(() => {
          this.formModal = { name: "", parent_id: null };
        }, this.$emit("typeCreated"));
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },
  },
};
</script>