import service from '@/store/services/thematics-service';

const state = {
    list: [],
    thematic: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, thematic) => {
        state.thematic = thematic;
    },
};

const actions = {
    list({ commit, dispatch }, payload) {
        return service.list(payload.params)
            .then(({ list }) => {
                commit('SET_LIST', list);
            });
    },

    get({ commit, dispatch }, params) {
        return service.get(params)
            .then((thematic) => { commit('SET_RESOURCE', thematic); });
    },

    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((thematic) => { commit('SET_RESOURCE', thematic); });
    },

    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((thematic) => { commit('SET_RESOURCE', thematic); });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    all: state => state.list.map(t => ({
        id: t.id,
        value: t.name,
        slug: t.slug
    })),
    getThematicByName: state => (name) => {
        return state.list.find(t => t.name === name)
    },
    thematic: state => state.thematic,
};

const thematics = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default thematics;