import qs from "qs";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json"
    }
};

function list(params) {
    let requestOptions = {
        ...options,
        params: params,
    }

    return axios.get(`${url}/admin/thematics`, requestOptions).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function get(id) {
    return axios.get(`${url}/admin/thematics/${id}`, options).then(response => {
        return response.data.data;
    });
}

function add(payload) {
    return axios.post(`${url}/admin/thematics`, payload, options).then(response => {
        return response.data.data;
    });
}

function update(payload) {
    return axios
        .put(`${url}/admin/thematics/${payload.id}`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function destroy(payload) {
    return axios.delete(`${url}/admin/thematics/${payload.id}`, options);
}

export default {
    list,
    get,
    add,
    update,
    destroy
};