<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ajouter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Permission</h3>
              </div>
              <div class="col-4 text-right">
                <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <form ref="permission_form" @submit.prevent="handleSubmit">
              <base-input label="Rôle">
                  <el-select v-model="permission.roles" prepend-icon="fas fa-user" multiple placeholder="Selectionner...">
                    <el-option v-for="single_role in all_roles" :key="single_role.id" :value="single_role.id"
                      :label="single_role.name">
                    </el-option>
                  </el-select>
                </base-input>
                <validation-error :errors="apiValidationErrors.roles" />

                <base-input label="Nom" prepend-icon="fas fa-user" v-model="permission.name" placeholder="Permission" />
                <validation-error :errors="apiValidationErrors.name" />

                <div class="my-4">
                  <base-button type="button" class="btn btn-sm btn-primary" native-type="submit">
                    Ajouter Permission
                  </base-button>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
export default {
  layout: "DashboardLayout",

  components: {
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      all_roles: [],
      permission: {
        name: null,
        roles: [],
      }
    };
  },

  created() {
    this.getRoles();
  },

  methods: {
    async getRoles() {
      try {
        await this.$store.dispatch("roles/list");
        this.all_roles = await this.$store.getters["roles/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "Liste Permissions" });
    },

    async handleSubmit() {
      try {
        await this.$store.dispatch("permissions/add", this.permission);

        this.$notify({
          type: "success",
          message: "Permission ajoutée avec succès."
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!"
        });
        this.setApiValidation(error.response.data.errors);
      }
    }
  }
};
</script>
