import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } =
  createNamespacedHelpers("article_categories");
const EXCLUDED_RESOURCE = 'documentations'
export default {
  data() {
    return {
      categoryModal: {
        isOpen: false,
        isLoading: false,
      },
      categoryForm: { name: "" },
      toggleCategory: false,
    };
  },
  watch: {
    toggleCategory: {
      handler: function (newVal, oldVal) {
        if (newVal == '' && this.resource?.slug != EXCLUDED_RESOURCE) {
          this.item.category = "";
          this.item.section = "";
          this.toggleSection = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      allCategories: "all",
      getCategoryByName: "getCategoryByName",
      category: "category",
    }),
    // keep section checkbox disabled if user does not checked category & type category name
    categoryAdded() {
      return this.toggleCategory && this.item.category != "";
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        await this.$store.dispatch("article_categories/list");

        if (this.allCategories && this.allCategories.length) {
          this.setCategory({});
          // this.item.category = this.allCategories[0].value;
        }
      } catch (error) {
        if (error.response !== undefined) {
          this.$notify({
            type: "danger",
            message: error.response?.data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message: `Une erreur est survenue lors de la récupération des Catégories.`,
          });
        }
      }
    },

    queryCategory(input, callback) {
      let types = this.allCategories;
      let results = input
        ? types.filter(this.createCategoryFilter(input))
        : types;

      //call callback function to return suggestions
      callback(results);
    },
    async handleEditCategory() {
      this.isLoading = true;
      let payload = {
        id: this.categoryForm.id,
        name: this.categoryForm.name,
      };
      try {
        await this.$store.dispatch("article_categories/update", payload);
        await this.getCategories();

        this.categoryModal.isOpen = false;
        this.$notify({
          type: "success",
          message: "Catégorie modifiée avec succès.",
        });
      } catch (error) {
        this.categoryForm.name = "";
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },

    handleCategorySelect(t) {
      this.setCategory(t);
      this.item.category = t.value;
    },

    async deleteCategory() {
      const confirmation = await swal.fire({
        title: `Supprimer le type`,
        html: `<p>Confirmez-vous la suppression de la catégorie <b>${this.item.category}</b>?</p>`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Oui, supprimer`,
        cancelButtonText: "Non, annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        },
      });

      try {
        if (confirmation.value === true) {
          let category = this.getCategoryByName(this.item.category);
          await this.$store.dispatch("article_categories/destroy", {
            id: category.id,
          });
          await this.getCategories();
          this.$notify({
            type: "success",
            message: "La catégorie a été supprimée avec succès.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur est survenue lors de la suppression de la catégorie!",
        });
      }
    },

    ...mapMutations({
      setCategory: "SET_RESOURCE",
    }),

    openEditCategoryModal() {
      if (Object.keys(this.category).length) {
        this.categoryForm = {
          id: this.category.id,
          name: this.category.value,
        };
      } else if (
        this.item.category.length &&
        this.getCategoryByName(this.item.category)
      ) {
        let category = this.getCategoryByName(this.item.category);
        this.categoryForm = {
          id: category.id,
          name: category.name,
        };
      } else {
        this.categoryForm.name = "";
      }
      this.categoryModal.isOpen = true;
    },

    createCategoryFilter(input) {
      return (category) => {
        return category.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
      };
    },
  },
};
