<template>
  <div class="content">
    <base-header type="warning" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Détails</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="mt--6">
      <div class="header pb-6 d-flex align-items-center profile-header">
        <!-- Mask -->
        <span class="mask bg-gradient-default opacity-8"></span>
        <!-- Header container -->
        <div class="container-fluid">
          <div class="row">
            <Transition mode="out-in" :duration="{ enter: 900, leave: 800 }">
              <div
                v-if="sectionState === 'showing'"
                class="col-lg-7 col-md-10 text-nowrap"
              >
                <h1 class="display-3 text-white mb-3">
                  {{ sectionForm.name }} <i>({{ sectionForm.of_type }})</i>
                </h1>
                <base-button @click="sectionState = 'editing'" type="secondary" size="sm"
                  >Modifier les Informations</base-button
                >
              </div>
              <div
                v-else-if="sectionState === 'editing'"
                class="col-lg-7 col-md-10 text-wrap"
              >
                <edit-section-page
                  @updateState="(state) => sectionState = state"
                  @updateSection="handleSubmit"
                  :state="sectionState"
                  :data.sync="sectionForm"
                  :errors="apiValidationErrors"
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <div class="container-fluid mt--6">
        <card v-if="isLoading">
          <span>En cours de récupération...</span>
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </card>
        <card v-else>
          <create-question slot="header" @questionCreated="get" :isArchived="sectionForm.archived" :sectionHasSubmits="sectionForm.has_submits" :sectionHasForms="sectionForm.is_attached_to_form" />
          <form-question-collapse @refreshQuestionsCollapse="get" :sectionHasSubmits="sectionForm.has_submits" :initialQuestions="this.sectionForm.questions" />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import formMixin from "@/mixins/form-mixin";
import EditSectionPage from './EditSectionPage.vue';
import CreateQuestion from '@/views/UserForms/Forms/Questions/CreateQuestion.vue';
import FormQuestionCollapse from '@/components/Collapse/FormQuestionCollapse.vue';
export default {
  name: "ShowSectionPage",
  components: {
    RouteBreadCrumb,
    EditSectionPage,
    CreateQuestion,
    FormQuestionCollapse,
  },

  mixins: [formMixin],

  data() {
    return {
      sectionState: "showing",
      sectionForm: {
        name: "",
        of_type: "",
        questions: [],
      },
      isLoading: false,
    };
  },
  created() {
    this.get();
  },
  methods: {
    id() {
      return parseInt(this.$route.params.id);
    },

    async get() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("form_sections/get", {id: this.id()});
        this.sectionForm = this.$store.getters["form_sections/section"];
        this.isLoading = false
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false
      }
    },

    async handleSubmit() {
      try {
        await this.$store.dispatch("form_sections/update", {
          id: this.id(),
          item: this.sectionForm,
        });
        this.sectionForm = await this.$store.getters["form_sections/section"];
        this.sectionState = 'showing';

        this.$notify({
          type: "success",
          message: "Les détails de la section ont été mis à jour avec succès!",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/brand/logo1.png);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
