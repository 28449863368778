<template>
  <modal size="lg" :show.sync="openModal" body-classes="p-0">
    <h6 slot="header" class="modal-title">{{ isNewQuestion ? "Ajouter une Question" : "Modifier la Question" }}</h6>
    <div v-if="!this.sectionId()">
      <img src="/img/loading.gif" style="height: 100px; width: 100px" />
    </div>
    <form v-else role="form" @submit.prevent="handleSubmit">
      <card
        header-classes="pb-5"
        body-classes="px-lg-5 pt-lg-5 pb-lg-4"
        class="border-0 mb-0"
      >
        <template>
          <div class="row d-flex border-primary border-top text-primary pt-2 mb-3">
            <span>1- Selection du Champs</span> 
          </div>
          <div class="row">
            <div class="col-lg-8">
              <base-input
                v-model="formModal.name"
                value="Question"
                placeholder="Entrer votre question"
              />
              <validation-error :errors="apiValidationErrors.name" />
            </div>
            <div class="col-lg-4">
              <base-input>
                <el-select
                  @change="setResponseField"
                  v-model="formModal.answer_type"
                  prepend-icon="fas fa-user"
                  placeholder="Type de Réponse"
                  :disabled="!isNewQuestion"
                >
                  <el-option
                    v-for="t in answerType"
                    :key="t.id"
                    :value="t.id"
                    :label="t.name"
                  />
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.answer_type" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8">
              <base-input
                v-show="formModal.answer_type == 1"
                placeholder=" Réponse courte..."
                input-classes="form-control-flush border-bottom col-lg-6"
              />
              <base-input
                v-show="formModal.answer_type == 2"
                placeholder=" Réponse longue..."
                input-classes="form-control-flush border-bottom"
              />
              <div v-show="hasOption">
                <div
                  class="d-flex flex-column flex-lg-row"
                  v-for="(choice, index) in option.choices"
                  :key="index"
                >
                  <base-input
                    ref="options"
                    v-model="choice.name"
                    input-group-classes="input-group-merge"
                  >
                    <template slot="prepend">
                      <i
                        v-show="formModal.answer_type == 3"
                        class="fas fa-circle"
                      ></i>
                      <i
                        v-show="formModal.answer_type == 4"
                        class="fas fa-square"
                      ></i>
                      <span v-show="formModal.answer_type == 5"
                        >{{ index + 1 }}.</span
                      >
                    </template>
                    <template slot="append">
                      <button
                        type="button"
                        class="close"
                        v-if="isOptionsMoreThanOne"
                        @click="deleteOption(index)"
                        aria-label="Supprimer"
                      >
                        <span>×</span>
                      </button>
                    </template>
                  </base-input>
                  <!-- <hr class="d-lg-none" /> -->
                </div>
                <base-input
                  @focus.enter="addNewOption"
                  placeholder=" Nouvelle option..."
                  input-classes="form-control-flush border-bottom"
                />
              </div>
              <base-input
                class="col-lg-6"
                v-show="formModal.answer_type == 7"
                type="date"
                id="example-date-input"
                input-classes="form-control-flush border-bottom"
              />
              <base-input
                class="col-lg-6"
                v-show="formModal.answer_type == 8"
                type="datetime-local"
                id="example-date-input"
                input-classes="form-control-flush border-bottom"
              />
            </div>
            <div class="col-lg-4">
              <div class="d-flex justify-content-lg-end">
                <div class="d-flex align-items-center">
                  <span>Obligatoire</span>
                  <base-switch
                    class="ml-3"
                    on-text="Oui"
                    off-text="Non"
                    v-model="formModal.answer_required"
                    type="success"
                  ></base-switch>
                </div>
              </div>
            </div>
          </div>

          <div class="row d-flex border-primary border-top text-primary pt-2 my-3">
            <span>2- Validation</span> 
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                  v-model="formModal.pattern"
                  prepend-icon="fas fa-pen"
                  placeholder="Validation du champs..."
                />
              <validation-error :errors="apiValidationErrors.pattern" />
            </div>
            <div class="col-lg-6">
              <base-input
                v-model="formModal.error_message"
                prepend-icon="fas fa-exclamation-triangle"
                placeholder="Message d'erreur..."
              />
              <validation-error :errors="apiValidationErrors.error_message" />
            </div>
          </div>

          <div class="row d-flex border-primary border-top text-primary pt-2 my-3">
            <span>3- Info bulle</span> 
          </div>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                  v-model="formModal.tooltip"
                  prepend-icon="fas fa-info"
                  placeholder="Description question..."
                />
              <validation-error :errors="apiValidationErrors.tooltip" />
            </div>
          </div>
        </template>
      </card>
      <div class="d-flex justify-content-between my-2 px-lg-5">
        <base-button type="primary" :loading="isLoading" native-type="submit"
          >{{ isNewQuestion ? "Enregistrer" : "Modifier"}}</base-button
        >
        <base-button type="danger" @click="closeModal">Annuler</base-button>
      </div>
    </form>
  </modal>
</template>

<script>
import { Modal } from "@/components";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

import { Select, Option } from "element-ui";
export default {
  name: "QuestionModal",
  emits: ["questionHandled"],
  props: {
    openModal: {
      type: Boolean,
      required: true
    },
    questionData: {
      type: Object,
      required: true
    },
    optionData: {
      type: Object,
      required: true
    },
  },
  components: {
    Modal,
    BaseButton,
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      isLoading: false,
      answerType: [
        { id: 1, name: "Réponse Courte", element: "input", type: "text" },
        { id: 2, name: "Paragraphe", element: "textarea", type: null },
        { id: 3, name: "Choix Multiple", element: "input", type: "radio" },
        { id: 4, name: "Case à Cocher", element: "input", type: "checkbox" },
        { id: 5, name: "Liste Déroulante", element: "select", type: null },
        { id: 6, name: "Importer un fichier", element: "input", type: "file" },
        { id: 7, name: "Date", element: "input", type: "date" },
        { id: 8, name: "Heure", element: "input", type: "date" },
        // { id: 9, name: "Echelle Linéaire", element: "input", type: "range" },
        // {
        //   id: 10,
        //   name: "Grille à Choix Multiple",
        //   element: "input",
        //   type: "text",
        // },
        // {
        //   id: 11,
        //   name: "Grille à cases à cocher",
        //   element: "input",
        //   type: "text",
        // },
      ],
      formModal: JSON.parse(JSON.stringify(this.questionData)),
      option: JSON.parse(JSON.stringify(this.optionData)),
    };
  },
  mixins: [formMixin],
  computed: {
    isNewQuestion() {
      return this.questionData.id != undefined ? false : true;
    },
    isOptionsMoreThanOne() {
      return this.option.choices.length > 1;
    },
    hasOption() {
      let type = this.formModal.answer_type;
      return type == 3 || type == 4 || type == 5;
    },
  },

  methods: {
    sectionId() {
      return parseInt(this.$route.params.id);
    },
    closeModal() {
      this.isLoading = false;
      this.formModal = JSON.parse(JSON.stringify(this.questionData));
      this.option = JSON.parse(JSON.stringify(this.optionData));
      this.resetApiValidation();
      this.$emit('update:openModal', false)
    },

    async handleSubmit() {
      this.isLoading = true;
      try {
        if (this.hasOption)
          Object.assign(this.formModal, {
            section: this.sectionId(),
            option_group: this.option,
          });
  
        if(this.isNewQuestion) {
          this.formModal.answer_type = this.answerType.find(
            (input) => input.id === this.formModal.answer_type
          );
        }
  
        let payload = {
          ...(!this.isNewQuestion ? { id: this.formModal.id } : {}),
          sectionId: this.sectionId(),
          params: this.formModal,
        };

        // start request
        let handleQuestionService = this.isNewQuestion ? "add" : "update";
        await this.$store.dispatch(`form_questions/${handleQuestionService}`, payload);
        // let question = this.$store.getters["form_questions/question"];
        this.$emit('update:openModal', false)

        this.$notify({
          type: "success",
          message: `Question ${this.isNewQuestion ? "ajoutée" : "modifée"} avec succès.`,
        });

        this.$nextTick(() => {
          this.formModal = JSON.parse(JSON.stringify(this.questionData));
          this.option = JSON.parse(JSON.stringify(this.optionData))
          this.resetApiValidation();
          this.$emit("questionHandled");
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false;
      }
      this.isLoading = false;
    },

    setResponseField() {
      switch (this.formModal.answer_type) {
        case 3:
          const option_group_1 = {
            name: "Choix Multiple",
            slug: "choix_multiple",
            choices: [
              { name: `Option ${this.optionsCount()}` },
            ],
          };
          this.selectedSections = [];
          this.option = { ...option_group_1 };
          break;
        case 4:
          const option_group_2 = {
            name: "Case à Cocher",
            slug: "case-a-cocher",
            choices: [
              { name: `Option ${this.optionsCount()}` },
            ],
          };
          this.selectedSections = [];
          this.option = { ...option_group_2 };
          break;
        case 5:
          const option_group_3 = {
            name: "Liste Déroulante",
            slug: "liste-deroulante",
            choices: [
              { name: `Option ${this.optionsCount()}` },
            ],
          };
          this.selectedSections = [];
          this.option = { ...option_group_3 };
          break;

        default:
          break;
      }
    },

    addNewOption() {
      let curentPosition = this.optionsCount();
      this.option.choices.push({
        name: `Option ${curentPosition + 1}`
      });

      this.$nextTick(() =>
        this.$refs.options[curentPosition].$refs.input.focus()
      );
    },

    deleteOption(index) {
      // remove the current option choice itself
      this.option.choices.splice(index, 1);

      // update option choices list
      this.$nextTick(() =>
        this.$refs.options[this.optionsCount() - 1].$refs.input.focus()
      );
    },

    optionsCount() {
      return this.option.choices.length;
    },
  },
};
</script>
<style>
#addNewOption:hover {
  border-bottom: 1px;
  border-color: darkolivegreen;
}
</style>
