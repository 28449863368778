const managementResources = {
  "contenu": {
    name: "Contenu",
    slug: "contenus",
    paths: {
      list: { title: "Liste Contenus", ref: "list_contents" },
      add: { title: "Ajouter Contenu", ref: "add_content" },
      edit: { title: "Modifier Contenu", ref: "edit_content" },
    },
    upload_files: true
  },
  "publication": {
    name: "Publication",
    slug: "publications",
    paths: {
      list: { title: "Liste Publications", ref: "list_publications" },
      add: { title: "Ajouter Publication", ref: "add_publication" },
      edit: { title: "Modifier Publication", ref: "edit_publication" },
    },
    upload_files: true
  },
  "documentation": {
    name: "Documentation",
    slug: "documentations",
    paths: {
      list: { title: "Liste Documentations", ref: "list_documentations" },
      add: { title: "Ajouter Documentation", ref: "add_documentation" },
      edit: { title: "Modifier Documentation", ref: "edit_documentation" },
    },
    upload_files: true
  },
  "menu": {
    name: "Menu",
    slug: "menus",
    paths: {
      list: { title: "Liste Menus", ref: "list_menus" },
      add: { title: "Ajouter Menu", ref: "add_menu" },
      edit: { title: "Modifier Menu", ref: "edit_menu" },
    },
    upload_files: false
  },
};

export default managementResources;