import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapMutations } = createNamespacedHelpers('form_types')

export default {
    data() {
        return {
            docModal: {
                isOpen: false,
                isLoading: false,
            },
            docTypeForm: { name: '' },
        }
    },
    computed: {
        ...mapState({
            docsList: 'list',
        }),
        ...mapGetters({
            allDocs: 'all',
            getDocTypeByName: 'getTypeByName',
            docType: 'type'
        }),
    },
    created() {
        this.getDocTypes()
    },
    methods: {
        async getDocTypes() {
            try {
                await this.$store.dispatch("form_types/list", { resource: 'article' });

                let docs = this.allDocs(this.docsList)
                if (docs && docs.length) {
                    this.setDocType({});
                    this.item.resource_type = docs[0].value;
                }
            } catch (error) {
                if(error.response !== undefined) {
                    this.$notify({
                        type: "danger",
                        message: error.response?.data.message,
                    });
                } else {
                    this.$notify({
                        type: "danger",
                        message: `Une erreur est survenue lors de la récupération des Types.`,
                    });
                }
            }
        },

        queryDocType(input, callback) {
            let types = this.allDocs
            let results = input ? types.filter(this.createDocFilter(input)) : types

            //call callback function to return suggestions
            callback(results)
        },
        async handleEditDocType() {
            this.isLoading = true;
            let payload = {
                id: this.docTypeForm.id,
                name: this.docTypeForm.name,
            };
            try {
                await this.$store.dispatch("form_types/update", payload);
                await this.getDocTypes()

                this.docModal.isOpen = false;
                this.$notify({
                    type: "success",
                    message: "Type modifié avec succès.",
                });
            } catch (error) {
                this.docTypeForm.name = ''
                this.$notify({
                    type: "danger",
                    message: "Désolé, une erreur est survenue!",
                });
                this.setApiValidation(error.response.data.errors);
            }
            this.isLoading = false;
        },

        handleDocSelect(t) {
            this.setDocType(t)
            this.item.resource_type = t.value
        },

        async deleteDocType() {
            const confirmation = await swal.fire({
                title: `Supprimer le type`,
                html: `<p>Confirmez-vous la suppression du type <b>${this.item.resource_type}</b>?</p>`,
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: `Oui, supprimer`,
                cancelButtonText: "Non, annuler",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-warning",
                }
            });

            try {
                if (confirmation.value === true) {
                    let type = this.getDocTypeByName(this.item.resource_type)
                    await this.$store.dispatch("form_types/destroy", { id: type.id });
                    await this.getDocTypes()
                    this.$notify({
                        type: "success",
                        message: "Le type a été supprimé avec succès.",
                    });
                }
            } catch (error) {
                this.$notify({
                    type: "danger",
                    message: "Désolé, une erreur est survenue lors de la suppression du type!",
                });
            }
        },

        ...mapMutations({
            setDocType: 'SET_RESOURCE'
        }),

        openEditDocModal() {
            if (Object.keys(this.docType).length) {
                this.docTypeForm = {
                    id: this.docType.id,
                    name: this.docType.value
                }
            } else if (this.item.resource_type.length && this.getDocTypeByName(this.item.resource_type)) {
                let type = this.getDocTypeByName(this.item.resource_type)
                this.docTypeForm = {
                    id: type.id,
                    name: type.name
                }
            } else {
                this.docTypeForm.name = ''
            }
            this.docModal.isOpen = true
        },

        createDocFilter(input) {
            return (type) => {
                return (type.value.toLowerCase().indexOf(input.toLowerCase()) === 0)
            }
        },
    }
}