import service from '@/store/services/dpo/dpo-formations-service';

const state = {
    list: [],
    formation: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, item) => {
        state.formation = item;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
};

const actions = {
    allFormations({ commit, dispatch }, payload) {
        return service.list(payload)
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            });
    },

    getFormation({ commit }, params) {
        return service.get(params)
            .then((formation) => { commit('SET_RESOURCE', formation); });
    },

    addFormation({ commit, dispatch }, params) {
        return service.add(params)
            .then((formation) => { commit('SET_RESOURCE', formation); });
    },

    updateFormation({ commit, dispatch }, params) {
        return service.update(params)
            .then((formation) => { commit('SET_RESOURCE', formation); });
    },

    destroyFormation({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    list: state => state.list,
    formation: state => state.formation,
    listTotal: state => state.meta.total,
};

const formations = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default formations;