<template>
  <main-form :formType="action" :name="resource.name" :page="pageTitle" :goBack="goBack">
    <template #managementForm>
      <form ref="content_form" @submit.prevent="handleSubmit">
        <!-- Media files -->
        <upload-media-form v-if="resource.upload_files" :files.sync="localFiles" :errors="getApiErrors" />
        <!-- form content start -->
        <slot />
        <div class="my-4">
          <base-button type="button" class="btn btn-sm btn-primary" native-type="submit" :loading="isLoading">
            {{ action }} {{ resource.name }}
          </base-button>
        </div>
      </form>
    </template>
  </main-form>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";
import UploadMediaForm from './UploadMediaForm.vue';
import MainForm from './MainForm.vue';

export default {
  name: "ContentForm",
  props: {
    action: {
      type: String,
      required: true
    },
    resource: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    localFiles: {
      type: Array,
      default: () => []
    },
    getApiErrors: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    BaseButton,
    UploadMediaForm,
    MainForm,
  },
  computed: {
    pageTitle() {
      let title = ''
      if(this.action == 'Ajouter') {
        title = this.resource.paths.add.title
      } else if (this.action == 'Modifier') {
        title = this.resource.paths.edit.title
      } else {
        title = this.resource.name
      }
      return title
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.resource.paths.list.ref });
    },
    handleSubmit() {
      this.$emit('handleContent')
    },
  },
};
</script>