import service from "@/store/services/userForms/form-sections-service";

const state = {
  list: [],
  availableSections: [],
  section: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_AVAILABLE_SECTIONS: (state, sections) => {
    state.availableSections = sections;
  },
  SET_RESOURCE: (state, section) => {
    state.section = section;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },
  getAvailableSections({ commit, dispatch }, payload) {
    return service.allByType(payload).then((list) => {
      commit("SET_AVAILABLE_SECTIONS", list);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((section) => {
      commit("SET_RESOURCE", section);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((section) => {
      commit("SET_RESOURCE", section);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((section) => {
      commit("SET_RESOURCE", section);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: (state) => {
    state.list.sort(
      (prevSec, nextSec) =>
        new Date(prevSec.created_at) - new Date(nextSec.created_at)
    );
    return state.list;
  },
  listCount: (state) => {
    return state.meta.total;
  },
  availableSectionsList:
    (state) =>
    (existingSections = []) => {
      if (existingSections.length == 0) {
        return state.availableSections;
      } else {
        let existingIds = existingSections.map((e_s) => e_s.id);
        return state.availableSections.filter((section) => {
          return !existingIds.includes(section.id);
        });
      }
    },
  section: (state) => state.section,
  dropdown: (state) => {
    return state.list.map((section) => ({
      id: section.id,
      name: section.name,
    }));
  },
};

const form_sections = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default form_sections;
