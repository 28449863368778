<template>
  <form class="mt-5" @submit.prevent="$emit('updateSection')">
    <div>
      <base-input
        label="Nom"
        labelClasses="text-secondary"
        v-model="data.name"
      />
      <validation-error :errors="errors.name" />
    </div>
    <div class="mb-4">
      <el-select
        v-model="data.of_type"
        @change="(val) => (data.of_type = val)"
        filterable
        placeholder="Sélectionner le type"
      >
        <el-option
          v-for="option in selectOptions"
          :key="option.label"
          :label="option.label"
          :value="option.value"
        >
        </el-option>
      </el-select>
      <validation-error :errors="errors.of_type" />
    </div>

    <div class="mt-5">
      <base-button
        v-show="sectionState === 'editing'"
        @click="$emit('updateState', 'showing')"
        type="button"
        class="btn btn-warning"
        >Annuler</base-button
      >
      <base-button type="button" class="btn btn-primary" native-type="submit">
        Enregistrer
      </base-button>
    </div>
  </form>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
export default {
  name: "EditSectionPage",
  props: {
    state: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },

    errors: {
      type: Object,
      default(rawProps) {
        return [];
      },
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    BaseButton,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      selectOptions: [
        {
          name: "Veuillez faire un choix",
          label: "Sélectionner un type",
          value: "",
        },
        {
          name: "Section",
          label: "Sections",
          value: "standard",
        },
        {
          name: "Annexe",
          label: "Annexes",
          value: "annexe",
        },
        {
          name: "Pièce-Jointe",
          label: "Pièces Jointes",
          value: "pj",
        },
      ],
    };
  },

  computed: {
    sectionState() {
      return this.state;
    },
    sectionErrors() {
      return this.errors;
    },
  },
};
</script>
