<template>
  <content-form
    action="Modifier"
    :resource="resource"
    :isLoading="isLoading"
    :localFiles.sync="files"
    @handleContent="editContent"
    :getApiErrors="apiValidationErrors"
  >
    <!-- fields start -->
    <!-- Level choice start -->
    <div class="row">
      <div class="col-lg-4">
        <div class="d-flex flex-row">
          <base-input label="Niveau" class="w-75">
            <el-select
              name="level"
              v-model="item.level"
              prepend-icon="fas fa-user"
              placeholder="Selectionner le niveau du menu..."
            >
              <el-option
                v-for="(m, index) in menuLevels"
                :key="index"
                :value="m.value"
                :label="m.name"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <validation-error :errors="apiValidationErrors.model" />
      </div>
    </div>
    <!-- Level choice end -->

    <!-- Menu parent & Model list start -->
    <div class="row">
      <div class="col-lg-4">
        <base-input label="Menu Parent">
          <el-select
            name="parent"
            v-model="item.parent_id"
            prepend-icon="fas fa-user"
            placeholder="Selectionner un parent..."
            @change="setOrder"
            :disabled="!hasParent"
          >
            <el-option
              v-for="(m, index) in parentsLevelList"
              :key="index"
              :value="m.id"
              :label="m.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.parent" />
      </div>
      <div class="col-lg-4">
        <base-input label="Ordonancement" v-model="item.order" placeholder="Position du menu" />
        <validation-error :errors="apiValidationErrors.ordonancement" />
      </div>
      <div class="col-lg-4">
        <div class="d-flex flex-row">
          <base-input label="Model">
            <el-select
              name="model"
              v-model="item.model"
              prepend-icon="fas fa-user"
              placeholder="Selectionner un model..."
              :disabled="hasChildren"
            >
              <el-option
                v-for="(m, index) in pageModels"
                :key="index"
                :value="m.slug"
                :label="m.name"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <validation-error :errors="apiValidationErrors.model" />
      </div>
    </div>
    <!-- Menu parent & Model list end -->

    <!-- Resource & Article Type list start -->
    <div class="row">
      <div class="col-lg-6">
        <base-input label="Ressource Gérée">
          <el-select
            name="parent"
            v-model="item.concerned_resource"
            prepend-icon="fas fa-user"
            placeholder="Selectionner la ressource gérée par ce menu..."
          >
            <el-option
              v-for="(m, index) in globalResources"
              :key="index"
              :value="m.slug"
              :label="m.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.concerned_resource" />
      </div>
      <div v-show="hasArticleType" class="col-lg-6">
        <div class="d-flex flex-row">
          <base-input label="Type d'Article" class="w-75">
            <el-select
              name="model"
              v-model="item.concerned_article_type"
              prepend-icon="fas fa-user"
              placeholder="Selectionner l'Article..."
            >
              <el-option
                v-for="(m, index) in articleTypes"
                :key="index"
                :value="m.id"
                :label="m.name"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <validation-error
          :errors="apiValidationErrors.concerned_article_type"
        />
      </div>
    </div>
    <!-- Resource & Article Type list end -->

    <base-input label="Libellé" v-model="item.name" />
    <validation-error :errors="apiValidationErrors.name" />
    <!-- fields end -->
  </content-form>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations } = createNamespacedHelpers("menus");

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import { Autocomplete, Select, Option } from "element-ui";
import ValidationError from "@/components/ValidationError.vue";
import ContentForm from "../../Components/Forms/ContentForm.vue";
import managementResources from "@/util/resources";

const levels = [
  { name: "Niveau 1", value: 1 },
  { name: "Niveau 2", value: 2 },
];

export default {
  name: "EditMenu",
  components: {
    BaseInput,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    ContentForm,
  },

  mixins: [formMixin],

  data() {
    return {
      isLoading: false,
      files: [],
      item: {
        name: "",
        model: "",
        level: null,
        order: null,
        concerned_resource: "",
        concerned_resource: null,
        children: [],
        parent_id: null,
        user: null,
      },
      menuLevels: levels,
      parents: [],
      globalResources: [],
      articleTypes: [],
      hasArticleType: false,
      parentsLevelList: [],
      pageModels: [],
      resource: managementResources.menu,
    };
  },

  computed: {
    // parent: {
    //   get() {
    //     return this.item.parent_id
    //   },
    //   set(val) {
    //     this.item.parent_id = val
    //   }
    // },
    level() {
      return this.item.level;
    },
    hasChildren() {
      let itemLength = this.item.children.length;
      return itemLength > 0;
    },
    hasParent() {
      return this.item.parent_id !== null;
    },
  },

  watch: {
    level: {
      handler: "getParentLevelList",
      immediate: true,
    },
    item: {
      handler: "checkIfArticleType",
      deep: true,
    },
  },

  created() {
    this.getProperties();
  },

  methods: {
    setOrder(parentId) {
      const parent = this.parentsLevelList.find(m => m.id === parentId)
      if(parent != undefined) this.item.order = parent.children.length + 1
    },
    id() {
      return this.$route.params.id;
    },

    async get() {
      try {
        await this.$store.dispatch("menus/get", this.id());
        this.item = this.$store.getters["menus/menu"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    goBack() {
      if (this.resource.paths)
        this.$router.push({ name: this.resource.paths.list.ref });
      else this.$router.push("#");
    },

    async editContent() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
        // if (this.user.roles[0].name === "member") {
        //   this.$notify({
        //     type: "danger",
        //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
        //   });
        //   return;
        // }

        await this.$store.dispatch("menus/update", {
          id: this.id(),
          item: this.item,
        });
        this.item = await this.$store.getters["menus/article"];

        this.$notify({
          type: "success",
          message: "Menu modifié avec succès",
        });
        this.goBack();
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },

    async getProperties() {
      try {
        this.$store.dispatch("menus/fetchAllProperties").then((properties) => {
          this.parents = [...properties.menus];
          this.pageModels = [...properties.models];
          this.globalResources = [...properties.resources];
          this.articleTypes = [...properties.types];

          this.get() // get menu after all properties fetched
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Une erreur est survenue lors de la récupération des Menus.`,
        });
      }
    },

    getParentLevelList() {
      if (this.item.level)
        this.parentsLevelList = this.parents.filter(
          (menu) => (menu.level === this.item.level) && (menu.id !== this.item.id)
        );
    },
    checkIfArticleType() {
      let currResource = this.globalResources.find(resource => resource.slug === this.item.concerned_resource)
        if(currResource && currResource.management ===  'content') {
          this.hasArticleType = true
        }
        else {
          this.hasArticleType = false
        }
    }
  },
};
</script>
