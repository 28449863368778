<template>
  <div>
    <el-tooltip content="Modifier la Question" placement="left">
      <base-button
        outline
        size="sm"
        type="secondary"
        @click="modals.editModal = true"
        class="btn-icon-only rounded-circle"
      >
        <i class="fas fa-pen"></i>
      </base-button>
    </el-tooltip>
    <el-tooltip content="Supprimer la Question" placement="right">
      <base-button
        outline
        size="sm"
        type="danger"
        @click="deleteQuestion"
        class="btn-icon-only rounded-circle border-0"
      >
        <i class="fas fa-trash"></i>
      </base-button>
    </el-tooltip>

    <!-- Edit Modal start -->
    <QuestionModal
      :openModal.sync="modals.editModal"
      :questionData="question"
      :optionData="{
        name: question.option_group.name,
        slug: question.option_group.slug,
      }"
      @questionHandled="$emit('questionUpdated')"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import QuestionModal from "@/components/Modals/QuestionModal.vue";

export default {
  name: "EditQuestion",
  emits: ["questionUpdated"],
  props: {
    shs: Boolean,
    question: {
      type: Object,
      required: true,
    },
  },
  components: { BaseButton, QuestionModal },
  data() {
    return {
      modals: {
        editModal: false,
        deleteModal: false,
      },
    };
  },
  computed: {
    sectionId() {
      return this.$route.params.id
    },
  },
  methods: {
    async deleteQuestion() {
      let alertObject = {
        name: "Supprimer",
        icon: "warning",
        content: "de supprimer",
      };
      if (this.shs) {
        alertObject = { name: "Archiver", icon: "info", content: "d'archiver" };
      }
      const confirmation = await swal.fire({
        icon: `${alertObject.icon}`,
        title: `${alertObject.name} cette question?`,
        html: `<p class="font-weight-lighter font-italic"><sup class="font-weight-bold text-warning">*</sup>${alertObject.name} cette question inclus le faite ${alertObject.content} ses options et annuler ses dépendances.</p>`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `${alertObject.name}`,
        cancelButtonText: "Garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("form_questions/destroy", {
            id: this.question.id,
            sectionId: this.sectionId,
          });
          this.$emit("questionUpdated");
          if (this.shs) {
            this.$notify({
              type: "info",
              message: "La question a été archivée avec succès.",
            });
          } else {
            this.$notify({
              type: "success",
              message: "La question a été supprimée avec succès.",
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur est survenue lors de la suppression de la question!",
        });
      }
    },
  },
};
</script>
