import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(payload) {
  const options = {
    params: payload.params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/admin/dpo/trainings/${payload.formation}/supports`, options)
    .then((response) => {
      return {
        list: response.data.data,
        meta: response.data.meta,
      };
    });
}

function get(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios
    .get(
      `${url}/admin/dpo/trainings/${payload.formation}/supports/${payload.id}`,
      options
    )
    .then((response) => {
      return response.data.data;
    });
}

function add(payload) {
  let params = payload.params;

  const bodyFormData = new FormData();
  bodyFormData.append("title", params.title);
  bodyFormData.append("description", params.description);
  bodyFormData.append("media_type", params.media_type);
  bodyFormData.append("media_url", params.media_url);
  bodyFormData.append("document", params.document);
  bodyFormData.append("author", params.author);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/admin/dpo/trainings/${payload.formation}/supports`,
      bodyFormData,
      options
    )
    .then((response) => {
      return response.data.data;
    });
}

function update(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .put(
      `${url}/admin/dpo/trainings/${payload.formation}/supports/${payload.id}`,
      payload.item,
      options
    )
    .then((response) => {
      return response.data.data;
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/items/${id}`, options);
}

function upload(item, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/items/${item.id}/image`, bodyFormData)
    .then((response) => {
      return response.data.url;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
};
