import qs from "qs";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    let options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    }

    return axios.get(
        `${url}/admin/article-sections`,
        options
    ).then((response) => ({ list: response.data.data }));
}

function get(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/admin/article-sections/${id}`, options).then(response => {
        return response.data.data;
    });
}

function add(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.post(`${url}/admin/article-sections`, payload, options).then(response => {
        return response.data;
    });
}

function update(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/admin/article-sections/${payload.id}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function destroy(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/admin/article-sections/${payload.id}`, options);
}

export default {
    list,
    get,
    add,
    update,
    destroy
};