import service from "@/store/services/users/permissions-service";

export const state = {
    list: {},
    permission: {},
    meta: {}
};

export const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, permission) => {
        state.permission = permission;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    }
};

export const actions = {
    list({ commit, dispatch }, params = {}) {
        return service.list(params).then(({ list, meta }) => {
            commit("SET_LIST", list);
            commit("SET_META", meta);
        });
    },

    get({ commit, dispatch }, params) {
        return service.get(params).then(permission => {
            commit("SET_RESOURCE", permission);
        });
    },

    add({ commit, dispatch }, params) {
        return service.add(params).then(permission => {
            commit("SET_RESOURCE", permission);
        });
    },

    update({ commit, dispatch }, params) {
        return service.update(params).then(permission => {
            commit("SET_RESOURCE", permission);
        });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    }
};

const getters = {
    list: state => state.list,
    listTotal: state => state.meta.total,
    permission: state => {
        let roles = []
        let permission = state.permission

        permission.roles.forEach(role => roles.push(role.id));
        return {
            id: permission.id,
            name: permission.name,
            roles: roles,
        }
    },
    dropdown: state => {
        return state.list.map(permission => ({
            id: permission.id,
            name: permission.name,
        }));
    }
};

const permissions = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default permissions;