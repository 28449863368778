import service from "@/store/services/newsletters-service";

export const state = {
    list: {},
    newsletter: {},
    meta: {}
};

export const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, newsletter) => {
        state.newsletter = newsletter;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    }
};

export const actions = {
    list({ commit, dispatch }, params = {}) {
        return service.list(params).then(({ list, meta }) => {
            commit("SET_LIST", list);
            commit("SET_META", meta);
        });
    },

    get({ commit, dispatch }, params) {
        return service.get(params).then(newsletter => {
            commit("SET_RESOURCE", newsletter);
        });
    },

    add({ commit, dispatch }, params) {
        return service.add(params).then(newsletter => {
            commit("SET_RESOURCE", newsletter);
        });
    },

    update({ commit, dispatch }, params) {
        return service.update(params).then(newsletter => {
            commit("SET_RESOURCE", newsletter);
        });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    }
};

const getters = {
    list: state => state.list,
    listTotal: state => state.meta.total,
    newsletter: state => state.newsletter,
    dropdown: state => {
        return state.list.map(newsletter => ({
            id: newsletter.id,
            name: newsletter.name
        }));
    }
};

const newsletters = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default newsletters;