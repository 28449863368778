import service from "@/store/services/userForms/forms-service";

const state = {
  list: [],
  form: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, item) => {
    state.form = item;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  list({ commit, dispatch }, payload) {
    return service.list(payload).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit }, params) {
    return service.get(params).then((form) => {
      commit("SET_RESOURCE", form);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((form) => {
      commit("SET_RESOURCE", form);
    });
  },

  addSections({ commit, dispatch }, params) {
    return service.associateSections(params).then((form) => {
      commit("SET_RESOURCE", form);
    });
  },

  reorderSections({ commit, dispatch }, payload) {
    return service.reOrderSections(payload).then((form) => {
        commit("SET_RESOURCE", form);
    });
  },

  removeSections({ commit, dispatch }, params) {
    return service.dissociateSections(params).then((form) => {
      commit("SET_RESOURCE", form);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((form) => {
      commit("SET_RESOURCE", form);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.total,
  form: (state) => {
    if (state.form.sections) {
      state.form.sections.sort(
        (prevSec, nextSec) => prevSec.range - nextSec.range
      );
    }
    return state.form;
  },
  sections: (state) => state.form.sections,
  types: (state) => state.meta.types,
  getSection: (state) => (id) =>
    state.form.sections.find((section) => section.id === id),
  relatedSection: (state, getters) => (sectionId) => {
    let sections = [...state.form.sections];

    let querySection = sections
      .filter((section) => section.type === "dependent" || section.has_related)
      .find((section) => section.id === sectionId);

    return querySection == undefined ? false : true;
  },
  noRelatedSections: (state, getters) => (sectionId) => {
    let currentSection = getters.getSection(sectionId);
    let sections = [...state.form.sections];
    let sectionsFiltered = sections.filter(
      (section) => section.type === "standard" && !section.has_related
    );
    let index = sectionsFiltered.findIndex((el) => el.id === sectionId);

    if (index != -1) sectionsFiltered.splice(index, 1);

    return sectionsFiltered.filter(
      (section) =>
        new Date(section.created_at) > new Date(currentSection.created_at)
    );
  },
};

const forms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default forms;
