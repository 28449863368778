<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Paramétrage</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb pageTitle="Paramétrage RDV"></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">Liste des Services</h5>
            </div>
            <!-- Card search -->
            <div class="card-header py-0">
              <!-- Search form -->
              <form>
                <div class="form-group mb-0">
                  <base-input
                    class="input-group-lg input-group-flush mb-0"
                    prepend-icon="fas fa-search"
                    placeholder="Recherche"
                    type="search"
                    v-model="servicesSearch"
                  >
                  </base-input>
                </div>
              </form>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <!-- List group -->
              <ul class="list-group list-group-flush list my--3">
                <li
                  v-for="service in servicesList"
                  :key="service.id"
                  class="list-group-item px-0"
                >
                  <div class="row align-items-center">
                    <div class="col ml--2">
                      <h4 class="mb-0">
                        {{ service.name }}
                      </h4>
                      <small>{{ service.thematic }}</small>
                    </div>
                    <div class="col-auto">
                      <button @click="editService(service)" type="button" class="btn btn-sm btn-primary">
                        <i class="fas fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-8 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Disponibilités Services</h3>
              </div>
              <div class="col-4 text-right">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="handleSubmit" @reset.prevent="resetServiceForm">
                <div class="d-flex flex-row">
                  <base-input label="Thématique" style="min-width: 50%">
                    <el-autocomplete
                      class="d-block"
                      v-model="service.thematic"
                      :fetch-suggestions="queryThematic"
                      placeholder="Selectionner..."
                      @select="handleSelect"
                    >
                    </el-autocomplete>
                  </base-input>
                  <div
                    v-if="getThematicByName(service.thematic)"
                    class="px-2 pt-2 align-self-center"
                  >
                    <base-button
                      type="primary"
                      @click="openEditModal"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon display-5 font-weight-light"
                        ><i class="fas fa-pen"></i
                      ></span>
                    </base-button>
                    <base-button
                      type="danger"
                      @click="deleteThematic"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon display-5 font-weight-light"
                        ><i class="fas fa-trash"></i
                      ></span>
                    </base-button>
                  </div>
                </div>
                <validation-error :errors="apiValidationErrors.thematic" />
                <base-input label="Nom du Service" v-model="service.name" />
                <validation-error :errors="apiValidationErrors.name" />

                <!-- Morning -->
                <div class="row mb-3">
                  <!-- Morning start hour -->
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de début matinée"
                      type="time"
                      v-model="service.mor_from_time"
                      @input="
                        getMorningTimeSlots(
                          service.mor_from_time,
                          service.mor_to_time
                        )
                      "
                    />
                    <div v-if="checkMorningTimeSlots">
                      <span
                        v-if="
                          !compareTime(
                            service.mor_from_time,
                            service.mor_to_time
                          )
                        "
                      >
                        <validation-error
                          :errors="[
                            'La date début et de fin de journée sont incorrects.',
                          ]"
                        />
                      </span>
                      <div v-if="morningTimeSlots.length > 0">
                        <badge
                          v-for="(slot, index) in morningTimeSlots"
                          :key="index"
                          rounded
                          type="primary"
                          class="mr-1"
                        >
                          {{ slot.start.getHours() }}:{{
                            slot.start.getMinutes() < 10
                              ? "0" + slot.start.getMinutes()
                              : slot.start.getMinutes()
                          }}
                          <b>-</b> {{ slot.end.getHours() }}:{{
                            slot.end.getMinutes() < 10
                              ? "0" + slot.end.getMinutes()
                              : slot.end.getMinutes()
                          }} </badge
                        >&nbsp;
                      </div>
                    </div>
                    <validation-error
                      :errors="apiValidationErrors.mor_from_time"
                    />
                  </div>

                  <!-- Morning End hour -->
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de fin matinée"
                      type="time"
                      v-model="service.mor_to_time"
                      @input="
                        getMorningTimeSlots(
                          service.mor_from_time,
                          service.mor_to_time
                        )
                      "
                    />
                    <validation-error
                      :errors="apiValidationErrors.mor_to_time"
                    />
                  </div>
                </div>

                <!-- Afternoon -->
                <div class="row">
                  <!-- Afternoon start hour -->
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de début soirée"
                      type="time"
                      v-model="service.aft_from_time"
                      @input="
                        getAfternoonTimeSlots(
                          service.aft_from_time,
                          service.aft_to_time
                        )
                      "
                    />
                    <div v-if="checkAfternoonTimeSlots">
                      <span
                        v-if="
                          !compareTime(
                            service.aft_from_time,
                            service.aft_to_time
                          )
                        "
                      >
                        <validation-error
                          :errors="[
                            'La date début et de fin de soirée sont incorrects',
                          ]"
                        />
                      </span>
                      <div v-if="afternoonTimeSlots.length > 0">
                        <badge
                          v-for="(slot, index) in afternoonTimeSlots"
                          :key="index"
                          rounded
                          type="warning"
                          class="mr-1"
                        >
                          {{ slot.start.getHours() }}:{{
                            slot.start.getMinutes() < 10
                              ? "0" + slot.start.getMinutes()
                              : slot.start.getMinutes()
                          }}
                          <b>-</b> {{ slot.end.getHours() }}:{{
                            slot.end.getMinutes() < 10
                              ? "0" + slot.end.getMinutes()
                              : slot.end.getMinutes()
                          }} </badge
                        >&nbsp;
                      </div>
                    </div>
                    <validation-error
                      :errors="apiValidationErrors.aft_from_time"
                    />
                  </div>

                  <!-- Afternoon End hour -->
                  <div class="col-lg-6">
                    <base-input
                      label="Heure de fin soirée"
                      type="time"
                      v-model="service.aft_to_time"
                      @input="
                        getAfternoonTimeSlots(
                          service.aft_from_time,
                          service.aft_to_time
                        )
                      "
                    />
                    <validation-error
                      :errors="apiValidationErrors.aft_to_time"
                    />
                  </div>
                </div>

                <div class="my-4">
                  <base-button
                    type="button"
                    class="btn btn-sm btn-danger"
                    native-type="reset"
                  >
                    Reinitialiser
                  </base-button>

                  <base-button
                    type="button"
                    class="btn btn-sm btn-primary"
                    native-type="submit"
                    :disabled="isLoading"
                  >
                    {{ service.id == undefined ? 'Ajouter Service' : 'Modifier Service'}}
                  </base-button>
                </div>
              </form>
              <modal
                :show.sync="modals.editThematicModal"
                header-classes="bg-success text-white"
                body-classes="p-0"
              >
                <div
                  slot="header"
                  class="modal-title text-white d-inline-block"
                >
                  <i class="fas fa-info-circle display-4"></i>
                  <h2 class="text-white d-inline ml-3">
                    Modifier la thématique
                  </h2>
                </div>
                <form role="form" @submit.prevent="handleEditThematic">
                  <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
                  >
                    <template>
                      <div class="row">
                        <div class="col-lg-8">
                          <base-input
                            name="Thematique"
                            v-model="thematicForm.name"
                            placeholder="Entrer votre thématique"
                            required
                          />
                        </div>
                      </div>
                    </template>
                  </card>
                  <div class="modal-footer d-flex justify-content-between">
                    <base-button
                      type="primary"
                      :loading="isLoading"
                      native-type="submit"
                      >Enregistrer</base-button
                    >
                    <base-button
                      type="danger"
                      @click="modals.editThematicModal = false"
                      >Annuler</base-button
                    >
                  </div>
                </form>
              </modal>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations } = createNamespacedHelpers("thematics");

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Autocomplete } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
moment.locale("fr");

export default {
  layout: "DashboardLayout",

  components: {
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      servicesSearch: "",
      isLoading: false,
      service: {
        thematic: "",
        name: "",
        mor_from_time: null,
        mor_to_time: null,
        aft_from_time: null,
        aft_to_time: null,
      },
      modals: {
        editThematicModal: false,
      },
      thematicForm: { name: "" },
      morningTimeSlots: [],
      afternoonTimeSlots: [],
    };
  },
  watch: {
    servicesSearch: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["all", "getThematicByName", "thematic"]),
    servicesList() {
      return this.$store.getters["booking_services/all"];
    },
    checkMorningTimeSlots() {
      return (
        this.service.mor_from_time != null && this.service.mor_to_time != null
      );
    },
    checkAfternoonTimeSlots() {
      return (
        this.service.aft_from_time != null && this.service.aft_to_time != null
      );
    },
  },
  created() {
    this.getThematics();
    this.getServices();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getServices();
    }, 300),
    goBack() {
      this.$router.push({ name: "RDV" });
    },

    async getThematics() {
      try {
        await this.$store.dispatch("thematics/list", {
          params: {
            of_type: "rdv",
          },
        });

        if (this.all && this.all.length) {
          this.setThematic({});
          this.service.thematic = this.all[0].value;
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
        this.setApiValidation(error.response.data.data.errors);
      }
    },

    async getServices() {
      try {
        await this.$store.dispatch("booking_services/list", {
          params: {
            query: this.servicesSearch,
          },
        });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
      }
    },

    queryThematic(input, callback) {
      let thematics = this.all;
      let results = input
        ? thematics.filter(this.createFilter(input))
        : thematics;

      //call callback function to return suggestions
      callback(results);
    },

    async handleSubmit() {
      this.isLoading = true
      try {
        if(this.service.id == undefined) {
          await this.$store.dispatch("booking_services/add", this.service);
        }
        else {
          await this.$store.dispatch("booking_services/update", this.service);
        }

        this.getThematics();
        this.getServices();

        this.$notify({
          type: "success",
          message: `Service ${this.service.id == undefined ? 'ajouté' : 'modifié'} avec succès`,
        });
        this.resetServiceForm();
        this.isLoading = false
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false
      }
    },

    editService(service) {
      this.resetServiceForm()
      this.service = {
        id: service.id,
        thematic: service.thematic,
        name: service.name,
        mor_from_time: moment(this.concatDateWithHour(service.mor_from_time)).format('LT'),
        mor_to_time: moment(this.concatDateWithHour(service.mor_to_time)).format('LT'),
        aft_from_time: moment(this.concatDateWithHour(service.aft_from_time)).format('LT'),
        aft_to_time: moment(this.concatDateWithHour(service.aft_to_time)).format('LT'),
      };
    },

    resetServiceForm() {
      this.service = {
        thematic: "",
        name: "",
        mor_from_time: null,
        mor_to_time: null,
        aft_from_time: null,
        aft_to_time: null,
      };
      this.morningTimeSlots = [];
      this.afternoonTimeSlots = [];
    },
    handleSelect(t) {
      this.setThematic(t);
      this.service.thematic = t.value;
    },

    createFilter(input) {
      return (thematic) => {
        return thematic.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
      };
    },

    concatDateWithHour(hour) {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      let todayFormatted = year + "-" + month + "-" + day;

      return new Date(`${todayFormatted} ${hour}`);
    },

    compareTime(h1, h2) {
      let from = this.concatDateWithHour(h1);
      let to = this.concatDateWithHour(h2);

      return from < to;
    },

    async deleteThematic() {
      const confirmation = await swal.fire({
        title: `Supprimer cette thématique?`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Supprimer`,
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        },
      });

      try {
        if (confirmation.value === true) {
          let thematic = this.getThematicByName(this.service.thematic);
          await this.$store.dispatch("thematics/destroy", { id: thematic.id });
          await this.getThematics();
          this.$notify({
            type: "success",
            message: "La thématique a été supprimée avec succès.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur est survenue lors de la suppression de la thématique!",
        });
      }
    },

    ...mapMutations({
      setThematic: "SET_RESOURCE",
    }),

    openEditModal() {
      if (Object.keys(this.thematic).length) {
        this.thematicForm = {
          id: this.thematic.id,
          name: this.thematic.value,
        };
      } else if (
        this.service.thematic.length &&
        this.getThematicByName(this.service.thematic)
      ) {
        let thematic = this.getThematicByName(this.service.thematic);
        this.thematicForm = {
          id: thematic.id,
          name: thematic.name,
        };
      } else {
        this.thematicForm.name = "";
      }
      this.modals.editThematicModal = true;
    },

    async handleEditThematic() {
      this.isLoading = true;
      let payload = {
        id: this.thematicForm.id,
        name: this.thematicForm.name,
      };
      try {
        await this.$store.dispatch("thematics/update", payload);
        await this.getThematics();

        this.modals.editThematicModal = false;
        this.$notify({
          type: "success",
          message: "Thématique modifiée avec succès.",
        });
      } catch (error) {
        this.thematicForm.name = "";
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },

    // generate time slots between two hours
    setTimeSlots(t1, t2) {
      if (t1 == null || t2 == null) return [];

      let slots = [];
      let startHour = this.concatDateWithHour(t1);
      let currSlot = { start: this.concatDateWithHour(t1) };

      while (startHour < this.concatDateWithHour(t2)) {
        startHour.setMinutes(startHour.getMinutes() + 60); // Add 60 minutes (1hour)
        currSlot.end = new Date(startHour);

        slots.push({ ...currSlot });

        // init the next iteration
        currSlot.start = new Date(startHour);
      }
      return slots;
    },

    getMorningTimeSlots(t1, t2) {
      this.morningTimeSlots = this.setTimeSlots(t1, t2);
    },
    getAfternoonTimeSlots(t1, t2) {
      this.afternoonTimeSlots = this.setTimeSlots(t1, t2);
    },
  },
};
</script>
