import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(payload) {
  const options = {
    params: payload.params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/admin/formulaires`, options).then((response) => {
    return {
      list: response.data.data,
      meta: response.data.meta,
    };
  });
}

function get(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios
    .get(`${url}/admin/formulaires/${payload.id}`, options)
    .then((response) => {
      return response.data.data;
    });
}

function add(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/admin/formulaires`, payload, options)
    .then((response) => {
      return response.data.data;
    });
}

async function reOrderSections(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
        .post(`${url}/admin/reorder/formulaires/${payload.id}/sections/${payload.type}`, {sections: payload.sections}, options);
    return response.data.data;
}

async function associateSections(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
        .post(`${url}/admin/toggle/formulaires/${payload.id}/sections/${payload.type}`, {sections: payload.sections}, options);
    return response.data.data;
}

async function dissociateSections(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
        .put(`${url}/admin/toggle/formulaires/${payload.id}/sections/${payload.sectionId}`, {}, options);
    return response.data.data;
}

function update(payload) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .put(`${url}/admin/formulaires/${payload.id}`, payload.item, options)
    .then((response) => {
      return response.data.data;
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/items/${id}`, options);
}

function upload(item, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/items/${item.id}/image`, bodyFormData)
    .then((response) => {
      return response.data.url;
    });
}

export default {
  list,
  get,
  add,
  reOrderSections,
  associateSections,
  dissociateSections,
  update,
  destroy,
  upload,
};
