<template>
  <form @submit.prevent="$emit('updateHeader', headerData)">
    <!-- <base-input label="Type">
                    <el-select
                      name="type"
                      v-model="item.type"
                      prepend-icon="fas fa-user"
                    >
                      <el-option
                        v-for="single_type in types"
                        :key="single_type.id"
                        :value="single_type.slug"
                        :label="single_type.name"
                      > 
                      </el-option>
                    </el-select>
                  </base-input> -->
    <base-input
      label="Nom"
      labelClasses="text-secondary"
      v-model="headerData.name"
    />
    <validation-error :errors="errors.name" />

    <base-input label="Détail" labelClasses="text-secondary">
      <html-editor class="w-100" v-model="headerData.detail" name="editor" />
    </base-input>
    <validation-error :errors="errors.detail" />
    <div class="mb-5">
      <base-button
        v-show="headerState === 'editing'"
        @click="$emit('updateState','showing')"
        type="button"
        class="btn btn-warning"
        >Annuler</base-button
      >
      <base-button type="button" class="btn btn-primary" native-type="submit">
        Enregistrer
      </base-button>
    </div>
  </form>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
export default {
  props: {
    state: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default(rawProps) {
        return [];
      }
    },
  },
  components: {
    HtmlEditor,
    BaseInput,
    BaseButton,
    ValidationError,
  },

  data() {
    return {
      headerData: {
        type: "",
        name: "",
        detail: "",
      },
    }
  },

  computed: {
    headerState() {
      return this.state;
    },
    headerErrors() {
      return this.errors;
    },
  },

  created(){
    this.getHeaderFields();
  },

  methods: {
    getHeaderFields() {
      const { name, detail, type } = this.data;
      this.headerData.name = name;
      this.headerData.detail = detail;
      this.headerData.type = type.slug;
    }
  }
};
</script>

<style>
</style>