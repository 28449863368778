import qs from "qs";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
    const options = {
        params: params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        }
    };

    return axios.get(`${url}/admin/menus`, options).then(response => {
        return {
            list: response.data.data,
            meta: response.data.meta
        };
    });
}

function fetchAll() {
    let params = {properties: ['menus', 'models', 'resources', 'article_type']}

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        },
        params: params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        }
    };
    
    return axios.get(`${url}/admin/fetch-all-menu-properties`, options).then(response => response.data);
}

function get(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .get(`${url}/admin/menus/${id}`, options)
        .then(response => {
            return response.data.data;
        });
}

function add(payload) {
    // const payload = jsona.serialize({
    //     stuff: item,
    //     includeNames: ["categories"]
    // });

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };
    return axios
        .post(`${url}/admin/menus`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function update(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };
    return axios
        .put(`${url}/admin/menus/${payload.id}`, payload.item, options)
        .then(response => {
            return response.data.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/admin/menus/${id}`, options);
}

export default {
    list,
    fetchAll,
    get,
    add,
    update,
    destroy,
};