var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Tableau de Bord',
          icon: 'ni ni-shop text-primary',
          path: '/admin/dashboard',
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Gestions")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Menus',
            icon: 'ni ni-bullet-list-67 text-primary',
            path: '/admin/siteweb-menus/liste',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Contenus',
            icon: 'ni ni-ungroup text-primary',
            path: '/admin/gestion-contenus',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Publications',
            icon: 'ni ni-align-left-2 text-primary',
            path: '/admin/gestion-publications',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Documentations',
            icon: 'ni ni-books text-primary',
            path: '/admin/gestion-documentations',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'FAQs',
            icon: 'ni ni-curved-next text-primary',
            path: '/admin/apdp-faqs/liste',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Newsletters',
            icon: 'ni ni-world-2 text-primary',
            path: '/admin/newsletters',
          }}}),_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-folder-17 text-primary',
            name: 'Formulaires',
            path: '/admin/apdp-formulaires',
          }}}),_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-book-bookmark text-primary',
            name: 'Prise de RDV',
            path: '/admin/apdp-communautes/prises-rdv',
          }}})],1)]),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Demandes Usagers")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-single-copy-04 text-warning',
            name: 'En Cours',
            path: '/admin/formulaire-usagers/en-cours/liste',
          }}}),_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-folder-17 text-success',
            name: 'Validées',
            path: '/admin/formulaire-usagers/approuves/liste',
          }}})],1)]),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Forum")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-notification-70 text-yellow',
            name: 'Forums',
            path: '/admin/apdp-communautes/forums',
          }}})],1)]),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Espace DPO")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
            icon: 'ni ni-align-left-2 text-danger',
            name: 'Formations',
            path: '/admin/dpo/formations',
          }}})],1)]),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Utilisateurs")]),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Rôles & Permissions',
            icon: 'fa fa-user-shield text-primary',
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: 'Rôles',
              path: '/admin/utilisateurs/gestion-roles',
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Permissions',
              path: '/admin/utilisateurs/gestion-permissions',
            }}})],1),_c('sidebar-item',{attrs:{"link":{
            icon: 'fa fa-users text-success',
            name: 'Utilisateurs',
            path: '/admin/utilisateurs/gestion-utilisateurs',
          }}})],1)])],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }