<template>
  <resource-list currResource="menu" moduleName="menus">
    <template #listTable>
            <el-table-column
              label="Name"
              min-width="200px"
              prop="name"
              sortable="custom"
            />
            <!-- <el-table-column
              label="Model"
              min-width="200px"
              prop="model"
              sortable="custom"
            /> -->
            <el-table-column
              label="Parent"
              min-width="200px"
              prop="content"
              sortable="custom"
            >
            <template v-slot="{ row }">
              <div>{{ row.parent ? row.parent.name : '' }}</div>
            </template>
            </el-table-column>

            <el-table-column
              label="Crée le"
              prop="created_at"
              min-width="170px"
              sortable="custom"
            />
    </template>
  </resource-list>
</template>
<script>
import moment from "moment";
moment.locale("fr");
import {
  TableColumn,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css"
import ResourceList from "../../Components/Management/ResourceList.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResourceList,
    [TableColumn.name]: TableColumn,
  }
};
</script>
