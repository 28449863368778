import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        },
    };
    return axios.get(`${url}/admin/sections/${params.id}/questions`, options)
        .then(response => {
            return {
                list: response.data.data,
                meta: response.data.meta
            };
        });
}

function get(id) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };

    return axios.get(`${url}/admin/sections/${payload.id}/questions${id}`, options)
        .then(response => {
            return response.data.data;
        });
}

function add(payload) {

    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };
    return axios.post(`${url}/admin/sections/${payload.sectionId}/questions`, payload.params, options)
        .then(response => {
            return response.data.data;
        });
}

function update(payload) {

    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };

    return axios.patch(`${url}/admin/sections/${payload.sectionId}/questions/${payload.id}`, payload.params, options)
        .then(response => response.data.data );
}

function destroy(payload) {
    const options = {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        }
    };

    return axios.delete(`${url}/admin/sections/${payload.sectionId}/questions/${payload.id}`, options);
}

export default {
    list,
    get,
    add,
    update,
    destroy
};