<template>
  <div>
    <base-button
      :type="color"
      @click="openModalOf(currType)"
      size="sm"
      class="btn-icon-only rounded-circle"
    >
      <span class="btn-inner--icon display-5 font-weight-light"
        ><i class="fas fa-plus"></i
      ></span>
    </base-button>
    <modal :show.sync="modals.toggleModal" size="lg" body-classes="p-0">
      <div slot="header" class="modal-title">
        <h2>Associer Sections</h2>
        <small class="font-weight-lighter font-italic"
          ><sup class="font-weight-bold text-warning">*</sup>L'association
          consiste à rattacher la section sélectionnée à ce formulaire.</small
        >
      </div>
      <form role="form" @submit.prevent="handleSubmit">
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <base-input :label="fieldLabel">
              <el-select
                v-model="selectedSections"
                multiple
                filterable
                placeholder="Multiple sélection"
              >
                <el-option
                  v-for="(option, index) in availableSections"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </card>
        <div class="modal-footer d-flex justify-content-between">
          <base-button
            type="primary"
            :loading="isLoading"
            native-type="submit"
            :disabled="selectedSections.length <= 0"
            >Enregistrer</base-button
          >
          <base-button type="danger" @click="closeModal">Annuler</base-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {
  name: "HandleFormSectionsModal",
  props: {
    formId: Number,
    color: String,
    fieldLabel: String,
    currType: String,
    sections: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      modals: {
        toggleModal: false,
      },
      selectedSections: [],
      selectedType: "",
      isLoading: false,
    };
  },
  computed: {
    availableSections() {
      return this.$store.getters["form_sections/availableSectionsList"](
        this.sections
      );
    },
  },
  methods: {
    async getAvailableSections() {
      try {
        await this.$store.dispatch("form_sections/getAvailableSections", {
          type: this.selectedType,
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },
    openModalOf(type) {
      this.selectedType = type;
      this.getAvailableSections();
      this.modals.toggleModal = true;
    },
    closeModal() {
      this.selectedType = null;
      this.selectedSections = [];
      this.$store.commit("form_sections/SET_AVAILABLE_SECTIONS", []);
      this.modals.toggleModal = false; //close modal
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("forms/addSections", {
          id: this.formId,
          type: this.selectedType,
          sections: this.selectedSections,
        });

        this.$notify({
          type: "success",
          message: "Les détails de la section ont été mis à jour avec succès!",
        });
        this.$emit("formUpdated");

        this.isLoading = false;
        this.modals.toggleModal = false;
        this.selectedType = null;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.isLoading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
