<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Liste</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Utilisateurs</h3>
              </div>
              <div class="col-6 text-right">
                <base-button type="primary" icon size="sm" @click="addUser()">
                  <span class="btn-inner--icon"
                    ><i class="fas fa-user-edit"></i
                  ></span>
                  <span class="btn-inner--text">Ajouter Utilisateur</span>
                </base-button>
              </div>
            </div>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Rechercher..."
                  clearable
                />
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="mapUsers"
              @sort-change="sortChange"
            >
              <div slot="empty" v-if="loading">
                <img
                  src="/img/loading.gif"
                  style="height: 100px; width: 100px"
                />
              </div>
              <!-- <el-table-column label="Author" min-width="50px">
              <template v-slot="{ row }">
                <img
                  v-if="row.profile_image"
                  :src="row.profile_image"
                  class="avatar rounded-circle mr-3"
                />
              </template>
            </el-table-column> -->

              <el-table-column
                label="Nom"
                min-width="60px"
                prop="name"
                sortable="custom"
              />
              <el-table-column
                label="Email"
                min-width="90px"
                prop="email"
                sortable="custom"
              />
              <el-table-column
                label="Rôle"
                min-width="60px"
                prop="roles"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  <span>{{ row.roles[0].name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Created At"
                prop="created_at"
                min-width="50px"
                sortable="custom"
              />
              <el-table-column label="Statut" min-width="50px" align="center">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip content="Changer Statut" placement="top">
                    <base-switch
                      on-text="Activé"
                      off-text="Désactivé"
                      v-model="row.is_active"
                      type="success"
                      @input="toggleUser(row)"
                    ></base-switch>
                  </el-tooltip>
                </div>
              </el-table-column>
              <el-table-column label="Actions" min-width="50px" align="left">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip content="Modifier" placement="top">
                    <a
                      type="text"
                      @click="editUser(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-user-edit"></i>
                    </a>
                  </el-tooltip>

                  <el-tooltip content="Supprimer" placement="top">
                    <a
                      type="text"
                      @click="deleteUser(row.id)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-trash"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Affichage de {{ total ? from + 1 : 0 }} to {{ to }} sur
                {{ total }} Utilisateurs

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} lignes sélectionnées
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("fr");

import { BasePagination } from "@/components";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    RouteBreadCrumb,
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "created_at",
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      users: [],
      loading: true,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    mapUsers() {
      if (this.users) {
        let items = this.users;
        items.map((item) => {
          return (item.created_at = moment(
            new Date(item.created_at).getTime()
          ).fromNow());
        });
        return items;
      } else return [];
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: this.pagination.currentPage,
          size: this.pagination.perPage,
        };

        await this.$store.dispatch("users/list", params);
        this.users = this.$store.getters["users/list"];
        this.total = this.$store.getters["users/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },

    async toggleUser(user) {
      const context = user.is_active ? "Activer" : "Désactiver";

      const confirmation = await swal.fire({
        title: `${context} cet Utilisateur?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Oui, ${context}!`,
        cancelButtonText: "Non, Garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          const resp = await this.$store.dispatch("users/toggleStatus", {
            id: user.id,
          });
          if (resp.hasOwnProperty('success')) {
            await this.getList();
            if (resp.success) {
              this.$notify({
                type: "success",
                message: resp.message,
              });
            } else {
              this.$notify({
                type: "success",
                message: resp.message,
              });
            }
          } else if(resp.response?.status != 200) {
            user.is_active = !user.is_active;
            this.$notify({
              type: "danger",
              message: resp.response.data?.message,
            });
          } else {
            user.is_active = !user.is_active;
            this.$notify({
              type: "danger",
              message: "Erreur lors de la mise à jour",
            });
          }
        } else {
          user.is_active = !user.is_active;
        }
      } catch (error) {
        user.is_active = !user.is_active;
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },

    async deleteUser(id) {
      const confirmation = await swal.fire({
        title: `Supprimer cet Utilisateur?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui, le supprimer!",
        cancelButtonText: "Non, le garder",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          const resp = await this.$store.dispatch("users/destroy", id);
          await this.getList();
          if (resp.success)
            this.$notify({
              type: "success",
              message: resp.message,
            });
          else
            this.$notify({
              type: "danger",
              message: resp.message,
            });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },
    addUser() {
      this.$router.push({ name: "Ajouter Utilisateur" });
    },
    async editUser(row) {
      await this.$store.dispatch("profile/me");
      let me = await this.$store.getters["profile/me"];

      if (row.id === me.id) {
        // this.$router.push({ name: "Profile Utilisateur" });
        this.$router.push({ name: "AuthUser" });
      } else {
        this.$router.push({
          name: "Modifier Utilisateur",
          params: { id: row.id },
        });
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
