import service from '@/store/services/dpo/dpo-episodes-service';

const state = {
    list: [],
    episode: {},
    meta: {},
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, item) => {
        state.episode = item;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
};

const actions = {
    allEpisodes({ commit, dispatch }, payload) {
        return service.list(payload)
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            });
    },

    getEpisode({ commit }, params) {
        return service.get(params)
            .then((episode) => { commit('SET_RESOURCE', episode); });
    },

    addEpisode({ commit, dispatch }, params) {
        return service.add(params)
            .then((episode) => { commit('SET_RESOURCE', episode); });
    },

    updateEpisode({ commit, dispatch }, params) {
        return service.update(params)
            .then((episode) => { commit('SET_RESOURCE', episode); });
    },

    destroyEpisode({ commit, dispatch }, params) {
        return service.destroy(params);
    },
};

const getters = {
    list: state => state.list,
    episode: state => state.episode,
    listTotal: state => state.meta.total,
};

const episodes = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default episodes;