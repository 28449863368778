<template>
  <div>
    <h6 class="heading-small text-muted mb-4">{{ section.name }}</h6>

    <div class="pl-lg-4">
      <div class="row">
        <div v-for="a in section.answers" :key="a.id" class="col-lg-6">
          <div v-show="a.input_type.element == 'input'" class="form-group">
            <label
              :for="`input-${a.name}`"
              class="form-control-label text-wrap"
              style="max-width: 38rem"
              >{{ a.name }}<sup v-show="a.amswer_required" class="text-danger">*</sup></label
            >
            <div v-if="a.input_type.type == 'file'">
              <span v-if="a.answer == undefined || a.answer == null"><i class="text-muted">Aucun fichier renseigné.</i></span>
              <div v-else>
                <div v-for="(fileName, i) in a.answer.answer_file" :key="i">
                  <span>Fichier {{ i + 1 }}</span>
                  <button
                    @click="downloadFile(a.answer.id, fileName)"
                    class="ml-1 btn btn-sm btn-success"
                  >
                    <i class="fas fa-download"></i>
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <base-input
              v-else
              :id="`input-${a.name}`"
              :value="getAnswerValue(a.answer)"
              disabled
              aria-disabled=""
              alternative
            ></base-input>
          </div>
          <div v-show="a.input_type.element == 'textarea'" class="form-group">
            <label
              class="form-control-label text-wrap"
              style="max-width: 38rem"
              >{{ a.name }}</label
            >
            <textarea
              :value="getAnswerValue(a.answer)"
              class="form-control"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4" />
  </div>
</template>
<script>
import axios from "axios";
const url = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "FilledSection",
  props: ["section"],
  methods: {
    async downloadFile(answerId, name) {
      try {
        const response = await axios.get(
          `${url}/admin/download-file/answers/${answerId}?name=${name}`,
          {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
            responseType: 'blob',
          }
        );

        // Create a URL for the blob data
        let fileUrl = window.URL.createObjectURL(response.data);
        let fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();

        // Clean up the URL object
        URL.revokeObjectURL(fileUrl);
      } catch (error) {
        this.$notify({
          type: "danger",
          message:
            "Désolé, une erreur s'est produite lors du téléchargement du fichier!",
        });
      }
    },
    getAnswerValue(a) {
      if(a == undefined) return '';

      if(a.answer_numeric) {
        return a.answer_numeric;
      } else if(a.answer_text) {
        return a.answer_text;
      } else if(a.answer_yn) {
        return a.answer_yn;
      } else {
        return '';
      }
    },
  },
};
</script>
