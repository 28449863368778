import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('article_sections')
const EXCLUDED_RESOURCE = 'documentations';

export default {
    data() {
        return {
            sectionModal: {
                isOpen: false,
                isLoading: false
            },
            sectionForm: { name: '' },
            toggleSection: false,
        }
    },
    watch: {
        toggleSection: {
          handler: function (newVal, oldVal) {
            if (!newVal && this.resource?.slug != EXCLUDED_RESOURCE) {
              this.item.section = "";
            }
          },
          immediate: true,
        },
      },
    computed: {
        ...mapGetters({
            allSections: 'all',
            getSectionByName: 'getSectionByName',
            section: 'section'
        }),
    },
    created() {
        this.getSections()
    },
    methods: {
        async getSections() {
            try {
                await this.$store.dispatch("article_sections/list");

                if (this.allSections && this.allSections.length) {
                    this.setSection({});
                    // this.item.section = this.allSections[0].value;
                }
            } catch (error) {
                this.$notify({
                    type: "danger",
                    message: `Une erreur est survenue lors de la récupération des Sections.`,
                });
            }
        },

        querySection(input, callback) {
            let sections = this.allSections
            let results = input ? sections.filter(this.createSectionFilter(input)) : sections

            //call callback function to return suggestions
            callback(results)
        },
        async handleEditSection() {
            this.isLoading = true;
            let payload = {
                id: this.sectionForm.id,
                name: this.sectionForm.name,
            };
            try {
                await this.$store.dispatch("article_sections/update", payload);
                await this.getSections()

                this.sectionModal.isOpen = false;
                this.$notify({
                    type: "success",
                    message: "Section modifiée avec succès.",
                });
            } catch (error) {
                this.sectionForm.name = ''
                this.$notify({
                    type: "danger",
                    message: "Désolé, une erreur est survenue!",
                });
                this.setApiValidation(error.response.data.errors);
            }
            this.isLoading = false;
        },

        handleSectionSelect(t) {
            this.setSection(t)
            this.item.resource_type = t.value
        },

        async deleteSection() {
            const confirmation = await swal.fire({
                title: `Supprimer le type`,
                html: `<p>Confirmez-vous la suppression de la section <b>${this.item.section}</b>?</p>`,
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: `Oui, supprimer`,
                cancelButtonText: "Non, annuler",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-warning",
                }
            });

            try {
                if (confirmation.value === true) {
                    let section = this.getSectionByName(this.item.section)
                    await this.$store.dispatch("article_sections/destroy", { id: section.id });
                    await this.getSections()
                    this.$notify({
                        type: "success",
                        message: "La section a été supprimée avec succès.",
                    });
                }
            } catch (error) {
                if(error.response !== undefined) {
                    this.$notify({
                        type: "danger",
                        message: error.response?.data.message,
                    });
                } else {
                    this.$notify({
                        type: "danger",
                        message: "Désolé, une erreur est survenue lors de la suppression de la section!",
                    });
                }
            }
        },

        ...mapMutations({
            setSection: 'SET_RESOURCE'
        }),

        openEditSectionModal() {
            if (Object.keys(this.section).length) {
                this.sectionForm = {
                    id: this.section.id,
                    name: this.section.value
                }
            } else if (this.item.section.length && this.getSectionByName(this.item.section)) {
                let section = this.getSectionByName(this.item.section)
                this.sectionForm = {
                    id: section.id,
                    name: section.name
                }
            } else {
                this.sectionForm.name = ''
            }
            this.sectionModal.isOpen = true
        },

        createSectionFilter(input) {
            return (section) => {
                return (section.value.toLowerCase().indexOf(input.toLowerCase()) === 0)
            }
        },
    }
}