<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ajouter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Idée</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Retourner à la liste</base-button
              >
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <!-- <div class="form-group">
                <label class="form-control-label"> Image </label>
                <div v-if="image" class="profile-image card-img pb-4">
                  <img :src="`${image}`" class="profile-image argon-image" />
                </div>
                <div v-else class="profile-image">
                  <img src="/img/placeholder.jpg" class="argon-image" />
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="image"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeImage"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Retirer
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!image" for="imageInput" class="mb-0"
                      >Selectionner une image</label
                    >
                    <label v-else for="imageInput" class="mb-0">Changer</label>
                    <input
                      id="imageInput"
                      ref="imageInput"
                      accept="image/*"
                      type="file"
                      style="display: none"
                      @change="onSelectFile"
                    />
                  </base-button>
                </div>
              </div>
              <validation-error :errors="apiValidationErrors.attachment" /> -->

              <base-input
                label="Thème"
                v-model="item.topic"
              />

              <base-input label="Idée">
                <html-editor v-model="item.content" name="editor" />
              </base-input>
              <validation-error :errors="apiValidationErrors.excerpt" />

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Ajouter sur le forum
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      // file: null,
      // image: null,
      item: {
        topic: "",
        content: "",
        excerpt: "",
        // image: null,
        user: null,
      },
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "Forums" });
    },

    // removeImage() {
    //   this.image = null;
    // },

    async handleSubmit() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
      //   });
      //   return;
      // }
      this.item.user = this.user.id
      this.item.excerpt = this.item.content;
      try {
        delete this.item.id;
        await this.$store.dispatch("forums/add", this.item);
        this.item = await this.$store.getters["forums/forum"];

        // if (this.file) {
        //   await this.$store.dispatch("forums/upload", {
        //     article: this.item,
        //     image: this.file,
        //   });

        //   this.item.image = await this.$store.getters["forums/url"];
        // }

        this.$notify({
          type: "success",
          message: "Idée ajoutée avec succès",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.item.id) {
          await this.$store.dispatch("items/destroy", this.item.id);
        }
      }
    },

    // async onSelectFile(e) {
    //   let files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.createImage(files[0]);
    // },

    // createImage(file) {
    //   let reader = new FileReader();
    //   reader.onload = (e) => {
    //     this.image = e.target.result;
    //     this.file = file;
    //   };

    //   reader.readAsDataURL(file);
    // },
  },
};
</script>
