import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

const options = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

function list(payload) {
  const requestOptions = {
    ...options,
    params: payload.params,
  };

  return axios.get(`${url}/admin/services`, requestOptions)
                .then(({ data }) => data.data )
                .catch(e => ({...e}));
}

function get(payload) {
  
  return axios
    .get(`${url}/admin/services/${payload.id}`, options)
    .then((response) => {
      return response.data.data;
    });
}

function add(payload) {
  return axios
    .post(`${url}/admin/services`, payload, options)
    .then((response) => {
      return response.data.data;
    });
}

function update(payload) {
  return axios
    .put(`${url}/admin/services/${payload.id}`, payload, options)
    .then((response) => {
      return response.data.data;
    });
}

function destroy(id) {
  return axios.delete(`${url}/admin/services/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
