<template>
  <div class="content">
    <base-header type="primary" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Détails</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="mt--6">
      <div class="header pb-6 d-flex align-items-center profile-header">
        <!-- Mask -->
        <span class="mask bg-gradient-default opacity-8"></span>
        <!-- Header container -->
        <div class="container-fluid">
          <div class="row">
            <Transition mode="out-in" :duration="{ enter: 900, leave: 800 }">
              <div
                v-if="formationState === 'showing'"
                class="col-lg-7 col-md-10 text-nowrap"
              >
                <h1 class="display-3 text-white">
                  {{ formFormation.title }}
                </h1>
                <p class="text-white mt-0 mb-5" v-html="formFormation.description"></p>
                <base-button @click="formationState = 'editing'" type="secondary"
                  >Modifier les Informations</base-button
                >
              </div>
              <div
                v-else-if="formationState === 'editing'"
                class="col-lg-7 col-md-10 text-wrap"
              >
                <edit-formation-page
                  @updateState="(state) => formationState = state"
                  @updateTraining="handleSubmit"
                  :state="formationState"
                  :data="formFormation"
                  :errors="apiValidationErrors"
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>

      <div class="container-fluid mt--6">
        <card>
          <create-support-modal
            slot="header"
            :formId="id()"
            :formEpisodes="formFormation.sections"
            @episodeCreated="get"
          />
          <formation-support-collapse
            :formId="id()"
            :initialEpisodes="this.formFormation.supports"
            @episodesUpdated="get"
          />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import CreateSupportModal from "@/components/Modals/Dpo/CreateEpisodeModal.vue";
import FormationSupportCollapse from "@/components/Collapse/FormEpisodeCollapse.vue";
import EditFormationPage from "./EditFormationPage.vue";

import formMixin from "@/mixins/form-mixin";
export default {
  name: "ShowTrainingPage",
  components: {
    CreateSupportModal,
    RouteBreadCrumb,
    FormationSupportCollapse,
    EditFormationPage,
  },

  mixins: [formMixin],

  data() {
    return {
      type: null,
      formationState: "showing",
      formFormation: {
        title: "",
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        description: "",
        supports: [],
      },
    };
  },
  created() {
    this.get();
  },
  methods: {
    id() {
      return this.$route.params.id;
    },

    async get() {
      try {
        await this.$store.dispatch("formations/getFormation", {id: this.id()});
        this.formFormation = this.$store.getters["formations/formation"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async handleSubmit(e) {
      try {
        await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
        // if (this.user.roles[0].name === "member") {
        //   this.$notify({
        //     type: "danger",
        //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
        //   });
        //   return;
        // }

        await this.$store.dispatch("formations/updateFormation", {
          id: this.id(),
          item: {...e},
        });
        this.formFormation = await this.$store.getters["formations/formation"];
        this.formationState = 'showing';

        this.$notify({
          type: "success",
          message: "Les détails du formulaires ont été mis à jour avec succès!",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/brand/logo1.png);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
