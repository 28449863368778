<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ajouter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb pageTitle="Ajouter FAQ"></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">FAQ</h3>
              </div>
              <div class="col-4 text-right">
                <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="handleSubmit">
                <div class="d-flex flex-row">
                  <base-input label="Thématique" style="min-width: 25%;">
                    <el-autocomplete class="d-block" v-model="item.thematic" :fetch-suggestions="queryThematic"
                      placeholder="Selectionner..." @select="handleSelect">
                    </el-autocomplete>
                  </base-input>
                  <div v-if="getThematicByName(item.thematic)" class="px-2 pt-2 align-self-center">
                    <base-button type="primary" @click="openEditModal" class="btn-icon-only rounded-circle">
                      <span class="btn-inner--icon display-5 font-weight-light"><i class="fas fa-pen"></i></span>
                    </base-button>
                    <base-button type="danger" @click="deleteThematic" class="btn-icon-only rounded-circle">
                      <span class="btn-inner--icon display-5 font-weight-light"><i class="fas fa-trash"></i></span>
                    </base-button>
                  </div>
                </div>
                <validation-error :errors="apiValidationErrors.thematic" />
                <base-input label="Question" v-model="item.question" />
                <validation-error :errors="apiValidationErrors.question" />
                <base-input label="Réponse" v-model="item.answer" />
                <validation-error :errors="apiValidationErrors.answer" />

                <div class="my-4">
                  <base-button type="button" class="btn btn-sm btn-primary" native-type="submit">
                    Ajouter FAQ
                  </base-button>
                </div>
              </form>
              <modal :show.sync="modals.editThematicModal" header-classes="bg-success text-white" body-classes="p-0">
                <div slot="header" class="modal-title text-white d-inline-block">
                  <i class="fas fa-info-circle display-4"></i>
                  <h2 class="text-white d-inline ml-3">Modifier la thématique</h2>
                </div>
                <form role="form" @submit.prevent="handleEditThematic">
                  <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                    <template>
                      <div class="row">
                        <div class="col-lg-8">
                          <base-input name="Thematique" v-model="thematicForm.name"
                            placeholder="Entrer votre thématique" required />
                        </div>
                      </div>
                    </template>
                  </card>
                  <div class="modal-footer d-flex justify-content-between">
                    <base-button type="primary" :loading="isLoading" native-type="submit">Enregistrer</base-button>
                    <base-button type="danger" @click="modals.editThematicModal = false">Annuler</base-button>
                  </div>
                </form>
              </modal>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapMutations } = createNamespacedHelpers('thematics')

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Autocomplete } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      isLoading: false,
      // file: null,
      // image: null,
      item: {
        thematic: '',
        question: "",
        answer: "",
        user: null,
      },
      modals: {
        editThematicModal: false
      },
      thematicForm: { name: '' },
    };
  },
  computed: {
    ...mapGetters([
      'all',
      'getThematicByName',
      'thematic'
    ])
  },
  created() {
    this.getThematics();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "list_faqs" });
    },

    async getThematics() {
      try {
        await this.$store.dispatch("thematics/list", {
          params: {
            of_type: 'faq'
          }
        })

        if (this.all && this.all.length) {
          this.setThematic({})
          this.item.thematic = this.all[0].value
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
        this.setApiValidation(error.response.data.data.errors);
      }
    },

    queryThematic(input, callback) {
      let thematics = this.all
      let results = input ? thematics.filter(this.createFilter(input)) : thematics

      //call callback function to return suggestions
      callback(results)
    },

    // removeImage() {
    //   this.image = null;
    // },

    async handleSubmit() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
      //   });
      //   return;
      // }
      this.item.user = this.user.id
      try {
        delete this.item.id;
        await this.$store.dispatch("faqs/add", this.item);
        this.item = await this.$store.getters["faqs/faq"];

        // if (this.file) {
        //   await this.$store.dispatch("forums/upload", {
        //     article: this.item,
        //     image: this.file,
        //   });

        //   this.item.image = await this.$store.getters["forums/url"];
        // }

        this.$notify({
          type: "success",
          message: "FAQ ajoutée avec succès",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.item.id) {
          await this.$store.dispatch("items/destroy", this.item.id);
        }
      }
    },
    handleSelect(t) {
      this.setThematic(t)
      this.item.thematic = t.value
    },

    // async onSelectFile(e) {
    //   let files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.createImage(files[0]);
    // },

    // createImage(file) {
    //   let reader = new FileReader();
    //   reader.onload = (e) => {
    //     this.image = e.target.result;
    //     this.file = file;
    //   };

    //   reader.readAsDataURL(file);
    // },
    createFilter(input) {
      return (thematic) => {
        return (thematic.value.toLowerCase().indexOf(input.toLowerCase()) === 0)
      }
    },
    async deleteThematic() {
      const confirmation = await swal.fire({
        title: `Supprimer cette thématique?`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Supprimer`,
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        }
      });

      try {
        if (confirmation.value === true) {
          let thematic = this.getThematicByName(this.item.thematic)
          await this.$store.dispatch("thematics/destroy", { id: thematic.id });
          await this.getThematics()
          this.$notify({
            type: "success",
            message: "La thématique a été supprimée avec succès.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue lors de la suppression de la thématique!",
        });
      }
    },

    ...mapMutations({
      setThematic: 'SET_RESOURCE'
    }),

    openEditModal() {
      if (Object.keys(this.thematic).length) {
        this.thematicForm = {
          id: this.thematic.id,
          name: this.thematic.value
        }
      }
      else if (this.item.thematic.length && this.getThematicByName(this.item.thematic)) {
        let thematic = this.getThematicByName(this.item.thematic)
        this.thematicForm = {
          id: thematic.id,
          name: thematic.name
        }
      }
      else {
        this.thematicForm.name = ''
      }
      this.modals.editThematicModal = true
    },

    async handleEditThematic() {
      this.isLoading = true;
      let payload = {
        id: this.thematicForm.id,
        name: this.thematicForm.name,
      };
      try {
        await this.$store.dispatch("thematics/update", payload);
        await this.getThematics()

        this.modals.editThematicModal = false;
        this.$notify({
          type: "success",
          message: "Thématique modifiée avec succès.",
        });
      } catch (error) {
        this.thematicForm.name = ''
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    }
  },
};
</script>
