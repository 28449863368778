import service from '@/store/services/articles/articles-service';

const state = {
    list: [],
    article: {},
    meta: {},
    url: null
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, article) => {
        state.article = article;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_URL: (state, url) => {
        state.url = url;
    }
};

const actions = {
    list({ commit, dispatch }, params) {
        return service.list(params)
            .then(({ list, meta }) => {
                commit('SET_LIST', list);
                commit('SET_META', meta);
            });
    },

    get({ commit, dispatch }, params) {
        return service.get(params)
            .then((article) => { commit('SET_RESOURCE', article); });
    },

    add({ commit, dispatch }, params) {
        return service.add(params)
            .then((article) => { commit('SET_RESOURCE', article); });
    },

    update({ commit, dispatch }, params) {
        return service.update(params)
            .then((article) => { commit('SET_RESOURCE', article); });
    },

    destroy({ commit, dispatch }, params) {
        return service.destroy(params);
    },

    upload({ commit, dispatch }, { article, medias, field = 'media' }) {
        return service.upload(article, medias, field)
            .then((url) => {
                commit('SET_URL', url);
            })
            .catch(error => {
                if (Object.hasOwn(error, 'errors')) return error.errors
                return error.message;
            });
    }
};

const getters = {
    list: state => state.list,
    listTotal: state => state.meta.total,
    article: state => state.article,
    url: state => state.url
};

const articles = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default articles;