import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(payload) {
    const options = {
        params: payload.params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        }
    };

    return axios.get(`${url}/admin/forums`, options).then(response => {
        return {
            list: response.data.data,
            meta: response.data.meta
        };
    });
}

function get(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .get(`${url}/admin/forums/${payload.id}`, options)
        .then(response => {
            return response.data.data
        });
}

function add(payload) {

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/admin/forums`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function update(item) {
    const payload = jsona.serialize({
        stuff: item,
        includeNames: []
    });

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .patch(`${url}/items/${item.id}?include=category,tags`, payload, options)
        .then(response => {
            return jsona.deserialize(response.data);
        });
}

function destroy(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/items/${id}`, options);
}

function upload(item, image) {
    const bodyFormData = new FormData();
    bodyFormData.append("attachment", image);

    return axios
        .post(`${url}/uploads/items/${item.id}/image`, bodyFormData)
        .then(response => {
            return response.data.url;
        });
}

export default {
    list,
    get,
    add,
    update,
    destroy,
    upload
};