<template>
  <div class="row align-items-center">
    <div class="col-8">
      <h3 class="mb-0">Supports</h3>
    </div>
    <div class="col-4 text-right">
      <base-button type="primary" @click="modals.form = true">
        Ajouter Support
      </base-button>
      <modal :show.sync="modals.form" body-classes="p-0">
        <h6 slot="header" class="modal-title">Ajouter un support</h6>
        <div v-if="!this.id">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <form v-else role="form" @submit.prevent="handleSubmit">
          <card
            type="secondary"
            header-classes="bg-transparent"
            body-classes="px-lg-5 py-lg-2"
            class="border-0 mb-0 text-left"
          >
            <template>
              <base-input
                alternative
                v-model="formModal.title"
                placeholder="Titre du support"
              />
              <div class="d-flex flex-row justify-content-start mb-3">
                <base-radio v-model="formModal.media_type" name="video">
                  Lien
                </base-radio>

                <base-radio
                  v-model="formModal.media_type"
                  name="livre"
                  class="ml-3"
                >
                  Document
                </base-radio>
              </div>

              <base-input
                v-if="formModal.media_type == 'video'"
                type="url"
                alternative
                v-model="formModal.media_url"
                placeholder="Lien du support"
              />

              <file-input
                v-if="formModal.media_type == 'livre'"
                class="mb-3"
                @change="filesChange"
              />
              <validation-error :errors="apiValidationErrors.document" />

              <base-input label="Description du support">
                <textarea
                  v-model="formModal.description"
                  name="editor"
                  class="form-control"
                  rows="3"
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.description" />
            </template>
          </card>
          <div class="d-flex justify-content-between my-3 px-lg-5">
            <base-button
              type="primary"
              :loading="isLoading"
              native-type="submit"
              >Enregistrer</base-button
            >
            <base-button type="danger" @click="modals.form = false"
              >Annuler</base-button
            >
          </div>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
import FileInput from "@/components/Inputs/FileInput";
import { Modal } from "@/components";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

import { Select, Option } from "element-ui";
export default {
  name: "CreateEpisodeModal",
  emits: ["episodeCreated"],
  props: ["formId", "formEpisodes"],
  components: {
    FileInput,
    Modal,
    BaseButton,
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [formMixin],
  data() {
    return {
      isLoading: false,
      modals: {
        form: false,
      },
      formModal: {
        title: "",
        description: "",
        media_type: "video",
        media_url: "",
        document: null,
        author: "",
      },
    };
  },

  computed: {
    id() {
      return this.formId !== undefined ? true : false;
    },
  },

  methods: {
    filesChange(files) {
      this.formModal.document = files[0];
    },
    async handleSubmit() {
      this.isLoading = true;
      let payload = {
        formation: this.formId,
        params: this.formModal,
      };
      try {
        await this.$store.dispatch("episodes/addEpisode", payload);
        // let episode = this.$store.getters["form_episodes/episode"];
        this.modals.form = false;

        this.$notify({
          type: "success",
          message: "Support ajouté avec succès.",
        });

        this.$nextTick(() => {
          this.formModal = { title: "", description: "", media_type: "video", media_url: "", document: null, author: "" };
        }, this.$emit("episodeCreated"));
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },
  },
};
</script>
