import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";
import AuthUser from "@/views/Pages/AuthUser/Profile.vue";

//Menu Pages
import ListMenusPage from "../views/Managements/Menus/ListMenuPage.vue";
import AddMenusPage from "../views/Managements/Menus/AddMenuPage.vue";
import EditMenusPage from "../views/Managements/Menus/EditMenuPage.vue";

//Content Pages
import ListContentsPage from "../views/Managements/Contents/ListContentPage.vue";
import AddContentsPage from "../views/Managements/Contents/AddContentPage.vue";
import EditContentsPage from "../views/Managements/Contents/EditContentPage.vue";

//Posts Pages
import ListPostsPage from "../views/Managements/Posts/ListPostPage.vue";
import AddPostsPage from "../views/Managements/Posts/AddPostPage.vue";
import EditPostsPage from "../views/Managements/Posts/EditPostPage.vue";

//Documentations Pages
import ListDocumentationsPage from "../views/Managements/Documentations/ListDocumentationPage.vue";
import AddDocumentationsPage from "../views/Managements/Documentations/AddDocumentationPage.vue";
import EditDocumentationsPage from "../views/Managements/Documentations/EditDocumentationPage.vue";

// FAQs Pages
import ListFaqsPage from "../views/Faqs/ListFaqPage.vue"
import AddFaqsPage from "../views/Faqs/AddFaqPage.vue"
import EditFaqsPage from "../views/Faqs/EditFaqPage.vue"

//APDP Forms
import ListFormsPage from "../views/UserForms/Forms/ListFormPage.vue"
import ShowFormsPage from "../views/UserForms/Forms/ShowFormPage.vue"
import AddFormsPage from "../views/UserForms/Forms/AddFormPage.vue"
import SettingFormsPage from "../views/UserForms/Forms/Sections/SettingFormsPage.vue"
import ShowSectionPage from "../views/UserForms/Forms/Sections/ShowSectionPage.vue"

// Pending User Form
import ListPendingRequestsPage from "../views/UserForms/Requests/Pending/ListPendingRequestPage.vue"
import ShowPendingRequestsPage from "../views/UserForms/Requests/Pending/ShowPendingRequestPage.vue"

//Approved User Form
import ListApprovedRequestsPage from "../views/UserForms/Requests/Approved/ListApprovedRequestPage.vue"
import ShowApprovedRequestsPage from "../views/UserForms/Requests/Approved/ShowApprovedRequestPage.vue"

//DPO Formation Pages
import ListFormationsPage from "../views/Dpo/Formation/ListFormationPage.vue";
import ShowFormationsPage from "../views/Dpo/Formation/ShowFormationPage.vue";
import AddFormationsPage from "../views/Dpo/Formation/AddFormationPage.vue";
// import EditFormationsPage from "../views/Dpo/Formation/EditFormationPage.vue";

//Blog Post Pages
import ListBlogPostsPage from "../views/Communities/BlogPosts/ListBlogPostPage.vue"
import AddBlogPostsPage from "../views/Communities/BlogPosts/AddBlogPostPage.vue"
import EditBlogPostsPage from "../views/Communities/BlogPosts/EditBlogPostPage.vue"

//Forum Pages
import ListForumsPage from "../views/Communities/Forums/ListForumPage.vue"
import ShowForumsPage from "../views/Communities/Forums/ShowForumPage.vue"
import AddForumsPage from "../views/Communities/Forums/AddForumPage.vue"
// import EditForumsPage from "../views/Communities/Forums/EditForumPage.vue"

// REndez-vous pages
import ListBookingsPage from "../views/Communities/Bookings/ListBookingPage.vue"
import ShowBookingsPage from "../views/Communities/Bookings/ShowBookingPage.vue"

//Users Pages
import ListRolePage from "../views/Managements/Roles/ListRolePage.vue";
import EditRolePage from "../views/Managements/Roles/EditRolePage.vue";
import AddRolePage from "../views/Managements/Roles/AddRolePage.vue";
import ListPermissionPage from "../views/Managements/Permissions/ListPermissionPage.vue";
import EditPermissionPage from "../views/Managements/Permissions/EditPermissionPage.vue";
import AddPermissionPage from "../views/Managements/Permissions/AddPermissionPage.vue";
import ListUserPage from "../views/Managements/Users/ListUserPage.vue";
import EditUserPage from "../views/Managements/Users/EditUserPage.vue";
import AddUserPage from "../views/Managements/Users/AddUserPage.vue";

// Newsletters
import ListNewsletterPage from "../views/Managements/Newsletters/ListNewsletterPage.vue";

const AlternativeDashboard = () =>
    import (
        /* webpackChunkName: "dashboard" */
        "@/views/Dashboard/AlternativeDashboard.vue"
    );

// Pages
const User = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");
const Pricing = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
const DoubleAuth = () =>
    import ( /* webpackChunkName: "pages" */ "@/views/Pages/DoubleAuth.vue");

const PasswordReset = () =>
    import ( /* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
    import ( /* webpackChunkName: "password" */ "@/views/Password/Email.vue");

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import admin_creator from "@/middleware/admin_creator";

let usersMenu = {
    path: "/admin/utilisateurs",
    component: DashboardLayout,
    name: "Utilisateurs",
    redirect: "/admin/utilisateurs/gestion-utilisateurs",
    children: [{
            path: "profile",
            name: "Profile Utilisateur",
            component: User,
            meta: { middleware: auth }
        },
        {
            path: "gestion-roles",
            name: "Liste Roles",
            component: ListRolePage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-roles/ajouter-role",
            name: "Ajouter Role",
            component: AddRolePage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-roles/modifier-role/:id",
            name: "Modifier Role",
            component: EditRolePage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-permissions",
            name: "Liste Permissions",
            component: ListPermissionPage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-permissions/ajouter-permission",
            name: "Ajouter Permission",
            component: AddPermissionPage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-permissions/modifier-permission/:id",
            name: "Modifier Permission",
            component: EditPermissionPage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-utilisateurs",
            name: "Liste Utilisateurs",
            component: ListUserPage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-utilisateurs/ajouter-utilisateur",
            name: "Ajouter Utilisateur",
            component: AddUserPage,
            meta: { middleware: auth }
        },
        {
            path: "gestion-utilisateurs/modifier-utilisateur/:id",
            name: "Modifier Utilisateur",
            component: EditUserPage,
            meta: { middleware: auth }
        },
        // {
        //     path: "category-management/list-categories",
        //     name: "List Categories",
        //     components: ListCategoryPage,
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "category-management/add-category",
        //     name: "Add Category",
        //     components: { default: AddCategoryPage },
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "category-management/edit-category/:id",
        //     name: "Edit Category",
        //     components: { default: EditCategoryPage },
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "tag-management/list-tags",
        //     name: "List Tags",
        //     components: { default: ListTagPage },
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "tag-management/add-tag",
        //     name: "Add Tag",
        //     components: { default: AddTagPage },
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "tag-management/edit-tag/:id",
        //     name: "Edit Tag",
        //     components: { default: EditTagPage },
        //     meta: { middleware: admin_creator }
        // },
        // {
        //     path: "item-management/list-items",
        //     name: "List Items",
        //     components: { default: ListItemPage },
        //     meta: { middleware: auth }
        // },
        // {
        //     path: "item-management/add-item",
        //     name: "Add Item",
        //     components: { default: AddItemPage },
        //     meta: { middleware: auth }
        // },
        // {
        //     path: "item-management/edit-item/:id",
        //     name: "Edit Item",
        //     components: { default: EditItemPage },
        //     meta: { middleware: auth }
        // }
    ]
}

let menuMenu = {
    path: "/admin/siteweb-menus",
    component: DashboardLayout,
    redirect: "/admin/siteweb-menus/liste",
    name: "Site Web Menus",
    children: [{
            path: "liste",
            name: "list_menus",
            component: ListMenusPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-menu",
            name: "add_menu",
            component: AddMenusPage,
            meta: { middleware: auth, }
        },
        {
            path: "modifier-menu",
            name: "edit_menu",
            component: EditMenusPage,
            meta: { middleware: auth, }
        },
    ]
}

let contentsManagementsMenu = {
    path: "/admin/gestion-contenus",
    component: DashboardLayout,
    redirect: "/admin/gestion-contenus/liste",
    name: "Contenus",
    children: [{
            path: "liste",
            name: "list_contents",
            component: ListContentsPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-contenu",
            name: "add_content",
            component: AddContentsPage,
            meta: { middleware: auth, }
        },
        {
            path: "modifier-contenu",
            name: "edit_content",
            component: EditContentsPage,
            meta: { middleware: auth, }
        },
    ]
}

let documentationsManagementsMenu = {
    path: "/admin/gestion-documentations",
    component: DashboardLayout,
    redirect: "/admin/gestion-documentations/liste",
    name: "Documentations",
    children: [{
            path: "liste",
            name: "list_documentations",
            component: ListDocumentationsPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-document",
            name: "add_documentation",
            component: AddDocumentationsPage,
            meta: { middleware: auth, }
        },
        {
            path: "modifier-document",
            name: "edit_documentation",
            component: EditDocumentationsPage,
            meta: { middleware: auth, }
        },
    ]
}

let postsManagementsMenu = {
    path: "/admin/gestion-publications",
    component: DashboardLayout,
    redirect: "/admin/gestion-publications/liste",
    name: "Publications",
    children: [{
            path: "liste",
            name: "list_publications",
            component: ListPostsPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-publication",
            name: "add_publication",
            component: AddPostsPage,
            meta: { middleware: auth, }
        },
        {
            path: "modifier-publication",
            name: "edit_publication",
            component: EditPostsPage,
            meta: { middleware: auth, }
        },
    ]
}

let faqMenu = {
    path: "/admin/apdp-faqs",
    component: DashboardLayout,
    redirect: "/admin/apdp-faqs/list",
    name: "FAQs APDP",
    children: [{
            path: "liste",
            name: "list_faqs",
            component: ListFaqsPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-faq",
            name: "add_faq",
            component: AddFaqsPage,
            meta: { middleware: auth, }
        },
        {
            path: "modifier-faq/:id",
            name: "edit_faq",
            component: EditFaqsPage,
            meta: { middleware: auth, }
        },
    ]
}

let typeFormMenu = {
    path: "/admin/apdp-formulaires/type",
    component: DashboardLayout,
    name: "Type Formulaires APDP",
    children: [{
            path: ":type",
            name: "Formulaires de Type",
            component: ListFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: ":type/details-formulaire/:id",
            name: "Détails Formulaire de Type",
            component: ShowFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: ":type/ajouter-formulaire",
            name: "Ajouter Formulaire de Type",
            component: AddFormsPage,
            meta: { middleware: auth, }
        },
    ]
}

let formMenu = {
    path: "/admin/apdp-formulaires",
    component: DashboardLayout,
    name: "Formulaires APDP",
    redirect: "/admin/apdp-formulaires/liste",
    children: [
        {
            path: "liste",
            name: "Liste Formulaires",
            component: ListFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: "details-formulaire/:id",
            name: "Détails Formulaire",
            component: ShowFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: "ajouter-formulaire",
            name: "Ajouter Formulaire",
            component: AddFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: "parametres-formulaires",
            name: "Paramètre Formulaires",
            component: SettingFormsPage,
            meta: { middleware: auth, }
        },
        {
            path: "parametres-formulaires/:id/questions",
            name: "Détails Section",
            component: ShowSectionPage,
            meta: { middleware: auth, navbarType: "light", }
        },
    ]
}

let dpoFormationMenu = {
    path: "/admin/dpo/formations",
    component: DashboardLayout,
    name: "Formation DPO",
    redirect: "/admin/dpo/formations/liste",
    children: [{
            path: "liste",
            name: "Liste Formation DPO",
            component: ListFormationsPage,
            meta: { middleware: auth, navbarType: "light", }
        },
        {
            path: "details-formation/:id",
            name: "Détails Formation DPO",
            component: ShowFormationsPage,
            meta: { middleware: auth, navbarType: "light", }
        },
        {
            path: "ajouter-formation",
            name: "Ajouter Formation DPO",
            component: AddFormationsPage,
            meta: { middleware: auth, navbarType: "light", }
        },
    ]
}

let pendingUsersFormMenu = {
    path: "/admin/formulaire-usagers/en-cours",
    component: DashboardLayout,
    redirect: "/admin/formulaire-usagers/en-cours/liste",
    name: "Demandes Usagers En Cours",
    children: [{
            path: "liste",
            name: "Listes Demandes Usagers En Cours",
            component: ListPendingRequestsPage,
            meta: { middleware: auth, }
        },
        {
            path: "details/:id",
            name: "Détails Demande En Cours",
            component: ShowPendingRequestsPage,
            meta: { middleware: auth, }
        },
    ]
}

let approvedUsersFormMenu = {
    path: "/admin/formulaire-usagers/approuves",
    component: DashboardLayout,
    redirect: "/admin/formulaire-usagers/approuves/liste",
    name: "Demandes Usagers Approuvées",
    children: [{
            path: "liste",
            name: "Listes Demandes Usagers Approuvées",
            component: ListApprovedRequestsPage,
            meta: { middleware: auth, }
        },
        {
            path: "details/:id",
            name: "Détails Demande Approuvée",
            component: ShowApprovedRequestsPage,
            meta: { middleware: auth, }
        },
    ]
}

let authPages = {
    path: "/admin",
    component: AuthLayout,
    name: "Authentication",
    children: [{
            path: "login",
            name: "Login",
            component: Login,
            meta: { middleware: guest }
        },
        {
            path: "password/reset",
            name: "PasswordReset",
            component: PasswordReset,
            meta: { middleware: guest }
        },
        {
            path: "password/email",
            name: "PasswordEmail",
            component: PasswordEmail,
            meta: { middleware: guest }
        },
    ]
};

let communityPages = {
    path: "/admin/apdp-communautes",
    component: DashboardLayout,
    redirect: "/admin/apdp-communautes/blogs",
    name: "Communautés",
    children: [{
            path: "blogs",
            name: "Blogs",
            component: ListBlogPostsPage,
            meta: { middleware: auth, }
        },
        {
            path: "blogs/ajouter-post",
            name: "Ajouter Post",
            component: AddBlogPostsPage,
            meta: { middleware: auth, }
        },
        {
            path: "blogs/editer-post/:id",
            name: "Modifier Post",
            component: EditBlogPostsPage,
            meta: { middleware: auth, }
        },
        {
            path: "forums",
            name: "Forums",
            component: ListForumsPage,
            meta: { middleware: auth, }
        },
        {
            path: "forums/ajouter-idee",
            name: "Ajouter Idée",
            component: AddForumsPage,
            meta: { middleware: auth, }
        },
        {
            path: "forums/details/:id",
            name: "Détails Idée",
            component: ShowForumsPage,
            meta: { middleware: auth, }
        },
        {
            path: "prises-rdv",
            name: "RDV",
            component: ListBookingsPage,
            meta: { middleware: auth, }
        },
        {
            path: "prises-rdv/parametres",
            name: "Paramétrer Disponibilités",
            component: ShowBookingsPage,
            meta: { middleware: auth, }
        },
    ]
}

const routes = [{
        path: "/",
        redirect: "/admin/dashboard",
        meta: { middleware: auth, }
    },
    {
        path: "/admin",
        redirect: "/admin/dashboard",
        meta: { middleware: auth, }
    },
    {
        path: "/dashboard",
        redirect: "/admin/dashboard",
        meta: { middleware: auth, }
    },
    {
        path: "/admin/dpo",
        redirect: "/admin/dpo/formations/liste",
        meta: { middleware: auth, }
    },
    {
        path: "/admin/formulaire-usagers",
        redirect: "/admin/formulaire-usagers/en-cours/liste",
        meta: { middleware: auth, }
    },
    menuMenu,
    contentsManagementsMenu,
    postsManagementsMenu,
    documentationsManagementsMenu,
    faqMenu,
    typeFormMenu,
    formMenu,
    pendingUsersFormMenu,
    approvedUsersFormMenu,
    dpoFormationMenu,
    communityPages,
    usersMenu,
    // pagesMenu,
    {
        path: "/admin",
        component: DashboardLayout,
        name: "Dashboard layout",
        children: [{
                path: "dashboard",
                name: "Tableau de Bord",
                component: AlternativeDashboard,
                meta: {
                    navbarType: "light",
                    middleware: auth,
                }
            },
            {
                path: "newsletters",
                name: "newsletters",
                component: ListNewsletterPage,
                meta: { middleware: auth, }
            },
            {
                path: "mon-profil",
                name: "AuthUser",
                components: { default: AuthUser },
                meta: { middleware: auth }
            },
        ]
    },
    authPages
];

export default routes;