<template>
  <content-form
    action="Modifier"
    :resource="resource"
    :isLoading="isLoading"
    :localFiles.sync="files"
    @handleContent="editContent"
    :getApiErrors="apiValidationErrors"
  >
    <!-- loader -->
    <div class="col-12 d-flex justify-content-center" v-if="isLoading">
      <img src="/img/loading.gif" style="height: 200px; width: 200px" />
    </div>
    <div v-else>
      <!-- Type start -->
      <div class="row">
        <div class="col-lg-6">
          <div class="d-flex flex-row">
            <base-input label="Type d'Article" class="w-75">
              <el-autocomplete
                class="d-block"
                v-model="item.type"
                :fetch-suggestions="queryType"
                placeholder="Selectionner un type..."
                @select="handleSelect"
              >
              </el-autocomplete>
            </base-input>
            <div
              v-if="getTypeByName(item.type)"
              class="px-2 pt-2 align-self-center"
            >
              <base-button
                type="primary"
                @click="openEditModal"
                class="btn-icon-only rounded-circle"
              >
                <span class="btn-inner--icon display-5 font-weight-light"
                  ><i class="fas fa-pen"></i
                ></span>
              </base-button>
              <base-button
                type="danger"
                @click="deleteType"
                class="btn-icon-only rounded-circle"
              >
                <span class="btn-inner--icon display-5 font-weight-light"
                  ><i class="fas fa-trash"></i
                ></span>
              </base-button>
            </div>
          </div>
          <validation-error :errors="apiValidationErrors.type" />
        </div>
      </div>
      <!-- Type end -->

      <base-input label="Titre" v-model="item.title" />
      <validation-error :errors="apiValidationErrors.title" />
      <base-input label="Sous Titre" v-model="item.sub_title" />
      <validation-error :errors="apiValidationErrors.sub_title" />

      <base-input label="Contenu">
        <html-editor v-model="item.content" name="editor" />
      </base-input>
      <validation-error :errors="apiValidationErrors.content" />
      <handle-article-type
        :modals.sync="modals"
        :resource="item.concerned_resource"
        :articleType.sync="articleTypeForm"
        @submitArticleType="handleEditType"
      >
      </handle-article-type>
    </div>
  </content-form>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import formMixin from "@/mixins/form-mixin";
import { Autocomplete, Select, Option } from "element-ui";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import articleTypeMixin from "@/mixins/article-type-mixin";
import ContentForm from "../../Components/Forms/ContentForm.vue";
import managementResource from "@/util/resources";
import HandleArticleType from "../../../components/Modals/HandleArticleType.vue";

export default {
  name: "EditContentPage",
  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    ContentForm,
    HandleArticleType,
  },

  mixins: [formMixin, articleTypeMixin],

  data() {
    return {
      isLoading: false,
      files: [],
      item: {
        type: "",
        concerned_resource: "publications",
        title: "",
        sub_title: "",
        content: "",
        excerpt: "",
        image: null,
        user: null,
      },
      resource: managementResource.publication,
    };
  },
  created() {
    this.get();
  },
  methods: {
    goBack() {
      if (this.resource.paths)
        this.$router.push({ name: this.resource.paths.list.ref });
      else this.$router.push("#");
    },
    id() {
      return this.$route.params.id;
    },
    async get() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("articles/get", this.id());
        this.item = Object.assign(
          this.item,
          this.$store.getters["articles/article"]
        );
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false;
      }
    },
    async editContent() {
      this.isLoading = true;

      try {
        this.isLoading = true;

        await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
        // if (this.user.roles[0].name === "member") {
        //   this.$notify({
        //     type: "danger",
        //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
        //   });
        //   return;
        // }
        if (this.files.length) {
          await this.$store.dispatch("articles/upload", {
            article: this.item,
            medias: this.files,
          });

          this.item.image = await this.$store.getters["articles/url"];
        }

        await this.$store.dispatch("articles/update", {
          id: this.id(),
          item: this.item,
        });
        this.item = await this.$store.getters["articles/article"];

        this.$notify({
          type: "success",
          message: "Publication modifiée avec succès",
        });
        this.goBack();
      } catch (error) {
        this.isLoading = false;
        scrollTo({
          top: 70,
          behavior: "smooth",
        });
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        // this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },
  },
};
</script>
