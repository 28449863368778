<template>
  <content-form
    action="Ajouter"
    :resource="resource"
    :isLoading="isLoading"
    :localFiles.sync="files"
    @handleContent="addContent"
    :getApiErrors="apiValidationErrors"
  >
    <!-- Type start -->
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-row">
          <base-input label="Type d'Article" class="w-75">
            <el-autocomplete
              class="d-block"
              v-model="item.type"
              :fetch-suggestions="queryType"
              placeholder="Selectionner un type..."
              @select="handleSelect"
            >
            </el-autocomplete>
          </base-input>
          <div
            v-if="getTypeByName(item.type)"
            class="px-2 pt-2 align-self-center"
          >
            <base-button
              type="primary"
              @click="openEditModal"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-pen"></i
              ></span>
            </base-button>
            <base-button
              type="danger"
              @click="deleteType"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-trash"></i
              ></span>
            </base-button>
          </div>
        </div>
        <validation-error :errors="apiValidationErrors.type" />
      </div>
    </div>
    <!-- Type end -->

    <!-- Check if article type has category & section - Start -->
    <div class="row">
      <div class="col-lg-6">
        <base-checkbox v-if="hasInput" class="mb-3" v-model="toggleCategory">
          Ajouter une Categorie
        </base-checkbox>
      </div>
      <div class="col-lg-6">
        <base-checkbox
          v-if="hasInput"
          class="mb-3"
          v-model="toggleSection"
          :disabled="!categoryAdded"
        >
          Ajouter une Section
        </base-checkbox>
      </div>
    </div>
    <!-- Check if article type has category & section - End -->

    <!-- Category & Section start -->
    <div class="row">
      <div v-if="toggleCategory" class="col-lg-6">
        <div class="d-flex flex-row">
          <base-input label="Catégorie" class="w-75">
            <el-autocomplete
              class="d-block"
              v-model="item.category"
              :fetch-suggestions="queryCategory"
              placeholder="Selectionner une catégorie..."
              @select="handleCategorySelect"
            >
            </el-autocomplete>
          </base-input>
          <div
            v-if="getCategoryByName(item.category)"
            class="px-2 pt-2 align-self-center"
          >
            <base-button
              type="primary"
              @click="openEditCategoryModal"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-pen"></i
              ></span>
            </base-button>
            <base-button
              type="danger"
              @click="deleteCategory"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-trash"></i
              ></span>
            </base-button>
          </div>
        </div>
        <validation-error :errors="apiValidationErrors.category" />
      </div>
      <div v-if="toggleSection" class="col-lg-6">
        <div class="d-flex flex-row">
          <base-input label="Section" class="w-75">
            <el-autocomplete
              class="d-block"
              v-model="item.section"
              :fetch-suggestions="querySection"
              placeholder="Selectionner une section..."
              @select="handleSectionSelect"
              :disabled="!categoryAdded"
            >
            </el-autocomplete>
          </base-input>
          <div
            v-if="getSectionByName(item.section)"
            class="px-2 pt-2 align-self-center"
          >
            <base-button
              type="primary"
              @click="openEditSectionModal"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-pen"></i
              ></span>
            </base-button>
            <base-button
              type="danger"
              @click="deleteSection"
              class="btn-icon-only rounded-circle"
            >
              <span class="btn-inner--icon display-5 font-weight-light"
                ><i class="fas fa-trash"></i
              ></span>
            </base-button>
          </div>
        </div>
        <validation-error :errors="apiValidationErrors.section" />
      </div>
    </div>
    <!-- Category & Section end -->

    <base-input label="Titre" v-model="item.title" />
    <validation-error :errors="apiValidationErrors.title" />
    <base-input label="Sous Titre / Lien" v-model="item.sub_title" />
    <validation-error :errors="apiValidationErrors.sub_title" />

    <base-input label="Contenu">
      <html-editor v-model="item.content" name="editor" />
    </base-input>
    <validation-error :errors="apiValidationErrors.content" />
    <handle-article-type
      :modals.sync="modals"
      :resource="item.concerned_resource"
      :articleType.sync="articleTypeForm"
      @submitArticleType="handleEditType"
    >
    </handle-article-type>
    <handle-article-category
      :modals.sync="categoryModal"
      :category.sync="categoryForm"
      @submitCategory="handleEditCategory"
    >
    </handle-article-category>
    <handle-article-section
      :modals.sync="sectionModal"
      :section.sync="sectionForm"
      @submitSection="handleEditSection"
    >
    </handle-article-section>
  </content-form>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import formMixin from "@/mixins/form-mixin";
import { Autocomplete, Select, Option } from "element-ui";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import articleTypeMixin from "@/mixins/article-type-mixin";
import categoryMixin from "@/mixins/article-category-mixin";
import sectionMixin from "@/mixins/article-section-mixin";
import ContentForm from "../../Components/Forms/ContentForm.vue";
import managementResource from "@/util/resources";
import HandleArticleType from "../../../components/Modals/HandleArticleType.vue";
import HandleArticleCategory from "../../../components/Modals/HandleArticleCategory.vue";
import HandleArticleSection from "../../../components/Modals/HandleArticleSection.vue";

export default {
  name: "AddContentPage",
  components: {
    HtmlEditor,
    BaseInput,
    BaseCheckbox,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    ContentForm,
    HandleArticleType,
    HandleArticleCategory,
    HandleArticleSection,
  },

  mixins: [formMixin, articleTypeMixin, categoryMixin, sectionMixin],

  data() {
    return {
      checked: true,
      isLoading: false,
      disabledSection: false,
      files: [],
      item: {
        type: "",
        concerned_resource: "contenus",
        title: "",
        sub_title: "",
        content: "",
        excerpt: "",
        image: null,
        user: null,
        category: "",
        section: "",
      },
      resource: managementResource.contenu,
    };
  },
  methods: {
    goBack() {
      if (this.resource.paths)
        this.$router.push({ name: this.resource.paths.list.ref });
      else this.$router.push("#");
    },
    async addContent() {
      if (this.toggleCategory && this.item.category == "") {
        this.setApiValidation({
          category: ["Veuillez renseigner le champs de Categorie"],
        });
        return;
      }
      if (
        this.categoryAdded &&
        this.toggleSection &&
        this.item.section == ""
      ) {
        this.setApiValidation({
          section: ["Veuillez renseigner le champs de Section"],
        });
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
      //   });
      //   return;
      // }
      this.item.user = this.user.id;
      this.item.excerpt = this.item.content;
      try {
        delete this.item.id;

        if (this.item.category == "") this.item.category = null;
        if (this.item.section == "") this.item.section = null;
        
        await this.$store.dispatch("articles/add", this.item);
        this.item = await this.$store.getters["articles/article"];

        if (this.files.length) {
          await this.$store.dispatch("articles/upload", {
            article: this.item,
            medias: this.files,
          });

          this.item.image = await this.$store.getters["articles/url"];
        }

        this.$notify({
          type: "success",
          message: "Contenu ajouté avec succès",
        });
        this.goBack();
      } catch (error) {
        scrollTo({
          top: 70,
          behavior: "smooth",
        });
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.item.id) {
          await this.$store.dispatch("articles/destroy", this.item.id);
        }
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
