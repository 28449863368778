<template>
    <modal :show.sync="modals.articleTypeModal" body-classes="p-0">
      <h6 slot="header" class="modal-title">Modifier {{ resource }}</h6>
      <div v-if="!articleType.id">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
      <form v-else role="form" @submit.prevent="$emit('submitArticleType')">
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <base-input
              alternative
              v-model="articleType.name"
              class="mb-3"
              placeholder="Entrer le type d'Article"
              prepend-icon="ni ni-tag"
            />
          </template>
        </card>
        <div class="d-flex justify-content-between my-4 px-lg-5">
          <base-button type="primary" :loading="modals.isLoading" native-type="submit"
            >Enregistrer</base-button
          >
          <base-button type="danger" @click="modals.articleTypeModal = false"
            >Annuler</base-button
          >
        </div>
      </form>
    </modal>
</template>

<script>
import { Modal } from "@/components";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
export default {
  name: "HandleArticleType",
  emits: ["submitArticleType"],
  props: ["modals", "resource", "articleType"],
  components: {
    Modal,
    BaseButton,
    BaseInput,
  },
};
</script>