import qs from "qs";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
    const options = {
        params: params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        }
    };

    return axios.get(`${url}/admin/articles`, options).then(response => {
        return {
            list: response.data.data,
            meta: response.data.meta
        };
    });
}

function get(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .get(`${url}/admin/articles/${id}`, options)
        .then(response => {
            return response.data.data;
        });
}

function add(payload) {
    // const payload = jsona.serialize({
    //     stuff: item,
    //     includeNames: ["categories"]
    // });

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };
    return axios
        .post(`${url}/admin/articles`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function update(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };
    return axios
        .put(`${url}/admin/articles/${payload.id}`, payload.item, options)
        .then(response => {
            return response.data.data;
        });
}

function destroy(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/admin/articles/${id}`, options);
}

function upload(item, medias, field) {
    const bodyFormData = new FormData();
    medias.forEach(m => {
        bodyFormData.append(m.type, m.file);
    });

    return axios
        .post(`${url}/admin/uploads/articles/${item.id}/${field}`, bodyFormData)
        .then(response => {
            return response.data.url;
        })
        .catch(error => error);
}

export default {
    list,
    get,
    add,
    update,
    destroy,
    upload
};