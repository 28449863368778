<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Ajouter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Créer Formulaire</h3>
              </div>
              <div class="col-4 text-right">
                <base-button
                  @click="goBack"
                  type="button"
                  class="btn btn-sm btn-primary"
                  >Retourner à la liste
                </base-button>
              </div>
            </div>
            <div class="card-body">
              <form ref="warning_form" @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Titre" v-model="item.name" />
                  </div>
                  <div class="col-lg-6">
                    <div class="d-flex flex-row">
                      <base-input label="Traitement" class="w-75">
                        <el-autocomplete
                          class="d-block"
                          v-model="item.type"
                          :fetch-suggestions="queryType"
                          placeholder="Selectionner..."
                          @select="handleSelect"
                        >
                        </el-autocomplete>
                      </base-input>
                      <div
                        v-if="getTypeByName(item.type)"
                        class="px-2 pt-2 align-self-center"
                      >
                        <base-button
                          type="primary"
                          @click="openEditModal"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-pen"></i
                          ></span>
                        </base-button>
                        <base-button
                          type="danger"
                          @click="deleteType()"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </base-button>
                      </div>
                    </div>
                    <validation-error :errors="apiValidationErrors.type" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-checkbox
                      v-if="hasInput"
                      class="mb-3"
                      v-model="toggleFormProcess"
                    >
                      Ajouter une Sous Traitement
                    </base-checkbox>
                  </div>
                  <!-- Sub process start -->
                  <div v-if="toggleFormProcess" class="col-lg-6">
                    <div class="d-flex flex-row">
                      <base-input label="Sous Traitement" class="w-75">
                        <el-autocomplete
                          class="d-block"
                          v-model="item.sub_type"
                          :fetch-suggestions="querySubType"
                          placeholder="Selectionner un sous-traitement..."
                          @select="handleSubTypeSelect"
                        >
                        </el-autocomplete>
                      </base-input>
                      <div
                        v-if="getTypeByName(item.sub_type, 'child')"
                        class="px-2 pt-2 align-self-center"
                      >
                        <base-button
                          type="primary"
                          @click="e => openEditModal(e, 'child')"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-pen"></i
                          ></span>
                        </base-button>
                        <base-button
                          type="danger"
                          @click="deleteType('child')"
                          class="btn-icon-only rounded-circle"
                        >
                          <span
                            class="btn-inner--icon display-5 font-weight-light"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </base-button>
                      </div>
                    </div>
                    <validation-error :errors="apiValidationErrors.sub_type" />
                  </div>
                  <!-- Sub process end -->
                </div>

                <base-input label="Description">
                  <html-editor v-model="item.detail" name="editor" />
                </base-input>
                <validation-error :errors="apiValidationErrors.excerpt" />

                <div class="my-4">
                  <base-button
                    type="button"
                    class="btn btn-sm btn-primary"
                    native-type="submit"
                  >
                    Ajouter Formulaire
                  </base-button>
                </div>
              </form>
              <modal
                :show.sync="modals.editTypeModal"
                header-classes="bg-success text-white"
                body-classes="p-0"
              >
                <div
                  slot="header"
                  class="modal-title text-white d-inline-block"
                >
                  <i class="fas fa-info-circle display-4"></i>
                  <h2 class="text-white d-inline ml-3">
                    Modifier le traitement
                  </h2>
                </div>
                <form role="form" @submit.prevent="handleEditType">
                  <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
                  >
                    <template>
                      <div class="row row-cols-1">
                        <base-input
                          name="traitement"
                          label="Nom du Traitement :"
                          v-model="typeForm.name"
                          placeholder="Entrer le traitement"
                          required
                        />
                      </div>
                      <div class="row row-cols-1">
                        <base-input label="Description du Traitement :">
                          <textarea
                            name="description"
                            v-model="typeForm.description"
                            class="form-control"
                            rows="3"
                          ></textarea>
                        </base-input>
                      </div>
                    </template>
                  </card>
                  <div class="modal-footer d-flex justify-content-between">
                    <base-button
                      type="primary"
                      :loading="isLoading"
                      native-type="submit"
                      >Enregistrer</base-button
                    >
                    <base-button
                      type="danger"
                      @click="modals.editTypeModal = false"
                      >Annuler</base-button
                    >
                  </div>
                </form>
              </modal>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations } = createNamespacedHelpers("form_types");

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Autocomplete, Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";

export default {
  layout: "DashboardLayout",
  name: "AddFormPage",
  components: {
    HtmlEditor,
    RouteBreadCrumb,
    BaseInput,
    BaseButton,
    ValidationError,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      isLoading: false,
      item: {
        type: "",
        name: "",
        detail: "",
        excerpt: "",
        user: null,
        sub_type: "",
      },
      modals: {
        editTypeModal: false,
        editSubModal: false,
      },
      typeForm: { name: "", description: "" },
      toggleFormProcess: false,
    };
  },
  watch: {
    hasInput: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.toggleFormProcess = false;
        }
      },
      immediate: true,
    },
    toggleFormProcess: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          this.item.sub_type = "";
          this.setSubType({});
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "allParentTypes",
      "allChildrenTypes",
      "getTypeByName",
      "type",
      "subType",
    ]),
    // check if user input
    hasInput() {
      return typeof this.item.type === "string" && this.item.type != "";
    },
  },
  created() {
    this.getTypes();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "Liste Formulaires" });
    },

    async getTypes() {
      try {
        await this.$store.dispatch("form_types/list", {
          resource: "formulaire",
        });

        if (this.allParentTypes && this.allParentTypes.length) {
          this.setType({});
          this.item.type = this.allParentTypes[0].value;
        }
        // if (this.allChildrenTypes && this.allChildrenTypes.length) {
        //   this.setSubType({});
        //   this.item.sub_type = this.allChildrenTypes[0].value;
        // }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `Désolé, une erreur est survenue!`,
        });
        this.setApiValidation(error.response.data.data.errors);
      }
    },

    queryType(input, callback) {
      let types = this.allParentTypes;

      let results = input ? types.filter(this.createFilter(input)) : types;
      //call callback function to return suggestions
      callback(results);
    },

    querySubType(input, callback) {
      let types = this.allChildrenTypes;
      let results = input ? types.filter(this.createFilter(input)) : types;

      //call callback function to return suggestions
      callback(results);
    },

    async handleSubmit() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Désolé, vous n'êtes pas authorisé à effectuer cette action.",
      //   });
      //   return;
      // }
      this.item.user = this.user.id;
      this.item.excerpt = this.item.detail;
      try {
        delete this.item.id;
        await this.$store.dispatch("forms/add", this.item);
        this.item = await this.$store.getters["forms/form"];

        this.$router.push({
          name: "Détails Formulaire",
          params: { id: this.item.id },
        });

        this.$notify({
          type: "success",
          message: "Formulaire crée avec succès",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        // this.setApiValidation(error);
      }
    },
    handleSelect(t) {
      this.setType(t);
      this.item.type = t.value;
    },
    handleSubTypeSelect(t) {
      this.setSubType(t);
      this.item.sub_type = t.value;
    },
    createFilter(input) {
      return (type) => {
        return type.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
      };
    },
    async deleteType(level = "parent") {
      let itemType = ""
      
      if (level == "parent") itemType = this.item.type;
      else if (level == "child") itemType = this.item.sub_type;
      else return;

      const confirmation = await swal.fire({
        title: `Supprimer le type`,
        html: `<p>Confirmez-vous la suppression du type <b>${itemType}</b>?</p>`,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: `Oui, supprimer`,
        cancelButtonText: "Non, annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning",
        },
      });

      try {
        if (confirmation.value === true) {
          let currentType = this.getTypeByName(itemType, level);
          await this.$store.dispatch("form_types/destroy", {
            id: currentType.id,
          });
          await this.getTypes();
          this.$notify({
            type: "success",
            message: "Le type a été supprimé avec succès.",
          });
        }
      } catch (error) {
        if(error?.response != undefined) {
          const { message } = error.response?.data
          this.$notify({
            type: "danger",
            message: message,
          });
        } else {
          this.$notify({
            type: "danger",
            message:
              "Désolé, une erreur est survenue lors de la suppression du type!",
          });
        }
      }
    },
    ...mapMutations({
      setType: "SET_RESOURCE",
      setSubType: "SET_SUB_RESOURCE",
    }),

    openEditModal(ev, level = "parent") {
      let currentType = {};

      if (level == "parent") currentType = this.type;
      // set current type as main type
      else if (level == "child") currentType = this.subType; // set current type as sub type

      // check if current type is settled
      if (Object.keys(currentType).length) {
        this.typeForm = {
          id: currentType.id,
          name: currentType.value,
          description: currentType.description,
        };
      } else if (
        level == "parent" &&
        this.item.type.length &&
        this.getTypeByName(this.item.type)
      ) {
        // if there is no type (main or child) in store loking through item, and check it's existence in types collection
        let type = this.getTypeByName(this.item.type);
        this.typeForm = {
          id: type.id,
          name: type.value,
          description: type.description,
        };
      } else if (
        level == "child" &&
        this.item.sub_type.length &&
        this.getTypeByName(this.item.type, "child")
      ) {
        let type = this.getTypeByName(this.item.type, "child");
        this.typeForm = {
          id: type.id,
          name: type.value,
          description: type.description,
        };
      } else {
        this.typeForm.name = "";
        this.typeForm.description = "";
      }
      this.modals.editTypeModal = true;
    },

    async handleEditType() {
      this.isLoading = true;
      let payload = {
        id: this.typeForm.id,
        name: this.typeForm.name,
        description: this.typeForm.description,
      };
      try {
        await this.$store.dispatch("form_types/update", payload);
        await this.getTypes();

        if(this.toggleFormProcess) this.toggleFormProcess = false;
        this.modals.editTypeModal = false;
        this.$notify({
          type: "success",
          message: "Type modifié avec succès.",
        });
      } catch (error) {
        this.typeForm.name = "";
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
        this.setApiValidation(error.response.data.errors);
      }
      this.isLoading = false;
    },
  },
};
</script>
