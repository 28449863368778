import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
    const options = {
        params: params,
        paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
        },
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };


    return axios.get(`${url}/admin/users`, options).then(response => {
        return {
            list: response.data.data,
            meta: response.data.meta
        };
    });
}

function get(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .get(`${url}/admin/users/${id}`, options)
        .then(response => {
            return response.data.data;
        });
}

function add(payload) {

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/admin/users`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function update(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/admin/users/${payload.id}`, payload, options)
        .then(response => {
            return response.data.data;
        });
}

function toggle(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/admin/toggle/users/${payload.id}`, {}, options)
        .then(({data}) => {
            return data
        })
        .catch((error) => error);
}

function destroy(id) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.delete(`${url}/admin/users/${id}`, options)
    .then(({ data }) => data)
    .catch((error) => error)
}

function upload(user, image) {
    const payload = new FormData();
    payload.append("attachment", image);

    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/uploads/users/${user.id}/profile-image`, payload, options)
        .then(response => {
            return response.data.url;
        });
}

export default {
    list,
    get,
    add,
    update,
    toggle,
    destroy,
    upload
};