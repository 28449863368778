<template>
  <div>
    <div class="row align-items-center">
      <div class="col">
        <h3 class="mb-4">
          <base-button
            @click="$router.push({ name: 'Liste Formulaires' })"
            type="button"
            class="btn btn-sm btn-primary"
          >
            <i class="fas fa-arrow-left"></i>
          </base-button>
          Liste des sections du formulaire
        </h3>
      </div>
    </div>
    <collapse v-if="sectionTypes.length" :active-index="0">
      <collapse-item
        :id="`section-${type}`"
        :title="`collapse-${index}`"
        v-for="(type, index) in sectionTypes"
        :key="index"
        :name="`section${index + 1}`"
      >
        <div slot="title" class="d-flex align-items-center">
          <h5 class="mr-2 mb-0">{{ sectionTypeColor(type).name }}</h5>
        </div>
        <div>
          <div class="d-flex justify-content-between mb-4">
            <div class="d-flex">
              <h6 class="heading-small text-muted">
                {{ sectionTypeColor(type).name }}
              </h6>
              <handle-form-sections-modal
                :color="sectionTypeColor(type).color"
                :fieldLabel="sectionTypeColor(type).name"
                :currType="type"
                :sections="initialSections"
                :formId="id()"
                @formUpdated="$emit('sectionUpdated')"
              />

              <handle-drag-and-drop-modal
                :color="sectionTypeColor(type).color"
                :fieldLabel="sectionTypeColor(type).name"
                :currType="type"
                :sections="getAttachedSectionsByType(type)"
                :formId="id()"
                @formUpdated="$emit('sectionUpdated')"
              />
            </div>
          </div>
          <div class="row row-cols-1 py-3">
            <ul class="list-group list-group-flush" data-toggle="checklist">
              <li
                class="checklist-entry list-group-item flex-column align-items-start py-4"
                v-for="(section, index) in getAttachedSectionsByType(type)"
                :key="index"
              >
                <div
                  class="section-checklist-item"
                  :class="{
                    'checklist-item-checked': sectionIsArchivedWithCurrForm(
                      section.is_archived_with_form
                    ),
                    [`checklist-item-${sectionTypeColor(type).color}`]:
                      sectionTypeColor(type).color,
                  }"
                >
                  <div>
                    <base-checkbox
                      :checked="
                        sectionIsArchivedWithCurrForm(
                          section.is_archived_with_form
                        )
                      "
                      :type="sectionTypeColor(type).color"
                      @input="disabledSection(section)"
                      :disabled="section.archived"
                    />
                  </div>
                  <div class="checklist-info">
                    <h5 class="checklist-title mb-0">
                      <i
                        v-show="section.archived"
                        class="fas fa-archive mr-1 text-danger"
                      ></i
                      >{{ section.name }}
                    </h5>
                    <small
                      >Nbr. Questions : {{ section.questions.length }}</small
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </collapse-item>
    </collapse>
    <div v-else>
      <i class="text-muted"
        >Aucune section n'est enregistrée pour ce formulaire! Veuillez cliquer
        sur le bouton <span class="text-primary">Ajouter Section</span> pour
        ajouter</i
      >
    </div>
  </div>
</template>

<script>
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import BaseCheckbox from "../Inputs/BaseCheckbox.vue";
import HandleFormSectionsModal from "@/components/Modals/HandleFormSectionsModal.vue";
import HandleDragAndDropModal from "@/components/Modals/HandleDragAndDropModal.vue";

export default {
  props: {
    formHasSubmits: Boolean,
    initialSections: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Collapse,
    CollapseItem,
    BaseCheckbox,
    HandleFormSectionsModal,
    HandleDragAndDropModal,
  },
  data() {
    return {
      sectionTypes: ["standard", "annexe", "pj"],
      modals: {
        toggleModal: false,
      },
      selectedSections: [],
      draggingSections: [],
      isLoading: false,
      openDragModal: false,
    };
  },

  methods: {
    id() {
      return parseInt(this.$route.params.id);
    },
    sectionTypeColor(type) {
      let typeColor = {
        name: "",
        color: "",
      };
      switch (type) {
        case "standard":
          typeColor.name = "Standard";
          typeColor.color = "success";
          break;
        case "annexe":
          typeColor.name = "Annexe";
          typeColor.color = "info";
          break;
        case "pj":
          typeColor.name = "Pièce Jointe";
          typeColor.color = "warning";
          break;
        default:
          typeColor.name = "-----";
          typeColor.color = "secondary";
          break;
      }
      return typeColor;
    },
    sectionIsArchivedWithCurrForm(checkStatus) {
      if (checkStatus == null) return false;
      else return checkStatus;
    },
    getAttachedSectionsByType(type) {
      return this.initialSections.filter((section) => section.of_type == type);
    },
    async disabledSection(section) {
      try {
        await this.$store.dispatch("forms/removeSections", {
          id: this.id(),
          sectionId: section.id,
        });
        this.$emit("sectionUpdated");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }
    },
  },
};
</script>
