import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/users/auth";
// import alerts from "./modules/alerts-module";
import profile from "./modules/users/profile-module";
import users from "./modules/users/users-module";
import roles from "./modules/users/roles-module";
import permissions from "./modules/users/permissions-module";
import menus from "./modules/menus/menus-module";
import articles from "./modules/articles/articles-module";
import article_categories from "./modules/articles/article-categories-module";
import article_sections from "./modules/articles/article-sections-module";
import article_types from "./modules/articles/article-types-module";
import forms from "./modules/userForms/forms-module";
import formations from "./modules/dpo/dpo-formations-module";
import episodes from "./modules/dpo/dpo-episodes-module";
import submits from "./modules/userForms/submits-module";
import form_sections from "./modules/userForms/form-sections-module";
import form_questions from "./modules/userForms/form-questions-module";
import form_types from "./modules/userForms/form-types-module";
import blog_posts from "./modules/communauties/blog-posts-module";
import forums from "./modules/communauties/forums-module";
import thematics from "./modules/thematics-module";
import faqs from "./modules/communauties/faqs-module";
import reset from "./modules/reset";
import bookings from "./modules/communauties/bookings-module";
import booking_services from "./modules/communauties/booking-services-module";
import newsletters from "./modules/newsletters-module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        // alerts,
        profile,
        users,
        roles,
        permissions,
        article_categories,
        article_sections,
        article_types,
        menus,
        articles,
        forms,
        submits,
        formations,
        episodes,
        form_sections,
        form_questions,
        form_types,
        blog_posts,
        forums,
        thematics,
        faqs,
        reset,
        bookings,
        booking_services,
        newsletters,
    }
});