<template>
  <div class="card card-profile">
    <img
      src="/img/theme/img-1-1000x600.jpg"
      alt="Image placeholder"
      class="card-img-top"
    />

    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <img src="/img/placeholder.png" class="rounded-circle" />
          </a>
        </div>
      </div>
    </div>

    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between mb-4">
        <button @click="toggle2FA" class="btn btn-sm btn-success">
          Activer 2FA
        </button>
        <div class="float-right">
          <button
            @click="recoveryCodes"
            class="btn btn-sm btn-default"
          >
            Récupération
          </button>
          <button
            @click="resetRecoveryCodes"
            class="btn btn-sm btn-info"
          >
            <i v-if="isLoading" class="fa fa-spinner"></i>
            <i v-else class="fa fa-sync"></i>
          </button>
        </div>
        <modal size="lg" :show.sync="modal" body-classes="pb-5">
          <h6 slot="header" class="modal-title">Code de Récupération</h6>
          <div v-if="code == null">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>
          <div v-else>
            <p v-for="(recovery, i) in code" :key="i">{{ recovery }}</p>
          </div>
        </modal>
      </div>
    </div>

    <div class="card-body pt-0">
      <!-- <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div>
              <span class="heading">22</span>
              <span class="description">Friends</span>
            </div>
            <div>
              <span class="heading">10</span>
              <span class="description">Photos</span>
            </div>
            <div>
              <span class="heading">89</span>
              <span class="description">Comments</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="text-center">
        <h5 class="h3">
          {{ user.name }}
          <!-- <span class="font-weight-light">, 27</span> -->
        </h5>
        <!-- <div class="h5 font-weight-300">
          <i class="ni location_pin mr-2"></i>Bucharest, Romania
        </div> -->
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>
          <span v-for="(role, i) in user.roles" :key="i"
            >{{ role.name
            }}<span v-if="user.roles.length - i > 1" class="mx-1">-</span></span
          >
        </div>
        <!-- <div>
          <i class="ni education_hat mr-2"></i>University of Computer Science
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserCard",
  props: {
    user: Object,
  },
  data() {
    return {
      isLoading: false,
      modal: false,
      code: null
    };
  },
  methods: {
    async toggle2FA() {
      try {
        const resp = await this.$store.dispatch("profile/enable2FA");
        // console.log("response from toggle", resp);
      } catch (error) {
        // console.log("error from toogle 2FA request", error.message);
      }
    },
    async recoveryCodes() {
      try {
        this.isLoading = true
        this.modal = true;
  
        // const resp = await this.$store.dispatch("profile/qrCode");
        const resp = await this.$store.dispatch("profile/recoveryCode");
        // this.code = resp?.svg;
        this.code = resp;
      } catch (error) {
        this.code = '<span style="color: #852121;">Une erreur est survenue lors de la récupération du code.</span>';
      }

      this.isLoading = false;
    },
    async resetRecoveryCodes() {
      try {
        this.isLoading = true

        await this.$store.dispatch("profile/resetRecoveryCodes");

        this.$notify({
          type: "success",
          message: "Les codes de récupération ont été regénérés avec succès",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur est survenue!",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style></style>
