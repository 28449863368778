<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Paramètres</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Sections & Type tabs -->
        <tabs
          tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper"
          tabContentClasses="card shadow-none"
        >
          <tab-pane id="home">
            <span slot="title">
              <i class="ni ni-book-bookmark" />
              Traitements
            </span>

            <div class="card-body description">
              <!-- <create-type-modal
                @typeCreated="getTypes"
                :getType="findType"
                :options="selectOptions"
                :currentType.sync="type"
              /> -->
              <i>
                Bientôt disponible pour la gestion des traitements...
              </i>
            </div>
          </tab-pane>

          <tab-pane id="profile">
            <span slot="title">
              <i class="ni ni-bullet-list-67 mr-2" />
              Sections
            </span>
            <div class="card-body description">
              <create-section-modal
                @sectionCreated="getSections"
                :getType="findType"
                :options="selectOptions"
                :currentType.sync="type"
              />
            </div>
            <hr class="mt-1">
            <div class="card-body description">
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Par page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <div>
                    <base-input
                      v-model="query"
                      type="search"
                      prepend-icon="fas fa-search"
                      placeholder="Rechercher..."
                      clearable
                    />
                  </div>
                </div>
                <el-table
                  class="table-responsive align-items-center table-flush"
                  header-row-class-name="thead-light"
                  :data="mapItems"
                  @sort-change="sortChange"
                >
                  <div slot="empty" v-if="isLoading">
                    <img
                      src="/img/loading.gif"
                      style="height: 100px; width: 100px"
                    />
                  </div>
                  <el-table-column
                    label="Name"
                    min-width="200px"
                    prop="name"
                    sortable="custom"
                  >
                    <template v-slot="{ row }">
                      {{ row.name }}
                      <span
                        v-show="row.archived"
                        :class="{ 'text-danger': row.archived }"
                      >
                        <i class="fas fa-archive"></i>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Type"
                    min-width="200px"
                    prop="of_type"
                    sortable="custom"
                  >
                    <template v-slot="{ row }">
                      <badge :type="findType(row.of_type).color">
                        {{ row.of_type }}
                      </badge>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Nbr. Questions"
                    min-width="200px"
                    prop="questions"
                    sortable="custom"
                  >
                    <template v-slot="{ row }">
                      {{ row.questions.length }}
                    </template>
                  </el-table-column>
                  <el-table-column min-width="90px" align="center">
                    <div slot-scope="{ row }" class="table-actions">
                      <el-tooltip content="Détails" placement="top">
                        <base-button
                          :type="row.archived ? 'danger' : 'info'"
                          @click.native="showItem(row)"
                          class="table-action"
                          data-toggle="tooltip"
                          style="cursor: pointer"
                          size="sm"
                          icon
                        >
                          <i class="text-white fas fa-eye"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div>
                  <p class="card-category">
                    Affichage de {{ sectionsCount ? from + 1 : 0 }} à
                    {{ to }} de
                    {{ sectionsCount }}
                    {{ currentType.label }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="sectionsCount"
                />
              </div>
            </div>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("fr");
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination, TabPane, Tabs } from "@/components/";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import CreateSectionModal from "@/components/Modals/CreateSectionModal.vue";
import CreateTypeModal from "@/components/Modals/CreateTypeModal.vue";
export default {
  name: "ShowFormPage",
  components: {
    CreateSectionModal,
    CreateTypeModal,
    BasePagination,
    TabPane,
    Tabs,
    RouteBreadCrumb,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      sort: "created_at",
      type: "standard",
      isLoading: false,
      sections: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      selectOptions: [
        {
          name: "Veuillez faire un choix",
          label: "Sélectionner un type",
          value: "",
          color: "",
        },
        {
          name: "Section",
          label: "Sections",
          value: "standard",
          color: "default",
        },
        {
          name: "Annexe",
          label: "Annexes",
          value: "annexe",
          color: "info",
        },
        {
          name: "Pièce-Jointe",
          label: "Pièces Jointes",
          value: "pj",
          color: "warning",
        },
      ],
    };
  },
  watch: {
    type: {
      handler: "getSections",
      immediate: false,
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getSections",
      immediate: false,
      deep: true,
    },
  },
  computed: {
    sectionsList() {
      return this.$store.getters["form_sections/list"];
    },
    sectionsCount() {
      return this.$store.getters["form_sections/listCount"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.sectionsCount < highBound) {
        highBound = this.sectionsCount;
      }
      return highBound;
    },
    currentType() {
      return this.findType(this.type);
    },
    mapItems() {
      if (this.sectionsList) {
        let sections = this.sectionsList;
        sections.map((item) => {
          return (item.created_at = moment(
            new Date(item.created_at).getTime()
          ).fromNow());
        });
        return sections;
      } else return [];
    },
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getSections();
    }, 300),
    async getSections() {
      this.isLoading = true;
      try {
        let params = {
          type: this.type,
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: this.pagination.currentPage,
          size: this.pagination.perPage,
        };
        await this.$store.dispatch("form_sections/list", params);
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Désolé, une erreur s'est produite!",
        });
        this.setApiValidation(error.response.data.errors);
        this.isLoading = false;
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getSections();
    },
    findType(val) {
      return this.selectOptions.find((t) => t.value == val);
    },
    showItem(section) {
      this.$router.push({
        name: "Détails Section",
        params: { id: section.id },
      });
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/brand/logo1.png);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
